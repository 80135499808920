import React, { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { Divider, Form, FormInstance, Input, Select } from 'antd';
import {
  ACCOUNT_TYPE_OPTIONS,
  CREDIT_BNPL,
  CREDIT_LIMIT_OPTIONS,
  VALUE_ADDED_SERVICE_OPTIONS,
  formValidations
} from '../constants';

interface IAccountDetails {
  businessDetailsForm: FormInstance;
  selectedOption: string | null;
  setSelectedOption: Dispatch<SetStateAction<string | null>>;
}

const AccountDetails: FC<IAccountDetails> = ({
  businessDetailsForm,
  selectedOption,
  setSelectedOption
}) => {
  const nameValue = Form.useWatch('accountType', businessDetailsForm);

  const handleAccTypeChange = () => {
    businessDetailsForm.resetFields(['creditLimit', 'bnplCustomLimit']);
    setSelectedOption(null);
  };

  const handleLimitChange = (value: string) => {
    setSelectedOption(value);
  };
  return (
    <div>
      <Divider />
      <h2 className="text-xl font-bold mb-5 select-none">Account Details :</h2>

      <div className="flex flex-col md:flex-row md:gap-5 gap-0">
        <div className="w-full md:w-1/2">
          <Formlabel labelText="Account Type" required />
          <Form.Item
            className="mt-2"
            name={'accountType'}
            rules={[{ required: true, message: formValidations?.accountType?.required }]}>
            <Select
              onChange={handleAccTypeChange}
              placeholder="Select type"
              options={ACCOUNT_TYPE_OPTIONS}
              allowClear
            />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/2">
          <Formlabel labelText="Credit Limit" required={nameValue === CREDIT_BNPL} />
          <Form.Item
            className="mt-2"
            name="creditLimit"
            dependencies={['accountType']}
            rules={[
              {
                required: nameValue === CREDIT_BNPL,
                message: formValidations?.creditLimit?.required
              }
            ]}>
            <Select
              options={CREDIT_LIMIT_OPTIONS}
              disabled={!(nameValue === CREDIT_BNPL)}
              title={nameValue === CREDIT_BNPL ? '' : 'Limit is available for BNPL only'}
              placeholder="Enter limit"
              onChange={handleLimitChange}
            />
          </Form.Item>
        </div>
      </div>

      {selectedOption === 'custom' && (
        <div className="w-full">
          <Formlabel labelText="Custom Limit" required />
          <Form.Item
            className="mt-2"
            name={'bnplCustomLimit'}
            rules={[
              { required: true, message: 'Please enter the limit' },
              {
                pattern: /^[1-9]\d*$/,
                message: 'Limit should be valid number.'
              }
            ]}>
            <Input placeholder="Enter Limit" />
          </Form.Item>
        </div>
      )}

      <div className="w-full">
        <Formlabel labelText="Value Added Services" />
        <Form.Item className="mt-2" name={'valueAddedServices'}>
          <Select
            allowClear
            placeholder="Select services"
            mode="multiple"
            options={VALUE_ADDED_SERVICE_OPTIONS}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default AccountDetails;
