import { clearCache } from './clearCache';

export const getTokenDetails = () => {
  const token = localStorage.getItem('token');
  const tokenParts: any = token?.split('.');
  let tokenData = null;
  if (token) {
    const [, payload] = tokenParts;
    try {
      tokenData = token ? JSON.parse(atob(payload)) : null;
    } catch (error) {
      clearCache();
    }
  }

  return { tokenParts, tokenData };
};
