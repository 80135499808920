import { Button } from 'antd';
import React, { useState } from 'react';
import { getOrdersReport } from '../api';
import ComReportDatePicker from '../Partials/ComReportDatePicker';

const WalletReport: React.FC = () => {
  const [dateRange, setdDateRange] = useState<{
    from: string;
    to: string;
  }>({
    from: '',
    to: ''
  });

  const [error, setError] = useState<string>('');

  const handleGetOrder = () => {
    if (Object.values(dateRange)?.filter((val) => val)?.length) {
      setError('');
      getOrdersReport({
        from: dateRange?.from,
        to: dateRange?.to,
        reportType: 'walletReport'
      });
    } else {
      setError('Please enter any data.');
    }
  };

  return (
    <div>
      <h2 className="text-xl font-normal m-0 mb-4">Wallet Report</h2>
      <div className="flex flex-col items-center gap-5">
        {error ? <p className="m-0 text-red-400">{error}</p> : null}
        <div className="p-10 rounded-xl flex flex-col gap-5 primary-box-shadow">
          <ComReportDatePicker
            id={'businessReportDate'}
            currentState={dateRange}
            setState={setdDateRange}
          />

          <Button className="primary-button" onClick={handleGetOrder}>
            Download Report
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WalletReport;
