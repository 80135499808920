import React, { FC } from 'react';
import { Avatar, Typography } from 'antd';
import { MdBusiness } from 'react-icons/md';
import { IB2BOrderDetails } from '../../../../../interfaces/b2bV3OrdersList';

interface IBasicInformation {
  detailsData: IB2BOrderDetails | undefined;
}
const BasicInformation: FC<IBasicInformation> = ({ detailsData }) => {
  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <div className="w-[50px]">
            <Avatar
              size={50}
              shape="square"
              src={detailsData?.business?.logoUrl}
              icon={
                <div className="flex items-center justify-center pt-3">
                  <MdBusiness />
                </div>
              }
            />
          </div>

          <div className="flex flex-col gap-1">
            {detailsData?.business?.brandName && (
              <h2 className="text-xl font-medium">
                {`${detailsData?.business?.brandName}`.trim()}
              </h2>
            )}
            <div className="flex items-center gap-1 flex-wrap">
              <p className="font-semibold">Business ID:</p>
              {detailsData?.business?.id ? (
                <Typography.Text
                  className="font-medium flex items-center"
                  copyable={{ text: detailsData?.business?.id }}>
                  {detailsData?.business?.id}
                </Typography.Text>
              ) : (
                <p>NA</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicInformation;
