import { Button } from 'antd';
import UserMenu from '../ManageMenu/api';
import { useEffect, useState } from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import MenuForm from './MenuForm';
import MenuData from './api';
import { CLOUDINARY_URL } from '../../../utils/config';
import React from 'react';

const AddMenu = () => {
  const { getUsersMenuList, getUsersMenu, isLoading } = UserMenu();
  const { createMenu, deleteMenu, updateMenuData } = MenuData();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [action, setAction] = useState('');
  const [updateMenu, setUpdateMenu] = useState<any>({});

  const onSubmit = async (values: any) => {
    await createMenu({
      payload: values,
      callbackFun: getUsersMenuList
    });
    setShowForm(false);
  };

  useEffect(() => {
    getUsersMenuList();
  }, []);

  const deleteMenuList = async (id: number) => {
    await deleteMenu({
      id: id,
      callbackFun: getUsersMenuList
    });
  };

  const onUpdate = async (values: any) => {
    await updateMenuData({
      id: updateMenu?._id,
      payload: values,
      callbackFun: getUsersMenuList
    });
    setShowForm(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Menu ID',
      dataIndex: 'menuId',
      key: 'menuId'
    },
    {
      title: 'Menu Name',
      dataIndex: 'menuName',
      key: 'menuName'
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department'
    },
    {
      title: 'Action',
      width: '15%',
      render: (getUsersMenu) => (
        <div className="flex justify-between">
          <Button
            className="border-none p-0 w-5 h-5"
            onClick={() => {
              setShowForm(true);
              setUpdateMenu(getUsersMenu);
              setAction('Update');
            }}>
            <img src={`${CLOUDINARY_URL}/icons/arrow-circle-down_epd9xb.svg`} alt="" />
          </Button>
          <Button
            className="border-none p-0 w-5 h-5"
            onClick={() => deleteMenuList(getUsersMenu._id)}>
            <img src={`${CLOUDINARY_URL}/icons/Delete_Icon.svg`} alt="" />
          </Button>
        </div>
      )
    }
  ];
  return (
    <>
      <div className="top-row flex flex-row justify-between  mb-8 px-3 sm-w-48">
        <h2 className="text-xl font-bold m-0 mb-4 ">Menu List</h2>
        <Button
          className="primary-button w-28 h-11 py-2 px-2 font-bold"
          onClick={() => {
            setShowForm(true);
            setAction('Add');
          }}>
          ADD Menu
        </Button>
      </div>
      <div className="bg-white h-16 rounded-t-lg">
        <h4 className="ml-4 font-bold pt-4 h-16 text-xl ">Menu List</h4>
        <Table
          scroll={{ x: 1600 }}
          dataSource={getUsersMenu?.MenuList}
          columns={columns}
          loading={isLoading}
        />
        <MenuForm
          isVisible={showForm}
          onClose={() => setShowForm(false)}
          onSubmit={onSubmit}
          action={action}
          onUpdate={onUpdate}
          initialValues={action === 'Update' ? updateMenu : null}
        />
      </div>
    </>
  );
};
export default AddMenu;
