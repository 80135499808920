import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../utils/apiRequest';
import {
  IBusinessDetailsPayload,
  ICompanyDetailsPayload,
  ISignedUrlsResponse
} from '../../../../interfaces/b2bOnboarding';
import { ErrorMessage, SuccessMessage } from '../../../../utils/ResponseMessages';

export const companyDetails = async ({
  setLoading,
  payload,
  setBusinessID,
  callBackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setBusinessID: Dispatch<SetStateAction<string>>;
  payload: ICompanyDetailsPayload;
  callBackFunc?: () => void;
}) => {
  setLoading(true);
  try {
    const response = await httpClient?.post('/business/details', payload);
    setBusinessID(response?.data?.data?.businessId);
    SuccessMessage(response?.data?.message);
    if (callBackFunc) callBackFunc();
  } catch (error: any) {
    setBusinessID('');
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const businessDetails = async ({
  setLoading,
  payload,
  callBackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IBusinessDetailsPayload;
  callBackFunc?: () => void;
}) => {
  setLoading(true);
  try {
    const response = await httpClient?.post('/business/sales-description', payload);
    SuccessMessage(response?.data?.message);
    if (callBackFunc) callBackFunc();
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const submitDocument = async ({
  setLoading,
  businessId,
  documentId,
  documentType,
  callBackFunc,
  setSuccess
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  businessId: string;
  documentId: string;
  documentType: 'PAN' | 'AADHAR' | 'GST' | 'AGGREEMENT';
  callBackFunc?: () => void;
  setSuccess: Dispatch<SetStateAction<boolean>>;
}) => {
  setLoading(true);
  setSuccess(false);

  const payload = {
    businessId,
    documentId,
    documentType
  };

  try {
    const response = await httpClient?.post('/business/save-docs', payload);
    SuccessMessage(response?.data?.message);

    setSuccess(true);

    if (callBackFunc) callBackFunc();
  } catch (error: any) {
    setSuccess(false);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getSignedUrls = async ({
  setLoading,
  businessId,
  setUrlData,
  setIsRetry
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setIsRetry: Dispatch<SetStateAction<boolean>>;
  businessId: string;
  setUrlData: Dispatch<SetStateAction<ISignedUrlsResponse>>;
}) => {
  setIsRetry(false);
  setLoading(true);
  try {
    const response = await httpClient?.get(`business/url/${businessId}`);
    setIsRetry(false);
    setUrlData(response?.data?.data);
  } catch (error: any) {
    setIsRetry(true);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const uploadFile = async ({
  fileUrl,
  payload,
  contentType = 'image/jpeg',
  setLoading,
  setSuccess
}: {
  fileUrl: string;
  payload: ArrayBuffer;
  contentType?: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setSuccess: Dispatch<SetStateAction<boolean>>;
}) => {
  setLoading(true);
  setSuccess(false);
  const config = {
    method: 'PUT',
    headers: {
      'Content-Type': contentType
    },
    body: payload
  };

  try {
    const response = await fetch(fileUrl, config);

    if (response.ok) {
      SuccessMessage('Uploaded SuccessFully');
      setSuccess(true);
    }
  } catch (error: any) {
    setSuccess(false);
    console.log('upload error', error);
    ErrorMessage(error?.response?.data?.message || 'Upload failed.');
  } finally {
    setLoading(false);
  }
};
