import { Form, FormInstance, Input, Radio, Select } from 'antd';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { UPDATE_ORDERS_FIELDS } from '../../../../../../utils/constant';
import { ExcelToJson } from '../../../../../../utils/Excel-Conversion/ExcelToJson';

interface Props {
  // eslint-disable-next-line
  updateOrderForm: FormInstance<any>;
  onSubmit: () => void;
  isOnlyUpdate: boolean;
  setIsOnlyUpdate: Dispatch<SetStateAction<boolean>>;
  selectedFields: string[];
  setSelectedFields: Dispatch<SetStateAction<string[]>>;
  setErrorMsg: Dispatch<SetStateAction<string>>;
  onClickFile: () => void;
  fileName: string;
  setFileName: Dispatch<SetStateAction<string>>;
  setOrdersFile: Dispatch<
    SetStateAction<
      {
        // eslint-disable-next-line
        [key: string]: any;
      }[]
    >
  >;
}

const UpdateOrdersForm: React.FC<Props> = ({
  updateOrderForm,
  onSubmit,
  isOnlyUpdate,
  setIsOnlyUpdate,
  selectedFields,
  setSelectedFields,
  setErrorMsg,
  onClickFile,
  fileName,
  setFileName,
  setOrdersFile
}) => {
  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setErrorMsg('');
    updateOrderForm?.resetFields();

    onClickFile();
    if (event.target.files?.length) {
      setFileName(event.target.files[0]?.name);
      const result = await ExcelToJson({ dataFile: event?.target?.files[0] });
      setOrdersFile(result);
    } else {
      setOrdersFile([]);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="max-w-[550px] w-full p-5 primary-box-shadow rounded-xl">
        <Form
          layout="vertical"
          scrollToFirstError
          id={'updateOrderForm'}
          form={updateOrderForm}
          onFinish={onSubmit}>
          <Form.Item
            name="updateType"
            label="Update Type :"
            rules={[{ required: true, message: 'Please select update type.' }]}
            initialValue={isOnlyUpdate}>
            <Radio.Group
              className="py-2 flex"
              onChange={({ target }) => setIsOnlyUpdate(target?.value)}
              value={isOnlyUpdate}>
              <Radio value={true}>Update Only</Radio>
              <Radio value={false}>Update & Place Order</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Update Fields :"
            name="updateFields"
            rules={[{ required: true, message: 'Please select fields.' }]}
            initialValue={selectedFields}>
            <Select
              mode="multiple"
              placeholder="Select fields."
              optionFilterProp="label"
              allowClear
              onChange={(value: string[]) => {
                setSelectedFields(value);
              }}
              options={UPDATE_ORDERS_FIELDS}
            />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please upload a File!'
              }
            ]}
            label="Upload CSV/Excel (XLXS) File"
            name={'updateOrdersFile'}>
            <div
              className="flex items-center gap-2"
              title={selectedFields?.length ? '' : 'Select Fields to update.'}>
              <Input
                disabled={!selectedFields?.length}
                className="w-[95px] pl-1"
                type="file"
                id="files"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={onFileChange}
                onClick={onClickFile}
                suffix={false}
              />
              <div title={fileName}>
                {fileName?.length > 20 ? fileName?.slice(0, 20) + '...' : fileName}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UpdateOrdersForm;
