import React, { FC, Dispatch, SetStateAction } from 'react';
import { Modal, Table } from 'antd';
import { IB2BOrderItem } from '../../../../../interfaces/b2bV3OrdersList';
import { ColumnsType } from 'antd/es/table';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';
import moment from 'moment';

interface IItemTableModal {
  isItemsModalOpen: boolean;
  setIsItemsModalOpen: (value: boolean) => void;
  selectedItems: IB2BOrderItem[];
  setSelectedItems: Dispatch<SetStateAction<IB2BOrderItem[]>>;
}
const ItemTableModal: FC<IItemTableModal> = ({
  isItemsModalOpen,
  setIsItemsModalOpen,
  selectedItems,
  setSelectedItems
}) => {
  const handleOnClose = () => {
    setIsItemsModalOpen(false);
    setSelectedItems([]);
  };

  const tableColumns: ColumnsType<IB2BOrderItem> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <GetCopyTextDiv text={text} />
    },
    {
      title: 'HSN Code',
      dataIndex: 'hsnCode',
      key: 'hsnCode',
      render: (text) => <GetCopyTextDiv text={text} />
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      render: (text: string) =>
        text ? <span>{moment(text).format('YYYY-MM-DD, HH:mm:ss')}</span> : 'NA'
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      render: (text) => (text ? <GetCopyTextDiv text={text} /> : 'NA')
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text) => text || 'NA'
    },
    {
      title: 'Sub Category',
      dataIndex: 'subCategory',
      key: 'subCategory',
      render: (text) => text || 'NA'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => text || 'NA'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text) => text || 'NA'
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (text) => text || 'NA'
    },
    {
      title: 'Total Price',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (text) => text || 'NA'
    }
  ];

  return (
    <Modal
      width={'90%'}
      title={`Items ( ${selectedItems?.length} )`}
      open={isItemsModalOpen}
      onCancel={handleOnClose}
      footer={false}>
      <Table
        className="py-3"
        pagination={false}
        scroll={{ x: 1400 }}
        columns={tableColumns}
        dataSource={selectedItems}
      />
    </Modal>
  );
};

export default ItemTableModal;
