import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Select } from 'antd';
import React, { FC } from 'react';
import { SHIPPING_SERVICE_TYPE_OPTIONS, formValidations } from '../constants';
import { FaMinusCircle } from 'react-icons/fa';
import Formlabel from '../../../../CommonComponents/FormLabel';

interface IShippingMode {
  shippingModeCount: number;
  selectedShippingModes: string[];
}
const ShippingMode: FC<IShippingMode> = ({ shippingModeCount, selectedShippingModes }) => {
  return (
    <div>
      <Divider />
      <h2 className="text-xl font-bold mb-5 select-none">Shipping Mode :</h2>

      <Form.List
        name="shippingMode"
        initialValue={[
          { serviceType: undefined, isDomesticChosen: false, isInternationalChosen: false }
        ]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <>
                <Formlabel labelText="Service Type" required />
                <div key={key} className="mt-3 w-full flex gap-1 justify-between md:gap-5 mb-5">
                  <div className="flex flex-col md:flex-row w-[75%] sm:w-[90%] gap-5">
                    <div className="w-full md:w-[60%] flex flex-col gap-5">
                      <Form.Item
                        {...restField}
                        className="mb-0"
                        rules={[
                          {
                            required: true,
                            message: formValidations?.shippingServiceType?.required
                          }
                        ]}
                        name={[name, 'serviceType']}>
                        <Select
                          allowClear
                          options={SHIPPING_SERVICE_TYPE_OPTIONS?.map((opt) => ({
                            ...opt,
                            disabled: selectedShippingModes?.includes(opt?.value)
                          }))}
                          placeholder="Select service type"
                        />
                      </Form.Item>
                    </div>

                    <div className="flex justify-evenly w-[40%]">
                      <Form.Item
                        {...restField}
                        className="mb-0"
                        initialValue={false}
                        valuePropName="checked"
                        name={[name, 'isDomesticChosen']}>
                        <Checkbox>Domestic</Checkbox>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        valuePropName="checked"
                        className="mb-0"
                        initialValue={false}
                        name={[name, 'isInternationalChosen']}>
                        <Checkbox>International</Checkbox>
                      </Form.Item>
                    </div>
                  </div>

                  <Button
                    disabled={shippingModeCount <= 1}
                    title={shippingModeCount <= 1 ? '' : `Remove`}
                    icon={<FaMinusCircle />}
                    className="flex w-[20px] justify-center items-center"
                    onClick={() => remove(name)}
                  />
                </div>
              </>
            ))}

            {shippingModeCount != SHIPPING_SERVICE_TYPE_OPTIONS?.length && (
              <Form.Item className="flex justify-center">
                <Button
                  className="flex items-center"
                  type="primary"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}>
                  Add
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default ShippingMode;
