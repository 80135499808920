export const COMPANY_TYPES = [
  {
    value: 'PARTNERSHIP',
    label: 'Partnership'
  },
  {
    value: 'PVT_LTD',
    label: 'PVT LTD'
  },
  {
    value: 'PROPRIETORSHIP',
    label: 'Proprietorship'
  }
];

export const CONTRACT_TYPES = [
  {
    value: 'B2B',
    label: 'B2B'
  },
  {
    value: 'B2C',
    label: 'B2C'
  }
];

export const formFieldValidations = {
  brandName: {
    required: 'Please enter your brand name',
    maxCharactersLength: 50,
    maxCharactersMessage: 'Maximum 50 characters allowed.'
  },
  registeredName: {
    required: 'Please enter your registered name',
    maxCharactersLength: 50,
    maxCharactersMessage: 'Maximum 50 characters allowed.'
  },
  companyType: {
    required: 'Please enter any type'
  },
  contractType: {
    required: 'Please enter any type'
  },
  sector: { required: 'Please enter category' },
  website: {
    required: 'Please enter website',
    patternValueRegex: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
    patternMessage: 'Please enter a valid website. ( Eg: https://coreyo.com) '
  },
  pocFirstName: {
    required: 'Please enter first name',
    maxCharactersLength: 20,
    maxCharactersMessage: 'Maximum 20 characters allowed.'
  },
  pocLastName: {
    required: 'Please enter last name',
    maxCharactersLength: 20,
    maxCharactersMessage: 'Maximum 20 characters allowed.'
  },
  pocMobileNum: {
    required: 'Please enter mobile number',
    patternValueRegex: /^[0-9]{10}$/,
    patternMessage: 'Please enter a valid mobile number'
  },
  pocEmail: {
    required: 'Please enter email',
    patternValueRegex: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
    patternMessage: 'Please enter valid email'
  },
  pocDesignation: {
    required: 'Please enter designation',
    maxCharactersLength: 50,
    maxCharactersMessage: 'Maximum 50 characters allowed.'
  },
  contractExpiry: {
    required: 'Please enter date.'
  },
  userFirstName: {
    required: 'Please enter first name',
    maxCharactersLength: 20,
    maxCharactersMessage: 'Maximum 20 characters allowed.'
  },
  userLastName: {
    required: 'Please enter last name',
    maxCharactersLength: 20,
    maxCharactersMessage: 'Maximum 20 characters allowed.'
  },
  userContactNo: {
    required: 'Please enter mobile number',
    patternValueRegex: /^[0-9]{10}$/,
    patternMessage: 'Please enter a valid mobile number'
  },
  userEmail: {
    required: 'Please enter email',
    patternValueRegex: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
    patternMessage: 'Please enter valid email'
  },
  userPassword: {
    required: 'Please enter password'
  },
  userIsdCode: {
    required: 'Please enter ISD code'
  },
  formerBusinessEmail: {
    required: 'Please enter email',
    patternValueRegex: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
    patternMessage: 'Please enter valid email'
  }
};
