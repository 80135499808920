import { Button, Divider, Form, Input, Spin } from 'antd';
import React, { useState } from 'react';
import InvoiceShipmentButton from '../InvoiceShipmentButton';

const ShipmentLabelDownload: React.FC = () => {
  const [shipmentLabelForm] = Form.useForm();
  const [bulkId, setBulkId] = useState<string>('');
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);

  const handleBulkIdSubmit = ({ bulkId }: { bulkId: string }) => {
    setBulkId(bulkId);
  };

  return (
    <div>
      <h2 className="text-xl font-normal m-0 mb-4">Shipment Label Download</h2>

      <div className="flex justify-center">
        <div className="primary-box-shadow rounded-xl p-4">
          <div className="flex items-center flex-wrap gap-3 mb-3">
            <p className="m-0">Enter Bulk Id: </p>
            <div className="flex gap-3">
              <Form
                form={shipmentLabelForm}
                layout="vertical"
                id="shipmentLabelForm"
                onFinish={handleBulkIdSubmit}>
                <Form.Item name="bulkId" className="mb-0">
                  <Input placeholder="Bulk Order ID" value={bulkId} />
                </Form.Item>
              </Form>

              <Button className="primary-button" htmlType="submit" form="shipmentLabelForm">
                Submit
              </Button>
            </div>
          </div>
          {bulkId ? (
            <Spin spinning={invoiceLoading}>
              <Divider />
              <h2 className="mb-3 text-lg">Bulk Order ID: {bulkId}</h2>
              <InvoiceShipmentButton
                bulkId={bulkId}
                invoiceLoading={invoiceLoading}
                setInvoiceLoading={setInvoiceLoading}
              />
            </Spin>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ShipmentLabelDownload;
