import { Dispatch, SetStateAction } from 'react';
import { downloadBulkOrderReport } from '../../../CustomBulkOrders/api';
import { JsonToExcel } from '../../../../../utils/Excel-Conversion/JsonToExcel';

export const downloadBulkOrder = async ({
  setIsReportLoading,
  bulkId
}: {
  setIsReportLoading: Dispatch<SetStateAction<boolean>>;
  bulkId: string;
}) => {
  const flattenObjects = (
    bulkOrders: {
      // eslint-disable-next-line
      [key: string]: any;
    }[]
    // eslint-disable-next-line
  ): Promise<any[]> => {
    return new Promise((resolve) => {
      const result = [];
      for (const order of bulkOrders) {
        // eslint-disable-next-line
        const flattenedObj: any = {};

        for (const key in order) {
          if (typeof order[key] === 'object' && !Array.isArray(order[key])) {
            for (const subKey in order[key]) {
              flattenedObj[`${key}_${subKey}`] = order[key][subKey];
            }
          } else {
            flattenedObj[key] = order[key];
          }
        }

        // fields to remove from data
        delete flattenedObj?._id;
        delete flattenedObj?.__v;
        delete flattenedObj?.packageDetails;
        delete flattenedObj?.items;
        delete flattenedObj?.trackingDetails;
        delete flattenedObj?.userId;
        delete flattenedObj?.trackingSummary;
        delete flattenedObj?.updatedAt;
        delete flattenedObj?.ewayBill;
        delete flattenedObj?.expiryDate;
        delete flattenedObj?.shipmentLabel;

        result.push(flattenedObj);
      }

      resolve(result);
    });
  };

  const reponse = await downloadBulkOrderReport({
    setLoading: setIsReportLoading,
    bulkOrderId: bulkId
  });

  if (reponse?.length) {
    const result = await flattenObjects(reponse);

    JsonToExcel({
      dataFile: result,
      filename: `Bulk-Order-Report-${bulkId}`
    });
  }
};
