import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../utils/apiRequest';
import { showMessage } from '../../../utils/ResponseMessages';
import {
  ICODServiceProvidersList,
  IServiceProvidersListProps,
  IUpdateServiceabilityPayload
} from '../../../interfaces/serviceProvider';

export const getCodServicesList = async ({
  setLoading,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<ICODServiceProvidersList[]>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient?.get(`/cod-services/get`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData([]);
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};

export const handleCODServiceability = async ({
  setLoading,
  callBackFun,
  payload
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  callBackFun?: () => void;
  payload: ICODServiceProvidersList;
}) => {
  setLoading(true);
  try {
    await httpClient?.post(`/cod-services/update`, payload);
    showMessage('Updated successfully', 200);

    if (callBackFun) {
      callBackFun();
    }
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};

export const getServiceProvidersList = async ({
  setLoading,
  setData,
  portalName
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IServiceProvidersListProps[]>>;
  portalName: string;
}) => {
  setLoading(true);
  try {
    const response = await httpClient?.get(`/serviceability/get?portalName=${portalName}`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData([]);
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};

// NOTE: Same API for update and add Serviceability, while adding: set isActive as false in the payload
export const handleServiceability = async ({
  setLoading,
  callBackFun,
  payload
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  callBackFun?: () => void;
  payload: IUpdateServiceabilityPayload;
}) => {
  setLoading(true);
  try {
    await httpClient?.post(`/serviceability/update`, payload);
    showMessage('Updated successfully', 200);

    if (callBackFun) {
      callBackFun();
    }
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};
