import React, { FC } from 'react';
import { ICompanyDetailsData } from '../../../../../interfaces/b2bVerification';
import { Button, Image, Typography } from 'antd';

interface IDocuments {
  detailsData: ICompanyDetailsData;
}

const Documents: FC<IDocuments> = ({ detailsData }) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex gap-8 flex-wrap">
        {!!detailsData?.urls?.aadharUrl && (
          <div className="flex flex-col justify-start primary-box-shadow px-5 py-2 rounded-md gap-4 w-full max-w-[200px]">
            <h2 className="font-medium">Aadhaar: </h2>
            {!!detailsData?.businessKycDetails?.find((item) => item?.documentType === 'AADHAR')
              ?.documentId && (
              <Typography.Text
                className="font-medium flex items-center"
                copyable={{
                  text: detailsData?.businessKycDetails?.find(
                    (item) => item?.documentType === 'AADHAR'
                  )?.documentId
                }}>
                {
                  detailsData?.businessKycDetails?.find((item) => item?.documentType === 'AADHAR')
                    ?.documentId
                }
              </Typography.Text>
            )}

            <Button
              type="primary"
              className="rounded-sm w-full max-w-[200px]"
              title="Aadhaar Document"
              href={detailsData?.urls?.aadharUrl}
              target="_blank">
              View
            </Button>
          </div>
        )}
        {!!detailsData?.urls?.agreementUrl && (
          <div className="flex flex-col justify-start primary-box-shadow px-5 py-2 rounded-md gap-4 w-full max-w-[200px]">
            <h2 className="font-medium">Agreement Document: </h2>

            <Button
              type="primary"
              className="rounded-sm w-full max-w-[200px]"
              title="Agreement Document"
              href={detailsData?.urls?.agreementUrl}
              target="_blank">
              View
            </Button>
          </div>
        )}
      </div>

      <div className="flex flex-wrap gap-8">
        {!!detailsData?.urls?.panUrl && (
          <div className="flex flex-col gap-2 justify-start primary-box-shadow px-5 py-2 rounded-md">
            <h2 className="mb-2 font-medium">Pan Card Document: </h2>

            {!!detailsData?.businessKycDetails?.find((item) => item?.documentType === 'PAN')
              ?.documentId && (
              <Typography.Text
                className="font-medium flex items-center"
                copyable={{
                  text: detailsData?.businessKycDetails?.find(
                    (item) => item?.documentType === 'PAN'
                  )?.documentId
                }}>
                {
                  detailsData?.businessKycDetails?.find((item) => item?.documentType === 'PAN')
                    ?.documentId
                }
              </Typography.Text>
            )}

            <Image
              className="rounded-sm w-full max-w-[200px]"
              height={150}
              title="Pan Card Document"
              src={detailsData?.urls?.panUrl}
            />
          </div>
        )}
        {!!detailsData?.urls?.gstUrl && (
          <div className="flex flex-col gap-2 justify-start primary-box-shadow px-5 py-2 rounded-md">
            <h2 className="mb-2 font-medium">GST Document: </h2>

            {!!detailsData?.businessKycDetails?.find((item) => item?.documentType === 'GST')
              ?.documentId && (
              <Typography.Text
                className="font-medium flex items-center"
                copyable={{
                  text: detailsData?.businessKycDetails?.find(
                    (item) => item?.documentType === 'GST'
                  )?.documentId
                }}>
                {
                  detailsData?.businessKycDetails?.find((item) => item?.documentType === 'GST')
                    ?.documentId
                }
              </Typography.Text>
            )}

            <Image
              className="rounded-sm w-full max-w-[200px]"
              height={150}
              title="GST Document"
              src={detailsData?.urls?.gstUrl}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Documents;
