import MapUserMenu from './MapUserMenu';
import React from 'react';

const ManageMenu: React.FC = () => {
  return (
    <>
      <div className="mb-12 text-lg">Manage Menu Page</div>

      <MapUserMenu />
    </>
  );
};

export default ManageMenu;
