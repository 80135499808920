import React, { FC } from 'react';
import { ICompanyDetailsData } from '../../../../../interfaces/b2bVerification';
import { Empty, Tag } from 'antd';
import getRandomString from '../../../../../utils/getRandomString';

interface IAddressDetails {
  detailsData: ICompanyDetailsData;
}

const AddressDetails: FC<IAddressDetails> = ({ detailsData }) => {
  return detailsData?.businessPocDetails?.length ? (
    <div className="flex flex-wrap grow gap-6">
      {detailsData?.address?.map((item) => (
        <>
          <div
            className={`w-full lg:max-w-[700px] grow md:w-auto px-4 py-3 rounded primary-box-shadow border-gray-100 flex flex-col gap-1 relative ${
              item?.default ? 'bg-[#e6fffb] border border-[#87e8de]' : 'bg-white'
            }`}
            key={getRandomString(5)}>
            {item?.default && (
              <Tag color="cyan" className="font-bold border-2 absolute right-1 top-1 m-0">
                Default
              </Tag>
            )}

            {!!item?.tag && (
              <Tag color="blue" className="uppercase text-center font-medium mb-2">
                {item?.tag}
              </Tag>
            )}

            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[120px]  flex justify-between">
                ID <span>:</span>
              </p>
              {item?.id ? <p className="text-clip">{item?.id}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[120px]  flex justify-between">
                Saved As <span>:</span>
              </p>
              {item?.savedAs ? (
                <p className="text-clip font-medium">{item?.savedAs?.split('_')?.join(' ')}</p>
              ) : (
                'NA'
              )}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[120px]  flex justify-between">
                Name <span>:</span>
              </p>
              {item?.name ? <p className="text-clip">{item?.name}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[120px]  flex justify-between">
                Company Name <span>:</span>
              </p>
              {item?.companyName ? <p className="text-clip">{item?.companyName}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[120px]  flex justify-between">
                Mobile Num <span>:</span>
              </p>
              {item?.mobileNo ? (
                <p className="text-clip">
                  {item?.isdCode} - {item?.mobileNo}
                </p>
              ) : (
                'NA'
              )}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[120px]  flex justify-between">
                Alternate Num <span>:</span>
              </p>
              {item?.email ? <p className="break-all">{item?.email}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[120px]  flex justify-between">
                Email <span>:</span>
              </p>
              {item?.email ? <p className="break-all">{item?.email}</p> : 'NA'}
            </div>

            <p className="pt-2 font-bold w-[120px]">Address :</p>

            <div className="flex flex-wrap gap-2">
              <p className="pl-3 font-medium w-[70px]  flex justify-between">
                Line 1 <span>:</span>
              </p>
              {item?.line1 ? <p className="text-clip">{item?.line1}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="pl-3 font-medium w-[70px]  flex justify-between">
                Line 2 <span>:</span>
              </p>
              {item?.line2 ? <p className="text-clip">{item?.line2}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="pl-3 font-medium w-[70px]  flex justify-between">
                Line 3 <span>:</span>
              </p>
              {item?.line3 ? <p className="text-clip">{item?.line3}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="pl-3 font-medium w-[70px]  flex justify-between">
                City <span>:</span>
              </p>
              {item?.city ? <p className="text-clip">{item?.city}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="pl-3 font-medium w-[70px]  flex justify-between">
                State <span>:</span>
              </p>
              {item?.state ? (
                <p className="text-clip">
                  {item?.state} - {item?.stateCode}
                </p>
              ) : (
                'NA'
              )}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="pl-3 font-medium w-[70px]  flex justify-between">
                Country <span>:</span>
              </p>
              {item?.country ? (
                <p className="text-clip">
                  {item?.country} - {item?.countryCode}
                </p>
              ) : (
                'NA'
              )}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="pl-3 font-medium w-[70px]  flex justify-between">
                Postal <span>:</span>
              </p>
              {item?.postalCode ? <p className="text-clip">{item?.postalCode}</p> : 'NA'}
            </div>
          </div>
        </>
      ))}
    </div>
  ) : (
    <Empty />
  );
};

export default AddressDetails;
