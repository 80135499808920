import React from 'react';

export const getCartStatus = (currStatus: number | string) => {
  switch (currStatus) {
    case 0:
      return <p className="text-red-400">FAILED</p>;
    case 1:
      return <p className="text-yellow-400">PROCESSING</p>;
    case 2:
      return <p className="text-green-400">MANIFESTED</p>;
    case 3:
      return <p className="text-grey-400">CANCELLED</p>;
    case 4:
      return <p className="text-grey-400">DRAFT</p>;
    default:
      return <p className="text-grey-400">Unknown</p>;
  }
};
