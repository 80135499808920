import React, { FC } from 'react';
import { ICompanyDetailsData } from '../../../../../interfaces/b2bVerification';
import { Divider, Empty, Tag } from 'antd';
import getRandomString from '../../../../../utils/getRandomString';
import AdditionalData from './AdditionalData';
interface IProductDescription {
  detailsData: ICompanyDetailsData;
}

const ProductDescription: FC<IProductDescription> = ({ detailsData }) => {
  return detailsData?.productDesc?.length ? (
    <>
      <div>
        {detailsData?.productDesc?.map((item, index) => (
          <>
            {detailsData?.productDesc?.length > 1 && (
              <p className="font-bold text-xl mt-2 mb-4">Product {index + 1}</p>
            )}
            <div key={getRandomString(6)} className="flex flex-col gap-1">
              <div className="flex flex-col gap-2" key={getRandomString(5)}>
                <div className="flex flex-wrap gap-2">
                  <p className="font-bold w-[150px]  flex justify-between">
                    Product Type <span>:</span>
                  </p>
                  {item?.productType ? <p className="text-clip">{item?.productType}</p> : 'NA'}
                </div>

                <div className="flex flex-wrap gap-2">
                  <p className="font-bold w-[150px]  flex justify-between">
                    Average Weight <span>:</span>
                  </p>
                  {item?.averageWeight ? <p className="text-clip">{item?.averageWeight}</p> : 'NA'}
                </div>

                <div className="flex flex-wrap gap-2">
                  <p className="font-bold w-[150px]  flex justify-between">
                    Monthly Dispatch <span>:</span>
                  </p>
                  {item?.monthlyDispatch ? (
                    <p className="text-clip">{item?.monthlyDispatch}</p>
                  ) : (
                    'NA'
                  )}
                </div>

                <div className="flex flex-wrap gap-2">
                  <p className="font-bold w-[150px]  flex justify-between">
                    Average Billing Value <span>:</span>
                  </p>
                  {item?.averageBillingValue ? (
                    <p className="text-clip">{item?.averageBillingValue}</p>
                  ) : (
                    'NA'
                  )}
                </div>

                <div className="flex flex-wrap gap-2 my-1">
                  <p className="font-bold w-[150px]  flex justify-between">
                    Sales Mode <span>:</span>
                  </p>
                  {item?.salesMode ? <Tag color="default">{item?.salesMode}</Tag> : 'NA'}
                </div>

                <div className="flex flex-wrap gap-2 my-1">
                  <p className="font-bold w-[150px]  flex justify-between">
                    Market Place <span>:</span>
                  </p>
                  <div>
                    {item?.marketPlace?.length
                      ? item?.marketPlace?.map((item) => (
                          <Tag color="default" key={item}>
                            {item}
                          </Tag>
                        ))
                      : 'NA'}
                  </div>
                </div>
              </div>

              <AdditionalData productInformation={item} />
            </div>

            {index + 1 != detailsData?.productDesc?.length && <Divider />}
          </>
        ))}
      </div>
    </>
  ) : (
    <Empty />
  );
};

export default ProductDescription;
