import React from 'react';
import { Tag } from 'antd';

export const RoleTags = ({ roles }: { roles: any }) => {
  const getColor = (input: string) => {
    switch (input.toUpperCase()) {
      case 'EMPLOYEE':
        return 'bg-blue-100';
      default:
        return 'bg-blue-700 text-white';
    }
  };

  const flat = roles.reduce(
    (accumulator: string[], currentValue: string[]) => accumulator.concat(currentValue),
    []
  );
  const uniqueArray: string[] = Array.from(new Set(flat));

  return (
    <div className="flex gap-2">
      {uniqueArray?.map((role: string) => (
        <Tag key={role} className={`mb-2 font-bold py-1 tracking-widest ${getColor(role)}`}>
          {role}
        </Tag>
      ))}
    </div>
  );
};
