import React from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import UserMenu from '../api';
import { useEffect, useState } from 'react';
import Button from 'antd/es/button';

interface UserMenuTableProps {
  department: string;
  role: string;
}

const UserMenuTable: React.FC<UserMenuTableProps> = ({ department, role }) => {
  const {
    error,
    isLoading,
    getUsersMenuList,
    getUsersMenu,
    menuMap,
    getMenuMappedData,
    getMappedMenu,
    deleteMenu
  } = UserMenu();

  const [selectedMenu, setSelectedMenu] = useState<any[]>([]);

  useEffect(() => {
    getUsersMenuList();
  }, []);

  useEffect(() => {
    handleMenuMapData();
  }, [department, role]);

  const handleMenuMapData = () => {
    getMenuMappedData({ department: department, role: role });
  };

  const addMenuToUser = async (Id: string) => {
    const payload = {
      menuId: Id,
      role: role,
      department: department
    };

    await menuMap({
      payload: payload,
      callbackFun: () => {
        handleMenuMapData();
        // Remove the selected menu item from the menu list
        getUsersMenuList();
      }
    });
  };

  const removeMenufromUser = async (id: number, menuId: string) => {
    await deleteMenu({
      id: id,
      callbackFun: handleMenuMapData
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Menu ID',
      dataIndex: 'menuId',
      key: 'menuId'
    },
    {
      title: 'Menu Name',
      dataIndex: 'menuName',
      key: 'menuName'
    },
    {
      title: 'Action',
      width: '15%',
      render: (MenuList) => (
        <div className="flex justify-between">
          <Button className="primary-button" onClick={() => addMenuToUser(MenuList.menuId)}>
            Add
          </Button>
        </div>
      )
    }
  ];

  const selectedMenuColumns: ColumnsType<any> = [
    {
      title: 'Menu ID',
      dataIndex: 'menuId',
      key: 'menuId'
    },
    {
      title: 'Action',
      width: '15%',
      render: (MenuList) => (
        <div className="flex justify-between">
          <Button
            className="primary-button"
            onClick={() => removeMenufromUser(MenuList._id, MenuList.menuId)}>
            Remove
          </Button>
        </div>
      )
    }
  ];

  return (
    <>
      <div>
        <h1>Selected Menu</h1>
        <Table
          dataSource={getMappedMenu?.MappedList}
          columns={selectedMenuColumns}
          loading={isLoading}
        />
      </div>
      <div>
        <h1>Menu Table</h1>
        <Table
          dataSource={getUsersMenu?.MenuList.filter(
            (menuItem: { menuId: string }) =>
              !getMappedMenu?.MappedList.some(
                (selectedMenuItem: { menuId: string }) =>
                  selectedMenuItem?.menuId === menuItem?.menuId
              )
          )}
          loading={isLoading}
          columns={columns}
        />
      </div>
    </>
  );
};

export default UserMenuTable;
