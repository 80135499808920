import { useState } from 'react';
import { httpClient } from '../../../../utils/apiRequest';
import { showMessage } from '../../../../utils/ResponseMessages';
import { clearCache } from '../../../../utils/clearCache';

const handleUserLogin = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authUser, setAuthUser] = useState<string | null>('');

  const generateBasicAuthHeader = (userMail: string, password: string) => {
    const credentials = `${userMail}:${password}`;
    const encodedCredentials = btoa(credentials);
    const authHeader = `Basic ${encodedCredentials}`;
    return authHeader;
  };

  const userLogin = async (email: string, password: string, callBackFun?: () => void) => {
    try {
      setIsLoading(true);
      httpClient.defaults.headers.common['authorization'] = generateBasicAuthHeader(
        email,
        password
      );
      const response = await httpClient.post('/auth/login');
      setIsLoading(false);
      setAuthUser(response?.headers?.at);
      localStorage.setItem('token', response?.headers?.at);
      showMessage(response?.data?.message, response?.status);
      window.location.href = '/dashboard';
      if (callBackFun) {
        callBackFun();
      }
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      setIsLoading(false);
    }
  };

  const userLogOut = async () => {
    try {
      setIsLoading(true);
      await httpClient.post('/auth/logout');
      setIsLoading(false);
      clearCache();
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      setIsLoading(false);
    }
  };
  return {
    userLogin,
    isLoading,
    authUser,
    setAuthUser,
    userLogOut
  };
};

export default handleUserLogin;
