import { httpClient } from './apiRequest';

export const clearCache = (refresh = false) => {
  localStorage.removeItem('token');
  httpClient.defaults.headers.common['authorization'] = '';
  if (!refresh) {
    window.location.href = '/signin';
  }
  // Clearing cookies
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  // Clearing Cache
  if ('caches' in window) {
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
  }
};
