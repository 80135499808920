import React, { FC } from 'react';
import { ICompanyDetailsData } from '../../../../../interfaces/b2bVerification';
import { Avatar, Divider, Typography } from 'antd';
import { MdBusiness } from 'react-icons/md';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { getNormalizedUrl } from '../../../../../utils/getNormalizedUrl';
import GetStatus from '../../../../../utils/GetStatus';

interface IBasicInformation {
  detailsData: ICompanyDetailsData;
}
const BasicInformation: FC<IBasicInformation> = ({ detailsData }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col md:flex-row gap-4 items-center">
        <div className="w-[50px]">
          <Avatar
            size={50}
            shape="square"
            src={detailsData?.logoUrl}
            icon={
              <div className="flex items-center justify-center pt-3">
                <MdBusiness />
              </div>
            }
          />
        </div>

        <div className="flex flex-col gap-1">
          <h2 className="text-xl font-medium">{detailsData?.brandName}</h2>
          {detailsData?.id && (
            <Typography.Text
              className="font-medium flex items-center"
              copyable={{ text: detailsData?.id }}>
              {detailsData?.id}
            </Typography.Text>
          )}
        </div>
      </div>

      {detailsData?.description && (
        <p className="max-w-[800px] lg:max-w-full py-1">{detailsData?.description}</p>
      )}

      <Divider className="my-2" />

      <div className="flex flex-col gap-2 lg:flex-row lg:gap-5 justify-between">
        <div className="w-full md:w-1/2 flex flex-col gap-2">
          <div className="flex gap-2 flex-wrap">
            <p className="font-bold w-[110px] sm:w-[150px] flex justify-between">
              Website <span>:</span>
            </p>

            {detailsData?.website ? (
              <a
                title={detailsData?.website}
                href={getNormalizedUrl(detailsData?.website)}
                className="text-blue-400 hover:text-blue-700 flex gap-2 m-0 items-center"
                target="blank">
                <span className="truncate w-full max-w-[110px] sm:max-w-[150px]">
                  {detailsData?.website}
                </span>
                <FaExternalLinkAlt />
              </a>
            ) : (
              'NA'
            )}
          </div>

          <div className="flex gap-2 flex-wrap">
            <p className="font-bold w-[110px] sm:w-[150px] flex justify-between">
              Sales Officer Id <span>:</span>
            </p>

            {detailsData?.salesOfficerId ? (
              <Typography.Text
                className="flex items-center"
                copyable={{ text: detailsData?.salesOfficerId }}>
                {detailsData?.salesOfficerId}
              </Typography.Text>
            ) : (
              'NA'
            )}
          </div>

          <div className="flex gap-2 flex-wrap">
            <p className="font-bold w-[110px] sm:w-[150px] flex justify-between">
              Sector <span>:</span>
            </p>
            {detailsData?.sector ? <p>{detailsData?.sector}</p> : 'NA'}
          </div>
        </div>

        <div className="w-full md:w-1/2 flex flex-col gap-2">
          <div className="flex gap-2 flex-wrap">
            <p className="font-bold w-[110px] sm:w-[150px] flex justify-between">
              Status <span>:</span>
            </p>
            {detailsData?.status ? (
              <GetStatus
                currStatus={detailsData?.status}
                SHOW_TAGS
                PROCESSING="PENDING"
                SUCCESS="ENABLED"
                FAILED="CLOSED"
                CANCELLED="DISABLED"
                UNKNOWN="NA"
                CUSTOM_LABEL={detailsData?.status?.toUpperCase()}
              />
            ) : (
              '---'
            )}
          </div>

          <div className="flex gap-2 flex-wrap">
            <p className="font-bold w-[110px] sm:w-[150px] flex justify-between">
              KYC Status <span>:</span>
            </p>
            {detailsData?.kycStatus ? (
              <GetStatus
                currStatus={detailsData?.kycStatus}
                SHOW_TAGS
                PROCESSING="PENDING"
                SUCCESS="VERIFIED"
                FAILED="REJECTED"
                CANCELLED="INCOMPLETE"
                UNKNOWN="NA"
                CUSTOM_LABEL={detailsData?.kycStatus?.toUpperCase()}
              />
            ) : (
              '---'
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
