import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import { Empty, Spin } from 'antd';

interface OrderData {
  date: {
    seconds: number;
  };
  failedOrders: number;
  successfulOrders: number;
  cancelledOrders: number;
}

interface MonthDailyOrderLineGraphProps {
  loading: boolean;
  ordersData?: OrderData[];
}

const MonthDailyOrderLineGraph: React.FC<MonthDailyOrderLineGraphProps> = ({
  loading,
  ordersData = []
}) => {
  const [graphData, setGraphData] = useState<any[]>([]);

  const orderKeyNames = {
    failed: 'Failed Order',
    success: 'Success Order',
    cancelled: 'Cancelled Order'
  };

  const formatDate = (timestamp: number) => {
    const givenDate = timestamp; // Unix timestamp in seconds
    const date = new Date(givenDate * 1000);

    return `${date?.getDate()}/${date?.getMonth() + 1}/${date?.getFullYear()}`;
  };

  useEffect(() => {
    const updatedData = ordersData?.map((order) => {
      return {
        date: formatDate(order?.date?.seconds),
        [orderKeyNames?.failed]: order?.failedOrders,
        [orderKeyNames?.success]: order?.successfulOrders,
        [orderKeyNames?.cancelled]: order?.cancelledOrders
      };
    });

    setGraphData(updatedData);
  }, [ordersData]);

  return (
    <div className="primary-box-shadow  lg:w-[50%] w-full p-4 rounded-md">
      <h3 className="mt-0 text-left my-5 text-lg font-normal">Current month daily order graph</h3>
      {loading ? (
        <div className="min-h-[500px] h-[90%] my-5 flex items-center justify-center">
          <Spin />
        </div>
      ) : ordersData?.length ? (
        <ResponsiveContainer height={500}>
          <LineChart data={graphData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <XAxis dataKey="date" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey={orderKeyNames?.failed}
              stroke="red"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey={orderKeyNames?.success}
              stroke="green"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey={orderKeyNames?.cancelled}
              stroke="orange"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div className="h-[90%] flex items-center justify-center">
          <Empty description={'No orders found.'} />
        </div>
      )}
    </div>
  );
};

export default MonthDailyOrderLineGraph;
