import { Button, Checkbox, Divider, Form, Typography } from 'antd';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import Formlabel from '../../../CommonComponents/FormLabel';
import AddressFields from '../AddressFields';
import { ICompanyDetailsForm, ICompanyDetailsPayload } from '../../../../interfaces/b2bOnboarding';
import PersonOfContact from './PersonOfContact';
import BasicDetails from './BasicDetails';
import UserDetails from './UserDetails';
import dayjs from 'dayjs';
import { companyDetails } from '../api';

interface ICompanyDetails {
  sameBillingAddress: boolean;
  setSameBillingAddress: Dispatch<SetStateAction<boolean>>;
  nextStep: () => void;
  setBusinessID: Dispatch<SetStateAction<string>>;
}

const CompanyDetails: FC<ICompanyDetails> = ({
  sameBillingAddress,
  setSameBillingAddress,
  nextStep,
  setBusinessID
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm<ICompanyDetailsForm>();
  const format = 'DD/MM/YYYY';
  const oneYearFromNow = dayjs().add(1, 'year');

  useEffect(() => {
    form.setFieldValue('isBillingAddressSame', true);
    form.setFieldValue('contractExpiry', oneYearFromNow);
  }, [form, oneYearFromNow]);

  const onSubmitDetails = async (values: ICompanyDetailsForm) => {
    let payload: ICompanyDetailsPayload = {
      brandName: values?.brandName?.trim(),
      registeredName: values?.registeredName?.trim(),
      companyType: values?.companyType,
      contractType: values?.contractType,
      sector: values?.sector?.trim(),
      contractExpiry: dayjs(values?.contractExpiry).format(format),
      poc: {
        firstName: values?.poc_firstName?.trim(),
        lastName: values?.poc_lastName?.trim(),
        contactNo: values?.poc_contactNo?.trim(),
        designation: values?.poc_designation?.trim()
      },
      user: {
        firstName: values?.user_firstName?.trim(),
        lastName: values?.user_lastName?.trim(),
        mobileNo: values?.user_contactNo?.trim(),
        isdCode: Number(values?.user_isdCode),
        email: values?.user_email?.trim()
      },
      companyAddress: {
        addressLine1: values?.companyAddressLine1?.trim(),
        addressLine2: values?.companyAddressLine2?.trim(),
        locality: values?.companyLocality?.trim(),
        city: values?.companyCity?.trim(),
        postalCode: values?.companyPostalCode?.trim(),
        state: values?.companyState?.trim(),
        mobileNo: values?.companyMobileNum?.trim(),
        isdCode: Number(values?.companyIsdCode)
      },
      isBillingAddressSame: values?.isBillingAddressSame
    };

    if (values?.website) {
      payload = {
        ...payload,
        website: values?.website?.trim()
      };
    }

    if (values?.poc_email) {
      payload = {
        ...payload,
        poc: {
          ...payload.poc,
          email: values?.poc_email?.trim()
        }
      };
    }

    if (values?.formerBusinessEmail) {
      payload = {
        ...payload,
        formerBusinessEmail: values?.formerBusinessEmail
      };
    }

    if (!values?.isBillingAddressSame) {
      payload = {
        ...payload,
        billingAddress: {
          addressLine1: values?.billingAddressLine1?.trim(),
          addressLine2: values?.billingAddressLine2?.trim(),
          city: values?.billingCity?.trim(),
          postalCode: values?.billingPostalCode?.trim(),
          state: values?.billingState?.trim(),
          mobileNo: values?.billingMobileNum?.trim(),
          isdCode: Number(values?.billingIsdCode)
        }
      };
    }

    await companyDetails({
      setLoading: setIsLoading,
      payload,
      setBusinessID,
      callBackFunc: nextStep
    });
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmitDetails}
        layout="vertical"
        scrollToFirstError
        id="companyDetailsForm"
        disabled={isLoading}>
        <BasicDetails format={format} oneYearFromNow={oneYearFromNow} />
        <PersonOfContact />

        <UserDetails />

        <Divider />

        <h2 className="text-xl font-bold mb-5 select-none">Address :</h2>

        <div>
          <Formlabel labelText="1. Company Address" />
          <AddressFields
            line_1_fieldName="companyAddressLine1"
            line_2_fieldName="companyAddressLine2"
            locality_fieldName="companyLocality"
            postalCode_fieldName="companyPostalCode"
            city_fieldName="companyCity"
            state_fieldName="companyState"
            mobile_fieldName="companyMobileNum"
            isdCode_fieldName="companyIsdCode"
          />
        </div>

        <div className="my-4 flex items-center gap-3">
          <Form.Item className="m-0" name="isBillingAddressSame">
            <Checkbox
              checked={sameBillingAddress}
              onChange={() => setSameBillingAddress(!sameBillingAddress)}
              className="m-0"
            />
          </Form.Item>
          <Typography.Text className="m-0 select-none">
            <b>Billing Address</b> is same as <b>Company Address</b>
          </Typography.Text>
        </div>

        {!sameBillingAddress && (
          <div>
            <Formlabel labelText="2. Billing Address" />
            <AddressFields
              line_1_fieldName="billingAddressLine1"
              line_2_fieldName="billingAddressLine2"
              locality_fieldName="billingLocality"
              postalCode_fieldName="billingPostalCode"
              city_fieldName="billingCity"
              state_fieldName="billingState"
              mobile_fieldName="billingMobileNum"
              isdCode_fieldName="billingIsdCode"
            />
          </div>
        )}
      </Form>

      <div className="mt-5 mb-3 flex justify-center">
        <Button
          loading={isLoading}
          htmlType="submit"
          form="companyDetailsForm"
          className="primary-button">
          Submit & Proceed
        </Button>
      </div>
    </>
  );
};

export default CompanyDetails;
