import { message } from 'antd';

message.config({
  maxCount: 1
});

export const SuccessMessage = (description: string) =>
  message.success({
    content: description,
    className: 'custom-class',
    style: {
      marginTop: '50px',
      color: '#010E30',
      transitionDuration: '5s'
    }
  });

export const ErrorMessage = (description: string) =>
  message.error({
    content: description,
    className: 'custom-class',
    style: {
      marginTop: '50px',
      color: '#010E30',
      transitionDuration: '5s'
    }
  });

export const showMessage = (message: string, status: number) => {
  if (status) {
    if (status >= 200 && status <= 300) {
      SuccessMessage(message);
    } else {
      ErrorMessage(message);
    }
  } else {
    ErrorMessage('Something Went Wrong Please Try Again');
  }
};
