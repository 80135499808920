import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../../../../utils/apiRequest';
import { showMessage } from '../../../../../../../utils/ResponseMessages';

export const createJiraTicket = async ({
  orderId,
  setIsLoading
}: {
  orderId: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  setIsLoading(true);
  try {
    const response = await httpClient.get(`/jira/create-ticket?orderId=${orderId}`);
    showMessage(response?.data?.message, response?.status);
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.Status);
  } finally {
    setIsLoading(false);
  }
};
