import { Empty, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

interface YearlyData {
  month: number;
  successfulThisMonth: number;
}

interface YearlyOrderGraphProps {
  yearlyData: YearlyData[];
  loading: boolean;
}

const YearlyOrderGraph: React.FC<YearlyOrderGraphProps> = ({ yearlyData, loading }) => {
  const [graphData, setGraphData] = useState<
    Array<{ month: string; 'Successful This Month': number | null }>
  >([]);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  function getMonthName(monthNumber: number) {
    return months[monthNumber - 1];
  }

  useEffect(() => {
    const formattedData = months.map((month) => {
      const dataForMonth = yearlyData.find((d) => getMonthName(d.month) === month);

      return {
        month: month,
        'Successful This Month': dataForMonth ? dataForMonth.successfulThisMonth : null
      };
    });

    setGraphData(formattedData);
  }, [yearlyData]);

  return (
    <div className="primary-box-shadow lg:w-[50%] w-full rounded-xl p-4">
      <h3 className="mt-0 text-left my-5 text-lg font-normal">Yearly Average Order Graph</h3>
      {loading ? (
        <div className="min-h-[500px] h-[90%] my-5 flex items-center justify-center">
          <Spin />
        </div>
      ) : yearlyData?.length ? (
        <ResponsiveContainer height={500}>
          <BarChart data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Successful This Month" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div className="h-[90%] flex items-center justify-center">
          <Empty description={'No orders found.'} />
        </div>
      )}
    </div>
  );
};

export default YearlyOrderGraph;
