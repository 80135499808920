import { Button, Table, Tabs, TabsProps, Tag } from 'antd';
import React, { FC, useState } from 'react';
import {
  IBusinessPresence,
  IProductDesc,
  IShippingModeData,
  IValueAddedServiceEvents,
  IValueAddedServices
} from '../../../../../../interfaces/b2bVerification';
import { ColumnsType } from 'antd/es/table';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import GetStatus from '../../../../../../utils/GetStatus';
import EventsModal from '../EventsModal';

interface IAdditionalData {
  productInformation: IProductDesc;
}
const AdditionalData: FC<IAdditionalData> = ({ productInformation }) => {
  const [showEventModal, setShowEventModal] = useState<boolean>(false);
  const [eventModalData, setEventModalData] = useState<IValueAddedServiceEvents[] | undefined>(
    undefined
  );

  const [modalTitle, setModalTitle] = useState<string>('');

  const shippingModeColumns: ColumnsType<IShippingModeData> = [
    {
      title: 'Service Type',
      dataIndex: 'serviceType',
      width: 200
    },
    {
      title: <div className="flex justify-center">Domestic</div>,
      dataIndex: 'isDomesticChosen',
      width: 200,
      render: (value) => (
        <div className="flex justify-center">
          {value ? <FaCheckCircle className="text-green-400" /> : <FaRegCircle />}
        </div>
      )
    },
    {
      title: <div className="flex justify-center">International</div>,
      dataIndex: 'isInternationalChosen',
      width: 200,
      render: (value) => (
        <div className="flex justify-center">
          {value ? <FaCheckCircle className="text-green-400" /> : <FaRegCircle />}
        </div>
      )
    }
  ];

  const businessPresenceColumns: ColumnsType<IBusinessPresence> = [
    {
      title: 'City',
      width: 200,
      dataIndex: 'city'
    },
    {
      title: 'Country',
      width: 200,
      dataIndex: 'country'
    }
  ];

  const valueAddedServiceColumns: ColumnsType<IValueAddedServices> = [
    {
      title: 'Service',
      dataIndex: 'service',
      width: 300,
      render: (value) => (value ? value?.split('_')?.join(' ') : 'NA')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 200,
      render: (status) => (
        <GetStatus
          currStatus={status}
          SHOW_TAGS
          FAILED="DISABLED"
          SUCCESS="ENABLED"
          CUSTOM_LABEL={status?.toUpperCase()}
        />
      )
    },
    {
      title: 'Details',
      dataIndex: 'events',
      width: 200,
      render: (events, item) =>
        events?.length ? (
          <Button onClick={() => onClickView(events, item?.service?.split('_')?.join(' '))}>
            View
          </Button>
        ) : (
          'NA'
        )
    }
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Shipping Mode',
      children: (
        <div className="flex flex-col w-full">
          <Table
            className="w-full max-w-[500px]"
            pagination={false}
            dataSource={productInformation?.shippingMode}
            columns={shippingModeColumns}
            scroll={{ x: 500 }}
          />
        </div>
      )
    },
    {
      key: '2',
      label: 'Business Presence',
      children: (
        <Table
          className="w-full max-w-[500px]"
          dataSource={productInformation?.businessPresence}
          scroll={{ x: 500 }}
          columns={businessPresenceColumns}
        />
      )
    },
    {
      key: '3',
      label: 'Account Details',
      children: (
        <div className="flex flex-col w-full">
          <div className="flex flex-wrap gap-2 my-1">
            <p className="font-medium w-[150px]  flex justify-between">
              Account Type <span>:</span>
            </p>
            {productInformation?.accountDetails?.accountType ? (
              <Tag color="default">{productInformation?.accountDetails?.accountType}</Tag>
            ) : (
              'NA'
            )}
          </div>

          <div className="flex flex-wrap gap-2 my-1">
            <p className="font-medium w-[150px]  flex justify-between">
              Credit Limit <span>:</span>
            </p>
            {productInformation?.accountDetails?.creditLimit ? (
              <p>{productInformation?.accountDetails?.creditLimit}</p>
            ) : (
              'NA'
            )}
          </div>

          {!!productInformation?.accountDetails?.valueAddedServices?.length && (
            <Table
              className="mt-3 w-full"
              dataSource={productInformation?.accountDetails?.valueAddedServices}
              scroll={{ x: 500 }}
              columns={valueAddedServiceColumns}
            />
          )}
        </div>
      )
    }
  ];

  const onClickView = (data: IValueAddedServiceEvents[], title = 'NA') => {
    setModalTitle(title);
    setEventModalData(data);
    setShowEventModal(true);
  };

  const onCloseEventModal = () => {
    setShowEventModal(false);
    setEventModalData(undefined);
    setModalTitle('');
  };
  return (
    <>
      <div className="custom-tabs flex w-full mt-2">
        <Tabs className="w-full" defaultActiveKey="1" items={items} />
      </div>

      <EventsModal
        isOpen={showEventModal}
        onClose={onCloseEventModal}
        eventModalData={eventModalData}
        modalTitle={modalTitle}
      />
    </>
  );
};

export default AdditionalData;
