import { Button, Form, Input } from 'antd';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { formValidations } from '../constants';
import UploadFileInput from '../UploadFileInput';
import { uploadFile } from '../../api';
import { ErrorMessage } from '../../../../../utils/ResponseMessages';
import { IDocumentUpload } from '../../../../../interfaces/b2bOnboarding';
import { FaCheckCircle } from 'react-icons/fa';

interface IPanCardDetails {
  fileUrl: string;
  isFormDisabled: boolean;
  handleOnSubmit: ({ documentId, documentType }: IDocumentUpload) => Promise<void>;
  documentSuccess: boolean;
  setDocumentSuccess: Dispatch<SetStateAction<boolean>>;
  fileUploadSuccess: boolean;
  setFileUploadSuccess: Dispatch<SetStateAction<boolean>>;
}

const PanCardDetails: FC<IPanCardDetails> = ({
  isFormDisabled,
  fileUrl,
  handleOnSubmit,
  documentSuccess,
  setDocumentSuccess,
  fileUploadSuccess,
  setFileUploadSuccess
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleUpload = async () => {
    if (!file) {
      ErrorMessage('Please select a file first.');
      return;
    }

    const binaryData = await file.arrayBuffer();

    if (file) {
      await uploadFile({
        setLoading: setUploading,
        fileUrl,
        payload: binaryData,
        setSuccess: setFileUploadSuccess
      });
    }
  };

  const onSubmitForm = async (values: { panNumber: 'string' }) => {
    handleOnSubmit({
      documentId: values?.panNumber?.toUpperCase()?.trim(),
      documentType: 'PAN',
      setSuccess: setDocumentSuccess
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <Formlabel
        labelText={<h2 className="text-xl font-bold select-none">Pan Number : </h2>}
        required
      />

      <div className="flex flex-col gap-3">
        <Form
          onFinish={onSubmitForm}
          disabled={documentSuccess || isFormDisabled}
          className="w-full lg:w-3/4">
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-2">
            <Form.Item
              className="m-0 w-full sm:w-[80%]"
              name="panNumber"
              rules={[
                {
                  required: true,
                  message: formValidations?.pan?.required
                },
                {
                  pattern: formValidations?.pan?.patternRegex,
                  message: formValidations?.pan?.patternMessage
                }
              ]}>
              <Input
                placeholder="Enter pan number"
                suffix={documentSuccess && <FaCheckCircle className="text-green-400" />}
              />
            </Form.Item>
            <Button
              disabled={documentSuccess || isFormDisabled}
              className="m-0 primary-button w-full sm:w-[20%]"
              htmlType="submit">
              {documentSuccess ? 'Submitted' : 'Submit'}
            </Button>
          </div>
        </Form>

        <UploadFileInput
          inputId={'gst_file'}
          file={file}
          setFile={setFile}
          uploading={uploading}
          handleUpload={handleUpload}
          fileUploadSuccess={fileUploadSuccess}
          setFileUploadSuccess={setFileUploadSuccess}
          isDisabled={isFormDisabled}
        />
      </div>
    </div>
  );
};

export default PanCardDetails;
