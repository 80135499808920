import axios from 'axios';
import { TRACKING_URL } from '../../../utils/config';
import { showMessage } from '../../../utils/ResponseMessages';
import { notification } from 'antd';

export const downloadCurrentTracking = async ({
  startDate,
  endDate,
  providerName,
  userId
}: {
  startDate: Date;
  endDate: Date;
  providerName: string;
  userId?: string;
}) => {
  const url = `${TRACKING_URL}/api/v1/downloadTracking`;
  const payload = {
    userId,
    startDate,
    endDate,
    providerName
  };
  const response = await axios.post(url, payload);
  if (response.status !== 200) {
    throw new Error('Failed to download tracking data');
  }
  return response.data.data;
};

export const uploadManualTracking = async ({
  file,
  providerName
}: {
  file: File;
  providerName: string;
}) => {
  try {
    const url = `${TRACKING_URL}/api/v1/uploadManualTracking`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('providerName', providerName);
    const response = await axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data.data;
  } catch (error: any) {
    console.log(error, 40);
    notification.error({
      message: error?.response?.data?.error?.message || 'Something went wrong'
    });
    throw error;
  }
};
