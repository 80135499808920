import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserForm from './UserForm';
import UserCount from './UserCount';
import { CLOUDINARY_URL } from '../../../utils/config';
import moment from 'moment';
import UserMenu from '../ManageMenu/api';
import UsersData from './api';
import { UserFormDetails } from './interfaces/UserData';
import { RoleTags } from './utils/RoleTags';

const Users: React.FC = () => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState<boolean>(false);
  const { getUsersData, error, isLoading, getUsersList } = UserMenu();
  const { deleteUser, UsersList, updateUsersList } = UsersData();
  const [action, setAction] = useState('');
  const [updateUser, setUpdateUser] = useState<any>({});

  useEffect(() => {
    getUsersList();
  }, []);

  const handleViewDetails = (userDetails: string[]) => {
    navigate('/user-details', { state: { userDetails } });
  };

  const onUpdate = async (values: UserFormDetails, id: number) => {
    await updateUsersList({
      id: updateUser._id,
      payload: values,
      callbackFun: getUsersList
    });
    setShowForm(false);
  };

  const deleteUserDetails = async (id: number) => {
    await deleteUser({
      id: id,
      callbackFun: getUsersList
    });
  };

  const onSubmit = async (values: UserFormDetails) => {
    await UsersList({
      payload: values,
      callbackFun: getUsersList
    });
    setShowForm(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      width: '10%',
      render: (getUsersData) => (
        <>
          <h3 className="font-bold">{getUsersData?.firstName}</h3>
          <p>{getUsersData?.email}</p>
        </>
      ),
      responsive: ['sm']
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 300,
      render: (roles) => (roles?.length ? <RoleTags roles={roles} /> : '--')
    },
    {
      title: 'User Id',
      dataIndex: 'email',
      key: 'email',
      responsive: ['sm']
    },
    {
      title: 'Create Date',
      dataIndex: 'createDate',
      key: 'createDate',
      width: 210,
      render: (dom) => {
        return <span>{moment(dom).format('DD.MM.YYYY')}</span>;
      },
      responsive: ['sm']
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      key: 'mobile'
    },
    {
      title: 'Action',
      width: '15%',
      render: (getUsersData) => (
        <div className="flex justify-between">
          <Button
            className="border-none p-0 w-5 h-5"
            onClick={() => {
              setUpdateUser(getUsersData);
              setShowForm(true);
              setAction('Update');
            }}>
            <img src={`${CLOUDINARY_URL}/icons/arrow-circle-down_epd9xb.svg`} alt="" />
          </Button>
          <Button
            className="border-none p-0 w-5 h-5"
            onClick={() => deleteUserDetails(getUsersData._id)}>
            <img src={`${CLOUDINARY_URL}/icons/Delete_Icon.svg`} alt="" />
          </Button>
          <Button className="primary-button" onClick={() => handleViewDetails(getUsersData)}>
            View
          </Button>
        </div>
      )
    }
  ];
  return (
    <>
      <div className="top-row flex flex-row justify-between  mb-8 px-3 sm-w-48">
        <h2 className="text-xl font-bold m-0 mb-4 ">Users</h2>
        <Button
          className="primary-button w-28 h-11 py-2 px-2 font-bold"
          onClick={() => {
            setShowForm(true);
            setAction('Add');
          }}>
          ADD User
        </Button>
      </div>
      <UserCount />
      <div>
        <h4 className="ml-4 font-bold pt-4  text-xl ">List Users</h4>
        <Table
          scroll={{ x: 1600 }}
          columns={columns}
          dataSource={getUsersData?.data}
          loading={isLoading}
        />
        <UserForm
          isVisible={showForm}
          onClose={() => setShowForm(false)}
          onSubmit={onSubmit}
          action={action}
          onUpdate={onUpdate}
          initialValues={action === 'Update' ? updateUser : null}
        />
      </div>
    </>
  );
};

export default Users;
