import { Button, Tabs, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoleTags } from '../../utils/RoleTags';
import { FaEdit } from 'react-icons/fa';
import { MdOutlinePreview } from 'react-icons/md';

const UserDetails: React.FC = () => {
  const [detailsData, setDetailsData] = useState<{ title: string; value: any }[]>([]);
  const navigate = useNavigate();
  const state = useLocation()?.state;
  const user = state.userDetails;

  useEffect(() => {
    const newData = [
      {
        title: 'User Id',
        value: user?._id || '--'
      },
      {
        title: 'Department',
        value: user?.department || '--'
      },
      {
        title: 'First Name',
        value: user?.firstName || '--'
      },
      {
        title: 'Last Name',
        value: user?.lastName || '--'
      },
      {
        title: 'Preferred Language',
        value: user?.preferredLanguage || '--'
      },
      {
        title: 'Created At',
        value: new Date(user?.createdAt).toLocaleDateString() || '--'
      },
      {
        title: 'Updated At',
        value: new Date(user?.updatedAt).toLocaleDateString() || '--'
      },
      {
        title: 'Reporting To',
        value: user?.reportingTo || '--'
      }
    ];

    setDetailsData(newData);
  }, [user]);

  return (
    <>
      <Button className="m-0 p-0" type="link" onClick={() => navigate('/users')}>
        {`<`}
        <span className="hover:underline pl-1">Back to Orders List</span>
      </Button>
      <div className="font-bold text-2xl mt-8">User Information</div>
      <div className=" bg-white flex mt-20">
        <Tabs defaultActiveKey="tab1" type="card" className="pb-5 min-h-[500px]">
          <Tabs.TabPane tab="General Info" key="tab1" className="px-4 flex flex-col gap-3">
            {detailsData?.map((data) => (
              <div key={data?.title} className="flex gap-3  text-xl">
                <p className="w-[250px] flex justify-between font-bold">
                  {data?.title} <span>:</span>
                </p>
                <p className="capitalize">{data?.value}</p>
              </div>
            ))}
          </Tabs.TabPane>
          <Tabs.TabPane tab=" Contact Info" key="tab2" className="px-4 flex flex-col gap-3">
            <div className="flex gap-3 text-xl">
              <p className="w-[250px] flex justify-between font-bold">
                Mobile No <span>:</span>
              </p>
              <p className="capitalize">{user?.mobile || '--'}</p>
            </div>

            <div className="flex gap-3 text-xl">
              <p className="w-[250px] flex justify-between font-bold">
                Roles <span>:</span>
              </p>
              {user?.role?.length ? <RoleTags roles={user?.role} /> : '---'}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Permissions"
            key="tab3"
            className="px-4 flex flex-col items-center gap-3">
            <div className="flex gap-4">
              {user?.rights?.map((item: string) => (
                <div
                  key={item}
                  className={`flex items-center text-xl gap-1 ${
                    item?.toUpperCase() === 'READ' ? 'bg-green-400' : 'bg-blue-400'
                  } rounded-lg px-4 gy-2 hover:scale-110 duration-500 cursor-default`}>
                  <Tag
                    className={`m-0 font-bold text-xl py-1 tracking-widest border-none bg-transparent`}>
                    {item?.toUpperCase()}
                  </Tag>
                  {item?.toUpperCase() === 'READ' ? <FaEdit /> : <MdOutlinePreview />}
                </div>
              ))}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default UserDetails;
