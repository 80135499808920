import React, { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { getTickerMessage, updateTickerMessage } from './api';

interface ITickerValue {
  commonAlert: string;
  specialAlert: string;
}

const UpdateTicker = () => {
  const [loading, setLoading] = useState(true);
  const [tickerValue, setTickerValue] = useState<ITickerValue>({
    commonAlert: '',
    specialAlert: ''
  });

  const fetchTickerData = async () => {
    setLoading(false);
    getTickerMessage().then((res) => {
      setTickerValue((prevState) => ({
        ...prevState,
        commonAlert: res.commonAlert,
        specialAlert: res.specialAlert
      }));
    });
  };

  useEffect(() => {
    if (loading) fetchTickerData();
    else return;
  }, [loading]);

  return (
    <div>
      <p className="text-xl font-normal mb-4">Update Ticker</p>
      <p className="mt-5 text-left mb-2 text-lg font-normal">Common Alert</p>

      <InlineEdit
        id="commonAlert"
        value={tickerValue?.commonAlert}
        setTickerValue={setTickerValue}
        loading={loading}
        tickerValue={tickerValue}
      />

      <p className="mt-5 text-left mb-2 text-lg font-normal">Special Alert</p>
      <InlineEdit
        id="specialAlert"
        value={tickerValue?.specialAlert}
        setTickerValue={setTickerValue}
        loading={loading}
        tickerValue={tickerValue}
      />
    </div>
  );
};

interface IInlineEditProps {
  id: string;
  value: string;
  setTickerValue: React.Dispatch<React.SetStateAction<ITickerValue>>;
  loading: boolean;
  tickerValue: ITickerValue;
}

const InlineEdit: React.FC<IInlineEditProps> = ({
  id,
  value,
  setTickerValue,
  loading,
  tickerValue
}) => {
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTickerValue((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value
    }));
  };

  const updateTickerData = () => {
    updateTickerMessage({
      ...tickerValue
    });
  };

  if (loading) return null;

  return (
    <>
      <TextArea id={id} rows={4} aria-label="Field name" value={value} onChange={onChange} />
      <button
        onClick={updateTickerData}
        className="ant-btn css-dev-only-do-not-override-1wazalj ant-btn-default primary-button mt-5">
        Save
      </button>
    </>
  );
};

export default UpdateTicker;
