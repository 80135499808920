import { Divider, Form, Input } from 'antd';
import React from 'react';
import PasswordChangeFields from '../PasswordChangeFields';
import Formlabel from '../../../../CommonComponents/FormLabel';

const ChangePassword: React.FC = () => {
  return (
    <div className="flex justify-center">
      <div className="primary-box-shadow w-[90%] max-w-[500px] p-8 rounded-xl flex flex-col">
        <Form layout="vertical" requiredMark={false}>
          <Form.Item
            className="m-0"
            rules={[{ required: true, message: 'Please enter current password.' }]}
            name="currentPassword"
            label={<Formlabel labelText="Enter Current Password" />}>
            <Input placeholder="Current Password" type="text" />
          </Form.Item>
          <Divider />
          <PasswordChangeFields />
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
