import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import UploadFileInput from '../UploadFileInput';
import { ErrorMessage } from '../../../../../utils/ResponseMessages';
import { uploadFile } from '../../api';
import { Button } from 'antd';
import { B2B_AGREEMENT_LINK } from '../../../../../utils/config';
import { FaDownload } from 'react-icons/fa';

interface IAgreementDetails {
  fileUrl: string;
  fileUploadSuccess: boolean;
  setFileUploadSuccess: Dispatch<SetStateAction<boolean>>;
  isFormDisabled: boolean;
}

const AgreementDetails: FC<IAgreementDetails> = ({
  fileUrl,
  fileUploadSuccess,
  setFileUploadSuccess,
  isFormDisabled
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleUpload = async () => {
    if (!file) {
      ErrorMessage('Please select a file first.');
      return;
    }

    const binaryData = await file.arrayBuffer();

    if (file) {
      await uploadFile({
        setLoading: setUploading,
        fileUrl,
        contentType: 'application/pdf',
        payload: binaryData,
        setSuccess: setFileUploadSuccess
      });
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center flex-wrap justify-between gap-3 w-full lg:w-3/4">
        <Formlabel
          labelText={<h2 className="text-xl font-bold select-none">Agreement File : </h2>}
          required
        />

        <Button icon={<FaDownload />} type="primary" target="_blank" href={B2B_AGREEMENT_LINK}>
          Download Agreement
        </Button>
      </div>

      <UploadFileInput
        inputId={'agreement_file'}
        file={file}
        setFile={setFile}
        uploading={uploading}
        handleUpload={handleUpload}
        fileUploadSuccess={fileUploadSuccess}
        setFileUploadSuccess={setFileUploadSuccess}
        acceptedFiles=".pdf"
        isDisabled={isFormDisabled}
      />
    </div>
  );
};

export default AgreementDetails;
