import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Button, Switch, Table } from 'antd';
import { BiRefresh } from 'react-icons/bi';
import { ColumnsType } from 'antd/es/table';
import { GetProviderLogo } from '../../../../utils/GetProviderLogos';
import { IServiceProvidersListProps } from '../../../../interfaces/serviceProvider';
import { handleServiceability } from '../api';
import ConfirmModal from '../../../../utils/ConfirmModal';

interface IHandlePortalServiceability {
  currentPortalName: string;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  handleGetServiceabilityList: () => void;
  serviceList: IServiceProvidersListProps[];
}
const HandlePortalServiceability: FC<IHandlePortalServiceability> = ({
  currentPortalName,
  setIsLoading,
  isLoading,
  handleGetServiceabilityList,
  serviceList
}) => {
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const initialCurrentProviderState = {
    serviceProvider: '',
    serviceType: '',
    isActive: false,
    portalName: currentPortalName
  };

  const [currentProvider, setCurrentProvider] = useState(initialCurrentProviderState);

  const handleOnChange = (data: IServiceProvidersListProps) => {
    setCurrentProvider({
      ...currentProvider,
      serviceProvider: data?.serviceProvider,
      serviceType: data?.serviceType,
      isActive: data?.isActive
    });
    setConfirmModal(true);
  };

  const providerColumns: ColumnsType<IServiceProvidersListProps> = [
    {
      title: <div className="flex justify-center">Service Provider</div>,
      dataIndex: 'serviceProvider',
      render: (text) => (
        <div title={text} className="flex items-center justify-center">
          <img
            loading="lazy"
            className="max-w-[100px]"
            src={GetProviderLogo(text)}
            alt="provider"
          />
        </div>
      )
    },
    {
      title: <div className="flex justify-center">Service Type</div>,
      dataIndex: 'serviceType',
      render: (data) => (
        <div className="flex justify-center">
          <b>{data}</b>
        </div>
      )
    },
    {
      title: <div className="flex justify-center">Serviceability</div>,
      dataIndex: 'isActive',
      render: (isActive, data) => (
        <div className="flex justify-center">
          <Switch
            checked={isActive}
            className="bg-gray-300"
            onChange={() => handleOnChange(data)}
          />
        </div>
      )
    }
  ];

  const onConfirmChange = () => {
    handleServiceability({
      setLoading: setIsLoading,
      payload: { ...currentProvider, isActive: !currentProvider?.isActive },
      callBackFun: () => {
        handleGetServiceabilityList();
        setConfirmModal(false);
        setCurrentProvider(initialCurrentProviderState);
      }
    });
  };

  return (
    <>
      <div>
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-xl font-normal">{currentPortalName} Serviceability</h2>
          <Button
            onClick={() => handleGetServiceabilityList()}
            className="primary-button flex items-center gap-2">
            <BiRefresh size={20} />
            Refresh
          </Button>
        </div>

        <Table
          columns={providerColumns}
          dataSource={serviceList}
          loading={isLoading}
          rowKey={'_id'}
        />
      </div>

      <ConfirmModal
        isModalOpen={confirmModal}
        onClickOk={onConfirmChange}
        isLoading={isLoading}
        onCancel={() => setConfirmModal(false)}
        okText={currentProvider?.isActive ? 'Disable' : 'Enable'}
        modalBody={
          <div className="flex flex-col gap-2">
            <p className="m-0">
              Are you sure to <b>{currentProvider?.isActive ? 'disable' : 'enable'}</b> following
              service provider?
            </p>
            <p>
              <b>Name: {currentProvider?.serviceProvider}.</b>
            </p>
            <p>
              <b>Type: {currentProvider?.serviceType}.</b>
            </p>
          </div>
        }
      />
    </>
  );
};

export default HandlePortalServiceability;
