export const formValidations = {
  aadhaar: {
    required: 'Please enter Aadhaar number.',
    patternRegex: /^\d{12}$/,
    patternMessage: 'Invalid aadhaar number'
  },
  pan: {
    required: 'Please enter PAN number.',
    patternRegex: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/,
    patternMessage: 'Invalid PAN number. Eg ( AAAAA1111A )'
  },
  gst: {
    required: 'Please enter GST number.'
  }
};
