// eslint-disable-next-line
var XLSX = require('xlsx');

interface IArrayToExcelCsvProps {
  data: number[];
  filename: string;
}

export const ArrayToExcelCsv = ({ data, filename }: IArrayToExcelCsvProps) => {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');

  const formattedDate = `${day}-${month}-${year}-${hours}-${minutes}-${seconds}`;

  /* generate worksheet and workbook */
  const worksheet = XLSX.utils.aoa_to_sheet(data.map((num) => [num]));
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Numbers');

  /* create a CSV file and try to save */
  XLSX.writeFile(workbook, `${filename}-${formattedDate}.csv`);
};
