import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { authenticated } from '../config/userRoutes';
import { Layout } from 'antd';
import Sidebar from '../../components/Layout/Sidebar';
import MainContent from '../../components/Layout/MainContent';

interface AuthPageProps {
  isAuthenticated: boolean;
}

const { Header, Footer } = Layout;

const AuthPage: React.FC<AuthPageProps> = ({ isAuthenticated }) => {
  return (
    <div>
      <Layout>
        <Sidebar />

        <Layout>
          <Header className="primary-box-shadow p-0 bg-[#fff]">
            {/* Header content goes here */}
          </Header>

          <MainContent>
            <Routes>
              {isAuthenticated &&
                authenticated.map(({ routePath, Component }) => {
                  return <Route key={routePath} path={routePath} element={<Component />} />;
                })}
            </Routes>
          </MainContent>

          <Footer className="primary-box-shadow bg-[#fff] p-5 font-normal">
            Copyright Coreyo © 2023
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default AuthPage;
