import { showMessage } from '../../../utils/ResponseMessages';
import { httpClient } from '../../../utils/apiRequest';
import axios from 'axios';

export const getFintechOrders = async ({
  setLoading,
  setData,
  requestPayload
}: {
  setLoading: any;
  setData: any;
  requestPayload: any;
}) => {
  setLoading(true);

  try {
    let url = '/fintech-orders/cart-list';
    if (requestPayload.userId) {
      url += `?userId=${requestPayload.userId}`;
    }
    delete requestPayload.userId;
    const response = await httpClient.post(url, requestPayload);
    setData({
      carts: response?.data?.data?.carts,
      totalCart: response?.data?.data?.totalCart,
      totalPages: response?.data?.data?.totalPages
    });
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};

export const handleBulkInitiate = async (payload: any) => {
  try {
    const response = await httpClient.post(`/fintech-orders/initiate-bulk`, payload);
    showMessage(response?.data?.message, response?.status);
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};

export const bulkOrdersList = async ({
  payload,
  setData,
  setLoading,
  setTotalCount
}: {
  payload: any;
  setData: any;
  setLoading: any;
  setTotalCount: any;
}) => {
  try {
    const response = await httpClient.post(`/fintech-orders/bulk-order-list`, payload);
    await setData(response?.data?.data?.data);
    await setTotalCount(response?.data?.data?.totalCount);
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};

export const getCartDetails = async ({ query, setCartData, setCartLoading }: any) => {
  setCartLoading(true);
  try {
    const response = await httpClient.get(`/fintech-orders/booking-Details?orderId=${query}`);
    setCartData(response?.data?.data);
  } catch (error: any) {
    setCartData([]);
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setCartLoading(false);
  }
};

export const createBulkShipment = async (payload: any) => {
  try {
    const response = await httpClient.post(`/fintech-orders/create-bulk-shipment`, payload);
    showMessage(response?.data?.message, response?.status);
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};

export const getShipmentLableLink = async (payload: any) => {
  try {
    const response = await httpClient.post(`/fintech-orders/fintech-Bulk-lable`, payload);
    showMessage(response?.data?.message, response?.status);
    return response?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};

export const getFinTechUsers = async ({ setLoading }: { setLoading: any }) => {
  try {
    setLoading(true);
    const response = await httpClient.post(`/fintech-orders/users`);
    return response?.data?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};

export const deleteBulkShipment = async (payload: any) => {
  try {
    const response = await httpClient.post(`/fintech-orders/delete-booking`, payload);
    showMessage(response?.data?.message, response?.status);
    return response?.data?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};

export const getSuccessOrdersDeatils = async (requestPayload: any) => {
  try {
    const response = await httpClient.post(
      `/fintech-orders/success-booking-details`,
      requestPayload
    );
    return response?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};

export const uploadBookingReq = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fintech-orders/upload-booking`,
      payload,
      {
        headers: { 'Content-Type': 'multipart/form-data', authorization: `Bearer ${token}` }
      }
    );
    showMessage(response?.data?.message, response?.status);
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};

export const retryFailedUpload = async (bulkId: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fintech-orders/retry-failed-upload`,
      { bulkId },
      {
        headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }
      }
    );
    showMessage(response?.data?.message, response?.status);
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};
