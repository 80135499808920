import { Dispatch, SetStateAction, useState } from 'react';
import { showMessage } from '../../../../utils/ResponseMessages';
import { httpClient } from '../../../../utils/apiRequest';

const useOrderListDetails = () => {
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState({
    ordersList: [],
    totalPage: 0
  });
  const [refundOrdersData, setRefundOrdersData] = useState({
    refundList: [],
    totalPage: 0,
    currentDocument: 0,
    totalDocument: 0
  });
  const [singleOrderData, setSingleOrderData] = useState<any>({});
  const [refundDetailsData, setRefundDetailsData] = useState<any>({});
  const [getPaymentDetails, setGetPaymentDetails] = useState<any>({});

  const fetchStart = () => {
    setLoading(true);
    setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error: string) => {
    setLoading(false);
    setError(error);
  };

  const getOrdersList = async ({
    callbackFun,
    pageNumber = 1,
    pageLimit = 20,
    userId,
    from,
    to,
    orderId,
    awbNo,
    serviceProvider,
    orderStatus
  }: {
    callbackFun?: () => void;
    pageNumber?: number;
    pageLimit?: number;
    userId?: string;
    from?: string;
    to?: string;
    orderId?: string;
    awbNo?: string;
    serviceProvider?: string;
    orderStatus?: string;
  }) => {
    fetchStart();
    let payload = {} as {
      skip: number;
      limit: number;
      pageNumber?: number;
      pageLimit?: number;
      userId?: string;
      from?: string;
      to?: string;
      orderId?: string;
      awbNo?: string;
      serviceProvider?: string;
      orderStatus?: string;
    };

    if (pageLimit) {
      payload = {
        ...payload,
        skip: orderId || awbNo ? 0 : pageNumber - 1, // subtract by 1, As per api requirements, page number start from 0.
        limit: pageLimit
      };
    }

    if (userId) {
      payload = {
        ...payload,
        userId: userId
      };
    }
    if (from) {
      payload = {
        ...payload,
        from: from
      };
    }
    if (to) {
      payload = {
        ...payload,
        to: to
      };
    }
    if (orderId) {
      payload = {
        ...payload,
        orderId: orderId
      };
    }
    if (awbNo) {
      payload = {
        ...payload,
        awbNo
      };
    }
    if (serviceProvider) {
      payload = { ...payload, serviceProvider };
    }
    if (orderStatus) {
      payload = { ...payload, orderStatus };
    }

    try {
      const data = await httpClient.post(`/order/orders-list`, payload);
      fetchSuccess();
      setOrderData({
        ordersList: data?.data?.data?.ordersList,
        totalPage: data?.data?.data?.totalPage
      });
      if (callbackFun) {
        callbackFun();
      }
    } catch (error: any) {
      fetchError(error.message);
      showMessage(error?.response?.data?.message, error?.response?.status);
    }
  };

  const getRefundOrdersList = async ({
    callbackFun,
    orderId,
    pageNumber = 1,
    pageLimit = 20,
    from,
    to,
    paymentId,
    refundStatus
  }: {
    callbackFun?: () => void;
    orderId?: string;
    pageNumber?: number;
    pageLimit?: number;
    from?: string;
    to?: string;
    paymentId?: string;
    refundStatus?: string;
  }) => {
    fetchStart();

    const skip = orderId || paymentId ? 0 : pageNumber - 1; // subtract by 1, As per api requirements, page number start from 0.
    const orderid = orderId ? `&orderId=${orderId}` : '';
    const paymentid = paymentId ? `&paymentId=${paymentId}` : '';

    const dateRange = to ? `&from=${from}&to=${to}` : '';
    const status = refundStatus ? `&refundStatus=${refundStatus}` : '';

    try {
      const response = await httpClient.get(
        `/refund/list?limit=${pageLimit}&skip=${skip}${orderid}${dateRange}${paymentid}${status}`
      );
      fetchSuccess();
      setRefundOrdersData({
        refundList: response?.data?.data?.refundList,
        totalPage: response?.data?.data?.totalPage,
        totalDocument: response?.data?.totalDocument,
        currentDocument: response?.data?.currentDocument
      });
      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const getRefundOrderDetails = async ({
    callbackFun,
    orderId
  }: {
    callbackFun?: () => void;
    orderId?: string | number;
    pageNumber?: number;
    pageLimit?: number;
  }) => {
    fetchStart();

    try {
      const response = await httpClient.get(`/refund/details?orderId=${orderId}`);
      fetchSuccess();
      setRefundDetailsData(response?.data?.data);
      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const requestForRefund = async ({
    payload,
    callbackFun
  }: {
    payload: any;
    callbackFun?: () => void;
  }): Promise<any> => {
    fetchStart();
    try {
      const response = await httpClient.post(`/refund/request`, payload);
      fetchSuccess();
      showMessage(response?.data?.message, response?.data?.status);
      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const declineRefund = async ({
    payload,
    callbackFun
  }: {
    payload: any;
    callbackFun?: () => void;
  }): Promise<any> => {
    fetchStart();
    try {
      const response = await httpClient.post(`/refund/decline`, payload);
      fetchSuccess();
      showMessage(response?.data?.message, response?.data?.status);
      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const approveRefund = async ({
    payload,
    callbackFun
  }: {
    payload: any;
    callbackFun?: () => void;
  }): Promise<any> => {
    fetchStart();
    try {
      const response = await httpClient.post(`/refund/approve`, payload);
      fetchSuccess();
      showMessage(response?.data?.message, response?.data?.status);
      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const pushFailedOrderApi = async ({
    orderIDs = '',
    gatewayOrderID = '',
    payementId = '',
    isBulkOrderId = false,
    setResponseData,
    callbackFun
  }: {
    orderIDs: string;
    gatewayOrderID?: string;
    payementId?: string;
    isBulkOrderId: boolean;
    setResponseData: Dispatch<
      SetStateAction<{
        allOrders?: {
          [key: string]: string | boolean;
        }[];
        successOrders: string[];
        failedOrders: string[];
      }>
    >;
    callbackFun?: () => void;
  }) => {
    const payload: {
      failedOrders?: string | string[];
      custBulkOrderId?: string;
      paymentDetails: {
        gateway_order_id: string;
        payment_id: string;
      };
    } = {
      [isBulkOrderId ? 'custBulkOrderId' : 'failedOrders']: orderIDs,
      paymentDetails: {
        gateway_order_id: gatewayOrderID,
        payment_id: payementId
      }
    };

    fetchStart();
    try {
      const response = await httpClient.post(`/order/push-failed-order`, payload);
      fetchSuccess();
      showMessage(response?.data?.message, response?.status);

      setResponseData({
        successOrders: response?.data?.data?.response?.successOrderArray,
        failedOrders: response?.data?.data?.response?.failedOrderArray,
        allOrders: response?.data?.data?.response?.data
      });

      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
      return error;
    }
  };

  const getDetailsOfOrder = async ({ orderId }: { orderId: string }) => {
    fetchStart();

    try {
      const response = await httpClient.post(`/order/order-details`, { orderId });
      fetchSuccess();
      setSingleOrderData(response?.data?.data);
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const updateOrdersAPI = async ({
    ordersFile = [],
    isOnlyUpdate = false
  }: {
    ordersFile: {
      // eslint-disable-next-line
      [key: string]: any;
    }[];
    isOnlyUpdate: boolean;
  }) => {
    fetchStart();

    const payload: {
      data: {
        // eslint-disable-next-line
        [key: string]: any;
      }[];
      isOnlyUpdate: boolean;
    } = {
      data: ordersFile,
      isOnlyUpdate: isOnlyUpdate
    };
    try {
      const response = await httpClient.post(`/order/update-order`, payload);
      fetchSuccess();
      showMessage('Orders Updated Successfully', response?.status);
      return true;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
      return false;
    }
  };

  const PaymentDetails = async ({ orderId }: { orderId: string }) => {
    fetchStart();

    const payload: {
      orderId: string;
    } = {
      orderId: orderId
    };
    try {
      const response = await httpClient.post(`/order/pgoi`, payload);
      fetchSuccess();
      setGetPaymentDetails(response?.data);
      return response.data;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.data);
      fetchError(error?.message);
      return false;
    }
  };

  const executeRefundedOrder = async ({
    payload,
    callbackFun
  }: {
    payload: {
      orderId: string;
      actionBy: string;
      reasonOfAction: string;
    };
    callbackFun?: () => void;
  }) => {
    fetchStart();

    try {
      const response = await httpClient.post(`/refund/execute`, payload);
      fetchSuccess();
      showMessage(response?.data?.message, response?.data?.status);
      if (callbackFun) callbackFun();
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  return {
    getOrdersList,
    orderData,
    isLoading,
    error,
    requestForRefund,
    getRefundOrdersList,
    refundOrdersData,
    declineRefund,
    approveRefund,
    pushFailedOrderApi,
    getDetailsOfOrder,
    singleOrderData,
    updateOrdersAPI,
    PaymentDetails,
    getPaymentDetails,
    getRefundOrderDetails,
    refundDetailsData,
    executeRefundedOrder
  };
};
export default useOrderListDetails;
