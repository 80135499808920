import { httpClient } from '../../../utils/apiRequest';

export const getTickerMessage = async () => {
  try {
    const response = await httpClient.get('ticker/get-ticker');
    return response.data;
  } catch (error) {
    console.error('Error occurred while fetching ticker messages:', error);
    throw error;
  }
};

export const updateTickerMessage = async (payload: any) => {
  try {
    const response = await httpClient.post('ticker/update-ticker', { ...payload });
    return response.data;
  } catch (error) {
    console.error('Error occurred while updating ticker messages:', error);
    throw error;
  }
};
