import { Button, Form, FormInstance, Modal, Spin } from 'antd';
import React, { FC } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import TextArea from 'antd/es/input/TextArea';

interface IVerificationModal {
  showVerifyModal: boolean;
  closeModal: () => void;
  isAccept: boolean;
  onConfirm: ((values: any) => void) | undefined;
  businessId: string;
  verifyDetailsForm: FormInstance;
  confirmLoading: boolean;
}
const VerificationModal: FC<IVerificationModal> = ({
  showVerifyModal,
  closeModal,
  isAccept,
  onConfirm,
  businessId,
  verifyDetailsForm,
  confirmLoading
}) => {
  return (
    <Modal title={`Confirmation`} open={showVerifyModal} onCancel={closeModal} footer={false}>
      <Spin spinning={confirmLoading}>
        <hr className="mb-3" />
        <div className="flex flex-col gap-3">
          <p className="text-md">Please confirm to proceed</p>

          <div className="flex gap-2 flex-wrap">
            <p className="font-bold w-[110px] sm:w-[150px] flex justify-between">
              <Formlabel labelText="Business Id" /> <span>:</span>
            </p>
            {businessId}
          </div>

          <Form
            layout="vertical"
            form={verifyDetailsForm}
            onFinish={onConfirm}
            id="verifyDetailsForm">
            <Formlabel labelText="Description" />
            <Form.Item
              className="mt-3"
              name="description"
              rules={[{ required: true, message: 'Please enter description.' }]}>
              <TextArea placeholder="Enter description" maxLength={100} showCount />
            </Form.Item>
          </Form>

          <div className="flex justify-end gap-2">
            <Button onClick={closeModal}>Cancel</Button>

            <Button
              htmlType="submit"
              form="verifyDetailsForm"
              className={`${
                isAccept
                  ? 'bg-yellow-500 text-black hover:!text-black hover:!bg-yellow-550 hover:!border-yellow-600'
                  : 'bg-red-500 text-white hover:!text-white hover:!bg-red-550 hover:!border-red-500'
              }`}>
              {isAccept ? 'Accept' : 'Reject'}
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default VerificationModal;
