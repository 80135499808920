import { useState } from 'react';
import { showMessage } from '../../../../utils/ResponseMessages';
import { httpClient } from '../../../../utils/apiRequest';

const UsersData = () => {
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error: string) => {
    setLoading(false);
    setError(error);
  };

  const UsersList = async ({
    payload,
    callbackFun
  }: {
    payload: any;
    callbackFun?: () => void;
  }): Promise<any> => {
    fetchStart();
    try {
      const response = await httpClient.post(`internal-user/create`, payload);
      fetchSuccess();
      showMessage(
        response?.data?.data?.data?.menuId
          ? `${response?.data?.data?.data?.menuId} created successfully`
          : response?.data?.message,
        response?.status
      );

      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const deleteUser = async ({
    id,
    callbackFun
  }: {
    id?: number;
    callbackFun?: () => void;
  }): Promise<any> => {
    fetchStart();
    try {
      const response = await httpClient.delete(`internal-user/${id}`);
      fetchSuccess();

      showMessage(
        response?.data?.data?.data?.menuId
          ? `${response?.data?.data?.data?.menuId} deleted successfully`
          : response?.data?.message,
        response?.status
      );

      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const updateUsersList = async ({
    id,
    payload,
    callbackFun
  }: {
    id?: number;
    payload?: any;
    callbackFun?: () => void;
  }): Promise<any> => {
    fetchStart();
    try {
      const response = await httpClient.put(`internal-user/${id}`, payload);
      fetchSuccess();
      showMessage(
        response?.data?.data?.data?.menuId
          ? `${response?.data?.data?.data?.menuId} Updated successfully`
          : response?.data?.message,
        response?.status
      );

      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  return {
    error,
    isLoading,
    UsersList,
    deleteUser,
    updateUsersList
  };
};
export default UsersData;
