import React from 'react';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Button, Tag } from 'antd';
import { FaDownload } from 'react-icons/fa';
import { getCartStatus } from './functions';
import {
  IFintechAddressDetails,
  IGetFintechOrdersResponse,
  IGetFintechPackageDetails
} from '../../../interfaces/fintechOrders';

export const TableColumns = () => {
  const cartTableColumns: ColumnsType<IGetFintechOrdersResponse> = [
    {
      title: 'Order Id',
      dataIndex: 'orderId',
      width: 150,
      key: 'orderId'
    },
    {
      title: 'Order Type',
      width: 150,
      dataIndex: 'orderType',
      render: (orderType) => {
        switch (orderType) {
          case 1:
            return <Tag color="green">Prepaid</Tag>;
          case 2:
            return <Tag color="blue">Postpaid</Tag>;
          default:
            return '--';
        }
      }
    },
    {
      title: 'Date',
      dataIndex: 'scheduledAt',
      width: 120,
      render: (date) => <p className="w-[120px]">{moment(date).format('DD-MM-YYYY hh:mm:ss')}</p>
    },
    {
      title: (
        <div className="text-center">
          Total <br />
          Packages
        </div>
      ),
      dataIndex: 'packageDetails',
      width: 100,
      key: 'packageDetails',
      render: (packages) => <p className="w-[100px]">{packages?.length}</p>
    },
    {
      title: 'Cart Status',
      key: 'cartStatus',
      width: 150,
      dataIndex: 'cartStatus',
      render: (status) => getCartStatus(status)
    },
    {
      title: (
        <div className="text-center">
          Total <br />
          Height (cm)
        </div>
      ),
      dataIndex: 'totalHeight',
      width: 150
    },
    {
      title: (
        <div className="text-center">
          Total <br />
          Length (cm)
        </div>
      ),
      dataIndex: 'totalLength',
      width: 150
    },
    {
      title: (
        <div className="text-center">
          Total <br />
          Width (cm)
        </div>
      ),
      dataIndex: 'totalWidth',
      width: 150
    },
    {
      title: (
        <div className="text-center">
          Total <br />
          Weight (g)
        </div>
      ),
      dataIndex: 'totalWeight',
      width: 150
    },
    {
      title: 'Document',
      key: 'shipmentLink',
      dataIndex: 'shipmentLink',
      fixed: 'right',
      width: 150,
      render: (link) => (
        <Button
          disabled={!link?.length}
          icon={<FaDownload />}
          href={link}
          target="_blank"
          className="primary-button">
          Download
        </Button>
      )
    }
  ];

  const addressDetailsColumns: ColumnsType<IFintechAddressDetails> = [
    {
      title: '',
      dataIndex: 'rowHeading',
      fixed: 'left',
      width: 150,
      render: (data) => (data ? <p className="m-0 font-semibold">{data}</p> : '-')
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      width: 150,
      render: (data) => data ?? 'NA'
    },
    {
      title: 'Address line 1',
      dataIndex: 'line1',
      width: 200,
      render: (data) => data ?? 'NA'
    },
    {
      title: 'Address line 2',
      dataIndex: 'line2',
      width: 200,
      render: (data) => data ?? 'NA'
    },
    {
      title: 'City',
      dataIndex: 'city',
      width: 130,
      render: (data) => data ?? 'NA'
    },
    {
      title: 'State',
      dataIndex: 'state',
      width: 130,
      render: (state) => state ?? 'NA'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      width: 130,
      render: (country) => country ?? 'NA'
    },
    {
      title: (
        <div className="text-center">
          Postal <br />
          Code
        </div>
      ),
      dataIndex: 'pincode',
      width: 100,
      render: (data) => data ?? 'NA'
    },
    {
      title: 'Mobile Num',
      dataIndex: 'phoneNumber',
      width: 130,
      render: (phone) => (phone ?? 'NA')
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      width: 150,
      render: (data) => (data ? data : 'NA')
    }
  ];

  const packageDetailsColumns: ColumnsType<IGetFintechPackageDetails> = [
    {
      title: (
        <div className="text-center">
          Package <br /> Num
        </div>
      ),
      dataIndex: 'packageDetails',
      width: 80,
      fixed: 'left',
      render: (packageNumber) => packageNumber?.length ?? 'NA'
    },
    {
      title: 'Category',
      dataIndex: 'itemCategory',
      width: 150,
      render: (category) => category ?? 'NA'
    },
    {
      title: 'Description',
      dataIndex: 'itemDescription',
      width: 200,
      render: (description) => description ?? 'NA'
    },
    {
      title: 'Estimated Value',
      dataIndex: 'estimatedValue',
      width: 200,
      render: (value) => (value ? `₹ ${value}` : 'NA')
    },
    {
      title: 'Weight (g)',
      dataIndex: 'weight',
      width: 150
    },
    {
      title: 'Length (cm)',
      dataIndex: 'length',
      width: 150
    },
    {
      title: 'Height (cm)',
      dataIndex: 'height',
      width: 150
    },
    {
      title: 'Width (cm)',
      dataIndex: 'width',
      width: 150
    }
  ];

  return { cartTableColumns, addressDetailsColumns, packageDetailsColumns };
};
