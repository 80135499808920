import { Button, Form, Input, Spin, Table, TreeSelect } from 'antd';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { setUpMargin } from '../../api';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { FaPercentage, FaPlusCircle } from 'react-icons/fa';
import { IProvidersListResponse } from '../../../../../interfaces/b2bVerification';
import { ColumnsType } from 'antd/es/table';
import { IGroupedData, IMarginPayload, IService } from '../../../../../interfaces/b2bMargin';
import { getProviders } from '../../../../../hooks/b2bV3Providers';

interface IAddMarginModal {
  businessId: string;
}

interface IMarginTableData {
  carrierCode: string;
  serviceCode: string;
  provider: string;
  service: string;
  value: string;
  shippingKey: string;
  codKey: string;
  insuranceKey: string;
  shippingMargin?: string;
  codMargin?: string;
  insuranceMargin?: string;
}
const AddMarginModal: FC<IAddMarginModal> = ({ businessId }) => {
  const [marginTableForm] = Form.useForm();
  const [treeSelectForm] = Form.useForm();
  const [showAddMargin, setShowAddMargin] = useState<boolean>(false);
  const [customTreeData, setCustomTreeData] = useState<
    {
      title: string;
      value: string;
      children: {
        title: string;
        value: string;
      }[];
    }[]
  >([]);

  const [marginTableData, setMarginTableData] = useState<IMarginTableData[]>([]);
  const [marginLoading, setMarginLoading] = useState<boolean>(false);
  const [providersData, setProvidersData] = useState<IProvidersListResponse[]>([]);

  const rulesForMarginInput = [
    {
      pattern: /^(100|[1-9]?[0-9])$/,
      message: 'Value must be less than 100'
    }
  ];

  // ===============================================================================
  // ***IMPORTANT***: Everything on this UI is working based on this separator.
  //            separator used here must not be there in providersData from backend.
  const SEPARATOR = '-';
  // ===============================================================================

  const handleGetProviders = () => {
    getProviders({ setLoading: setMarginLoading, setData: setProvidersData });
  };
  useEffect(() => {
    if (showAddMargin) {
      handleGetProviders();
    }
  }, [showAddMargin]);

  useEffect(() => {
    const treeData = providersData?.map((item) => ({
      title: item?.name,
      value: item?.code,
      children: item?.services?.map((service) => ({
        title: `${item?.name}${SEPARATOR}${service?.name}`,
        value: `${item?.code}${SEPARATOR}${service?.code}`
      }))
    }));

    setCustomTreeData(treeData);
  }, [providersData]);

  const onShippingMarginChange = (key: string, value: string) => {
    const newData = marginTableData?.map((data) =>
      data?.value === key ? { ...data, shippingMargin: value } : data
    );

    setMarginTableData(newData);
  };

  const onCodMarginChange = (key: string, value: string) => {
    const newData = marginTableData?.map((data) =>
      data?.value === key ? { ...data, codMargin: value } : data
    );

    setMarginTableData(newData);
  };

  const onInsuranceMarginChange = (key: string, value: string) => {
    const newData = marginTableData?.map((data) =>
      data?.value === key ? { ...data, insuranceMargin: value } : data
    );

    setMarginTableData(newData);
  };

  const tableColumns: ColumnsType<{ [key: string]: any }> = [
    {
      title: 'Provider',
      dataIndex: 'provider',
      width: 200
    },
    {
      title: 'Service',
      dataIndex: 'service',
      width: 200
    },
    {
      title: 'Shipping Margin',
      dataIndex: 'shippingKey',
      width: 200,
      render: (name, data) => (
        <Form.Item className="mb-0" name={name} rules={rulesForMarginInput}>
          <Input
            type="number"
            placeholder="Enter percentage"
            suffix={<FaPercentage />}
            max={100}
            min={0}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onShippingMarginChange(data?.value, e.target.value)
            }
          />
        </Form.Item>
      )
    },
    {
      title: 'COD Margin',
      dataIndex: 'codKey',
      width: 200,
      render: (name, data) => (
        <Form.Item className="mb-0" name={name} rules={rulesForMarginInput}>
          <Input
            type="number"
            placeholder="Enter percentage"
            suffix={<FaPercentage />}
            max={100}
            min={0}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onCodMarginChange(data?.value, e.target.value)
            }
          />
        </Form.Item>
      )
    },
    {
      title: 'Insurance Margin',
      dataIndex: 'insuranceKey',
      width: 200,
      render: (name, data) => (
        <Form.Item className="mb-0" name={name} rules={rulesForMarginInput}>
          <Input
            type="number"
            placeholder="Enter percentage"
            suffix={<FaPercentage />}
            max={100}
            min={0}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onInsuranceMarginChange(data?.value, e.target.value)
            }
          />
        </Form.Item>
      )
    }
  ];

  const onTreeChange = (newValue: string[], secondValue: any) => {
    const tableData = secondValue?.map((item: string, index: number) => {
      const provider = item?.split(SEPARATOR)[0];
      const service = item?.split(SEPARATOR)[1];

      const carrierCode = newValue[index]?.split(SEPARATOR)[0];
      const serviceCode = newValue[index]?.split(SEPARATOR)[1];

      return {
        carrierCode,
        serviceCode,
        provider,
        service,
        value: item,
        shippingKey: `shipping-${item}`,
        codKey: `cod-${item}`,
        insuranceKey: `insurance-${item}`
      };
    });

    setMarginTableData(tableData);
  };

  const resetFields = () => {
    setShowAddMargin(false);
    setMarginTableData([]);
    marginTableForm.resetFields();
    treeSelectForm.resetFields();
  };

  const onSubmitMargins = async () => {
    const groupedData: IGroupedData[] = [];

    const updatedTableData: (IMarginTableData | null)[] = marginTableData
      ?.map((item) =>
        Number(item?.shippingMargin || 0) === 0 &&
        Number(item?.codMargin || 0) === 0 &&
        Number(item?.insuranceMargin || 0) === 0
          ? null
          : item
      )
      ?.filter((data) => data);

    updatedTableData?.forEach((item) => {
      if (
        !(
          Number(item?.shippingMargin) === 0 &&
          Number(item?.codMargin) === 0 &&
          Number(item?.insuranceMargin) === 0
        )
      ) {
        const existingGroup = groupedData.find((group) => group.carrierCode === item?.carrierCode);

        let service: IService = {
          serviceCode: item?.serviceCode
        };

        if (Number(item?.shippingMargin)) {
          service = {
            ...service,
            pricing: {
              ...service?.pricing,
              shippingMarginPercentage: {
                percentage: item?.shippingMargin
              }
            }
          };
        }

        if (Number(item?.codMargin)) {
          service = {
            ...service,
            pricing: {
              ...service?.pricing,
              codMarginPercentage: {
                percentage: item?.codMargin
              }
            }
          };
        }

        if (Number(item?.insuranceMargin)) {
          service = {
            ...service,
            pricing: {
              ...service?.pricing,
              insuranceMarginPercentage: {
                percentage: item?.insuranceMargin
              }
            }
          };
        }

        if (existingGroup) {
          // If the group exists, push the service into the services array
          existingGroup.services.push(service);
        } else {
          // If the group does not exist, create a new group
          groupedData.push({
            carrierCode: item?.carrierCode,
            services: [service]
          });
        }
      }
    });

    const payload: IMarginPayload = {
      businessId,
      carriersPricing: groupedData
    };

    await setUpMargin({
      setLoading: setMarginLoading,
      payload: payload,
      callbackFunc: resetFields
    });
  };

  return (
    <Spin spinning={marginLoading}>
      <div className="flex w-full justify-between max-w-[300px] gap-3 flex-wrap">
        <h2 className="font-bold mb-3 text-xl">Margin</h2>
        {!showAddMargin && (
          <Button
            onClick={() => setShowAddMargin(true)}
            type="primary"
            className="flex items-center"
            icon={<FaPlusCircle />}>
            Add Margin
          </Button>
        )}
      </div>
      {showAddMargin && (
        <>
          <div className="flex flex-col gap-3 mb-5">
            <Formlabel labelText="Select Provider and Services" />
            <Form form={treeSelectForm}>
              <Form.Item name="selectedProviders">
                <TreeSelect
                  className="w-full"
                  treeData={customTreeData}
                  placeholder="Select provider and services"
                  treeCheckable
                  onChange={onTreeChange}
                />
              </Form.Item>
            </Form>
          </div>

          {marginTableData?.length ? (
            <div className="flex flex-col gap-3">
              <Formlabel labelText="Margin for selected services" />

              <Form id="marginTableForm" form={marginTableForm} onFinish={onSubmitMargins}>
                <Table columns={tableColumns} dataSource={marginTableData} pagination={false} />
              </Form>

              <div className="flex justify-center my-3">
                <Button type="primary" htmlType="submit" form="marginTableForm">
                  Submit Margins
                </Button>
              </div>
            </div>
          ) : null}
        </>
      )}
    </Spin>
  );
};

export default AddMarginModal;
