import { API_URL } from '../../../../utils/config';

export const getOrdersReport = ({
  from,
  to,
  orderStatus,
  deliveryVendor,
  reportType = ''
}: {
  from: string;
  to: string;
  orderStatus?: string;
  deliveryVendor?: string;
  reportType?: string;
}) => {
  const queryParams: string[] = [];

  if (from) {
    queryParams.push(`from=${from?.replace(' ', '-')}&to=${to?.replace(' ', '-')}`);
  }
  if (deliveryVendor) {
    queryParams.push(`deliveryVendor=${deliveryVendor}`);
  }
  if (orderStatus) {
    queryParams.push(`orderStatus=${orderStatus}`);
  }

  const queryString = queryParams.join('&');

  let URL_PATH: string;

  reportType === 'businessOrders'
    ? (URL_PATH = '/order/business-orders-report')
    : reportType === 'walletReport'
    ? (URL_PATH = '/wallet/report')
    : (URL_PATH = '/order/orders-report');

  const newTab = window.open(`${API_URL}${URL_PATH}?${queryString}`, '_blank');
  if (newTab) {
    newTab.focus();
  }
};
