import React, { ReactElement } from 'react';

interface LabelProps {
  labelText: string | ReactElement;
  required?: boolean;
}
const Formlabel: React.FC<LabelProps> = ({ labelText, required }) => {
  return (
    <div className="m-0 select-none text-gray-500 font-medium flex gap-1">
      {labelText}
      {required && <span className="text-red-500">*</span>}
    </div>
  );
};

Formlabel.defaultProps = {
  required: false
};

export default Formlabel;
