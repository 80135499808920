import React, { useState } from 'react';
import ComReportDatePicker from '../Partials/ComReportDatePicker';
import { Button } from 'antd';
import OrderStatusDropdown from '../Partials/OrderStatusDropdown';
import DeliveryVendor from '../Partials/DeliveryVendor';
import { getOrdersReport } from '../api';

const OrdersReport: React.FC = () => {
  const [dateRange, setdDateRange] = useState<{
    from: string;
    to: string;
  }>({
    from: '',
    to: ''
  });

  const [error, setError] = useState<string>('');
  const [orderStatus, setOrderStatus] = useState<string>('');
  const [deliveryVendor, setDeliveryVendor] = useState<string>('');

  const handleGetOrder = () => {
    if (Object.values(dateRange)?.filter((val) => val)?.length || deliveryVendor || orderStatus) {
      setError('');
      getOrdersReport({
        from: dateRange?.from,
        to: dateRange?.to,
        orderStatus: orderStatus,
        deliveryVendor: deliveryVendor
      });
    } else {
      setError('Please enter any data.');
    }
  };

  const orderStatusOptions: {
    label: string;
    value: string;
  }[] = [
    {
      label: 'PROCESSING',
      value: 'Processing'
    },
    {
      label: 'SUCCESS',
      value: 'Order Created'
    },
    { label: 'FAILED', value: 'Order Failed' },
    {
      label: 'CANCELLED',
      value: 'Cancelled'
    }
  ];

  return (
    <div>
      <h2 className="text-xl font-normal m-0 mb-4">Orders Report</h2>
      <div className="flex flex-col items-center gap-5">
        {error ? <p className="m-0 text-red-400">{error}</p> : null}
        <div className="p-10 rounded-xl flex flex-col gap-5 primary-box-shadow">
          <ComReportDatePicker
            id={'orderReportDate'}
            currentState={dateRange}
            setState={setdDateRange}
          />

          <OrderStatusDropdown setOrderStatus={setOrderStatus} options={orderStatusOptions} />

          <DeliveryVendor setDeliveryVendor={setDeliveryVendor} />

          <Button className="primary-button" onClick={handleGetOrder}>
            Download Report
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrdersReport;
