import { Button, Form, FormInstance, Input } from 'antd';
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { GetPincodesFromExcel } from '../../../utils/getPincodesFromExcel';
import Formlabel from '../../../../../CommonComponents/FormLabel';

interface IFileUploadButton {
  pincodeForm: FormInstance;
  fileName: string;
  setFileName: Dispatch<SetStateAction<string>>;
  pincodeData: number[];
  setPincodeData: Dispatch<SetStateAction<number[]>>;
}

const FileUploadButton: FC<IFileUploadButton> = ({
  pincodeForm,
  fileName,
  setFileName,
  pincodeData,
  setPincodeData
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    if (event.target.files?.length) {
      setFileName(event.target.files[0]?.name);
      const result = await GetPincodesFromExcel({ dataFile: event?.target?.files[0] });
      setPincodeData(result);
    } else {
      setPincodeData([]);
    }
    setLoading(false);
  };

  const onClickRemove = () => {
    setPincodeData([]);
    setFileName('No File Chosen');
    pincodeForm.resetFields();
  };
  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: 'Please upload a File!'
        }
      ]}
      label={<Formlabel labelText="Upload CSV/Excel (XLXS) File" />}
      name="pincodes">
      <div className="flex flex-col md:flex-row items-center gap-2">
        <Input
          disabled={!!pincodeData?.length || loading}
          className="w-[95px] pl-1"
          type="file"
          id="pincodeFile"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={handleFileChange}
          suffix={false}
        />
        <div title={fileName}>
          {fileName?.length > 20 ? fileName?.slice(0, 20) + '...' : fileName}
          {!!pincodeData?.length && (
            <Button
              onClick={onClickRemove}
              title={`Remove file`}
              className="border-none"
              icon={<MdOutlineClose />}
            />
          )}
        </div>
      </div>
    </Form.Item>
  );
};

export default FileUploadButton;
