import { AlertTwoTone } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import React, { ReactNode } from 'react';

interface IConfirmModal {
  isModalOpen: boolean;
  onClickOk?: () => void;
  modalBody?: ReactNode | string;
  okText?: string;
  title?: string | ReactNode;
  onCancel?: () => void;
  isLoading?: boolean;
}
const ConfirmModal: React.FC<IConfirmModal> = ({
  isModalOpen,
  onClickOk,
  modalBody,
  okText = 'Confirm',
  title = (
    <div className="flex items-center gap-2">
      <AlertTwoTone />
      <span className="mt-1">Confirm</span>
    </div>
  ),
  onCancel,
  isLoading = false
}) => {
  return (
    <Modal
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      open={isModalOpen}
      title={title}
      onOk={onClickOk}
      onCancel={onCancel}
      okText={okText}>
      <Spin spinning={isLoading}>{modalBody}</Spin>
    </Modal>
  );
};

export default ConfirmModal;
