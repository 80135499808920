import React, { useState } from 'react';
import { Steps } from 'antd';
import CompanyDetails from './CompanyDetails';
import BusinessDetails from './BusinessDetails';
import KYCDetails from './KYCDetails';
import { SuccessMessage } from '../../../utils/ResponseMessages';

const B2BOnboarding = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [sameBillingAddress, setSameBillingAddress] = useState<boolean>(true);
  const [businessID, setBusinessID] = useState<string>('');
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const onSubmitAll = () => {
    SuccessMessage('Registered Successfully');
    setIsSubmitLoading(true);
    setTimeout(() => {
      setBusinessID('');
      setCurrentStep(0);
      setIsSubmitLoading(false);
    }, 1000);
  };

  const onboardingSteps = [
    {
      title: <p className="select-none">Company Details</p>,
      content: (
        <CompanyDetails
          sameBillingAddress={sameBillingAddress}
          setSameBillingAddress={setSameBillingAddress}
          nextStep={nextStep}
          setBusinessID={setBusinessID}
        />
      )
    },
    {
      title: <p className="select-none">Business Details</p>,
      content: <BusinessDetails prevStep={prevStep} nextStep={nextStep} businessID={businessID} />
    },
    {
      title: <p className="select-none">KYC Details</p>,
      content: (
        <KYCDetails
          businessID={businessID}
          onSubmitAll={onSubmitAll}
          isSubmitLoading={isSubmitLoading}
        />
      )
    }
  ];

  const items = onboardingSteps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <h2 className="text-xl font-normal m-0">B2B Onboarding</h2>

      <div className="flex justify-center">
        <div className="mt-5 py-5 px-7 primary-box-shadow rounded-l w-full xl:w-[70%]">
          <Steps current={currentStep} items={items} responsive />

          <div className="mt-8">{onboardingSteps[currentStep].content}</div>
        </div>
      </div>
    </>
  );
};

export default B2BOnboarding;
