import React, { FC, useEffect, useState } from 'react';
import AadhaarDoc from './AadhaarDoc';
import { Button, Divider, Spin } from 'antd';
import PanCardDetails from './PanCardDetails';
import GSTDetails from './GSTDetails';
import AgreementDetails from './AgreementDetails';
import { getSignedUrls, submitDocument } from '../api';
import { IDocumentUpload, ISignedUrlsResponse } from '../../../../interfaces/b2bOnboarding';
import { ReloadOutlined } from '@ant-design/icons';

interface IKYCDetails {
  businessID: string;
  onSubmitAll: () => void;
  isSubmitLoading: boolean;
}

const KYCDetails: FC<IKYCDetails> = ({ businessID, onSubmitAll, isSubmitLoading }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRetry, setIsRetry] = useState<boolean>(false);
  const [urlData, setUrlData] = useState<ISignedUrlsResponse>({
    aadharUrl: '',
    agreementUrl: '',
    panUrl: '',
    gstUrl: ''
  });
  const [aadhaarNumSuccess, setAadhaarNumSuccess] = useState(false);
  const [panNumSuccess, setPanNumSuccess] = useState(false);
  const [gstNumSuccess, setGstNumSuccess] = useState(false);

  const [aadhaarFileSuccess, setAadhaarFileSuccess] = useState(false);
  const [panFileSuccess, setPanFileSuccess] = useState(false);
  const [gstFileSuccess, setGstFileSuccess] = useState(false);
  const [agreementFileSuccess, setAgreementFileSuccess] = useState(false);

  const handleGetSignedUrl = async () => {
    await getSignedUrls({
      setLoading: setIsLoading,
      businessId: businessID,
      setIsRetry,
      setUrlData
    });
  };

  useEffect(() => {
    handleGetSignedUrl();
  }, []);

  const handleOnSubmit = async ({ documentId, documentType, setSuccess }: IDocumentUpload) => {
    await submitDocument({
      setLoading: setIsLoading,
      businessId: businessID,
      documentId,
      documentType,
      setSuccess
    });
  };

  return (
    <>
      {isRetry && (
        <div className="flex justify-between">
          <p className="text-red-500">
            Oops, Something went wrong. Please click on{' '}
            <span className="font-medium mr-2">Retry</span>
          </p>
          <Button
            type="primary"
            className="flex items-center"
            onClick={handleGetSignedUrl}
            icon={<ReloadOutlined className={`${isLoading && 'animate-spin'}`} />}>
            Retry
          </Button>
        </div>
      )}

      {!isRetry && (
        <Spin spinning={isLoading}>
          <AadhaarDoc
            documentSuccess={aadhaarNumSuccess}
            setDocumentSuccess={setAadhaarNumSuccess}
            isFormDisabled={isRetry || isLoading}
            fileUrl={urlData?.aadharUrl}
            handleOnSubmit={handleOnSubmit}
            fileUploadSuccess={aadhaarFileSuccess}
            setFileUploadSuccess={setAadhaarFileSuccess}
          />

          <Divider />

          <PanCardDetails
            isFormDisabled={isRetry || isLoading}
            fileUrl={urlData?.panUrl}
            handleOnSubmit={handleOnSubmit}
            documentSuccess={panNumSuccess}
            setDocumentSuccess={setPanNumSuccess}
            fileUploadSuccess={panFileSuccess}
            setFileUploadSuccess={setPanFileSuccess}
          />

          <Divider />

          <GSTDetails
            isFormDisabled={isRetry || isLoading}
            fileUrl={urlData?.gstUrl}
            handleOnSubmit={handleOnSubmit}
            documentSuccess={gstNumSuccess}
            setDocumentSuccess={setGstNumSuccess}
            fileUploadSuccess={gstFileSuccess}
            setFileUploadSuccess={setGstFileSuccess}
          />

          <Divider />

          <AgreementDetails
            fileUrl={urlData?.agreementUrl}
            fileUploadSuccess={agreementFileSuccess}
            setFileUploadSuccess={setAgreementFileSuccess}
            isFormDisabled={isRetry || isLoading}
          />

          {panFileSuccess &&
            panNumSuccess &&
            gstFileSuccess &&
            gstNumSuccess &&
            agreementFileSuccess && (
              <div className="pt-16 pb-5 flex justify-center">
                <Button
                  loading={isLoading || isSubmitLoading}
                  onClick={onSubmitAll}
                  className="primary-button">
                  Save & Submit
                </Button>
              </div>
            )}
        </Spin>
      )}
    </>
  );
};

export default KYCDetails;
