import React, { CSSProperties, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCompanyDetails, verifyDetails } from '../api';
import { Button, Collapse, CollapseProps, Form } from 'antd';
import { ICompanyDetailsData, IVerifyDetailsPayload } from '../../../../interfaces/b2bVerification';
import BasicInformation from './BasicInformation';
import { CaretRightOutlined } from '@ant-design/icons';
import BusinessLegalDetails from './BusinessLegalDetails';
import BusinessPocDetails from './BusinessPocDetails';
import AddressDetails from './AddressDetails';
import ProductDescription from './ProductDescription';
import LoadingPlaceHolder from './LoadingPlaceHolder';
import Documents from './Documents';
import VerificationModal from './VerificationModal';
import { FaBackward } from 'react-icons/fa';
import AddMarginModal from './AddMarginModal';

const CompanyDetails = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [verifyDetailsForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false);
  const [detailsData, setDetailsData] = useState<ICompanyDetailsData | any>();
  const [isActionAccept, setIsActionAccept] = useState<boolean>(false);

  const handleCompanyDetails = () => {
    if (param?.id) {
      getCompanyDetails({
        setLoading: setIsLoading,
        businessId: param.id,
        setData: setDetailsData
      });
    } else {
      navigate('b2b-company-list');
    }
  };
  useEffect(() => {
    handleCompanyDetails();
  }, []);

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
    {
      key: '1',
      label: (
        <p className="font-medium">{`Documents ( ${
          detailsData?.urls ? Object.values(detailsData?.urls)?.length : 0
        } )`}</p>
      ),
      children: <Documents detailsData={detailsData} />,
      style: panelStyle
    },
    {
      key: '2',
      label: <p className="font-medium">Business Legal Details</p>,
      children: <BusinessLegalDetails detailsData={detailsData} />,
      style: panelStyle
    },
    {
      key: '3',
      label: (
        <p className="font-medium">{`Business POC Details ( ${
          detailsData?.businessPocDetails?.length || 0
        } )`}</p>
      ),
      children: <BusinessPocDetails detailsData={detailsData} />,
      style: panelStyle
    },
    {
      key: '4',
      label: (
        <p className="font-medium">{`Address Details ( ${detailsData?.address?.length || 0} )`}</p>
      ),
      children: <AddressDetails detailsData={detailsData} />,
      style: panelStyle
    },
    {
      key: '5',
      label: (
        <p className="font-medium">{`Product Description ( ${
          detailsData?.productDesc?.length || 0
        } )`}</p>
      ),
      children: <ProductDescription detailsData={detailsData} />,
      style: panelStyle
    }
  ];

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: '#e0e3e7',
    borderRadius: '10px',
    border: 'none'
  };

  const openModal = () => {
    setShowVerifyModal(true);
  };

  const onCloseModal = () => {
    if (!confirmLoading) {
      setShowVerifyModal(false);
      verifyDetailsForm.resetFields();
    }
  };

  const onClickAccept = () => {
    setIsActionAccept(true);
    openModal();
  };

  const onClickReject = () => {
    setIsActionAccept(false);
    openModal();
  };

  const clearData = () => {
    onCloseModal();
    handleCompanyDetails();
  };

  const onConfirm = async (values: { description: string }) => {
    const payload: IVerifyDetailsPayload = {
      businessId: detailsData?.id,
      verification: isActionAccept ? 'ACCEPTED' : 'REJECTED',
      description: values?.description?.trim()
    };
    await verifyDetails({ setLoading: setConfirmLoading, payload, callbackFunc: clearData });
  };
  return (
    <>
      <Button className="m-0 p-0 flex items-center gap-2 " type="link" onClick={() => navigate(-1)}>
        <FaBackward />
        <span className="hover:underline pl-1">Back</span>
      </Button>

      <h2 className="text-xl font-normal m-0">Company Details</h2>
      {isLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <>
          <div className="my-5 primary-box-shadow p-4 rounded min-h-[150px] ">
            <BasicInformation detailsData={detailsData} />

            {!!(detailsData?.kycStatus === 'PENDING') && (
              <div className="flex flex-wrap items-center justify-end gap-3 my-3">
                <Button
                  onClick={onClickAccept}
                  className=" bg-yellow-500 text-black hover:!text-black hover:!bg-yellow-550 hover:!border-yellow-600">
                  Accept
                </Button>
                <Button
                  onClick={onClickReject}
                  className="bg-red-500 text-white hover:!text-white hover:!bg-red-550 hover:!border-red-500">
                  Reject
                </Button>
              </div>
            )}
          </div>
          {detailsData && (
            <Collapse
              accordion
              className="border-none bg-transparent"
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              items={getItems(panelStyle)}
            />
          )}

          <AddMarginModal businessId={detailsData?.id} />
        </>
      )}

      <VerificationModal
        confirmLoading={confirmLoading}
        showVerifyModal={showVerifyModal}
        closeModal={onCloseModal}
        isAccept={isActionAccept}
        onConfirm={onConfirm}
        businessId={detailsData?.id}
        verifyDetailsForm={verifyDetailsForm}
      />
    </>
  );
};

export default CompanyDetails;
