import React, { useEffect, useState } from 'react';
import { Button, Form, Pagination, PaginationProps, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getBulkOrdersList } from '../../api';
import { useNavigate } from 'react-router-dom';
import GetOrderByIdFilter from '../../../../CommonComponents/TableFilterComponents/GetOrderById';
import DateRangeFilter from '../../../../CommonComponents/TableFilterComponents/DateRangeFilter';
import { downloadBulkOrder } from '../../../Reports/BulkOrdersReport/Partials/DownloadBulkOrder';

const BulkOrdersList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
  const [bulkReportId, setBulkReportId] = useState<string>('');

  const [bulkOrdersList, setBulkOrdersList] = useState<{
    // eslint-disable-next-line
    ordersList: { [key: string]: any }[];
    totalPage: number;
  }>({
    ordersList: [],
    totalPage: 0
  });
  const [orderId, setOrderId] = useState('');

  // future use : filter data by bulk ID
  // const [bulkOrderId, setBulkOrderId] = useState('');

  const [daterange, setDaterange] = useState({ from: '', to: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);

  const navigate = useNavigate();

  // future use : filter data by bulk ID
  // const [bulkOrderByOrderId] = Form.useForm();

  const [bulkOrderByBulkId] = Form.useForm();

  const handleGetbulkOrders = () => {
    getBulkOrdersList({
      setLoading: setIsLoading,
      setData: setBulkOrdersList,
      orderId: orderId,
      from: daterange?.from,
      to: daterange?.to,
      pageNumber: currentPage,
      pageLimit: pageLimit
      // future use : filter data by bulk ID
      // bulkId: bulkOrderId,
    });
  };

  useEffect(() => {
    handleGetbulkOrders();
  }, [
    orderId,
    daterange,
    currentPage,
    pageLimit
    // future use : filter data by bulk ID
    // bulkOrderId,
  ]);

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  const onClickDownload = async (bulkId: string) => {
    setBulkReportId(bulkId);
    downloadBulkOrder({ setIsReportLoading: setIsReportLoading, bulkId: bulkId });
  };

  // eslint-disable-next-line
  const columns: ColumnsType<any> = [
    {
      title: 'Bulk Order Id',
      dataIndex: 'customBulkOrderId',
      fixed: 'left',
      render: (orderId) => <div>{orderId ? orderId : 'NA'}</div>

      // future use : filter data by bulk ID
      // ...GetOrderByIdFilter({
      //   orderId: bulkOrderId,
      //   setOrderId: setBulkOrderId,
      //   formInstance: bulkOrderByOrderId,
      //   title: 'Bulk Order ID',
      //   placeHolder: 'Bulk Order ID',
      //   formId: 'bulkOrderIdForm'
      // })
    },
    {
      dataIndex: 'orderIds',
      key: 'orderIds',
      render: (orderIds) => <div>{orderIds?.length}</div>,
      ...GetOrderByIdFilter({
        orderId: orderId,
        setOrderId: setOrderId,
        formInstance: bulkOrderByBulkId,
        title: 'Total Orders',
        formId: 'Order ID'
      })
    },
    {
      dataIndex: 'createdAt',
      key: 'createdAt',
      ...DateRangeFilter({ setDaterange: setDaterange, daterange: daterange })
    },
    {
      title: 'Success Orders',
      dataIndex: 'successfulOrders',
      key: 'successfulOrders',
      render: (successOrders) => <div className="text-green-400">{successOrders?.length}</div>
    },
    {
      title: 'Failed Orders',
      dataIndex: 'failedOrders',
      key: 'failedOrders',
      render: (failedOrders) => <div className="text-red-400">{failedOrders?.length}</div>
    },
    {
      title: 'Report',
      dataIndex: 'customBulkOrderId',
      render: (bulkId) => (
        <Button
          disabled={isReportLoading && bulkId != bulkReportId}
          loading={isReportLoading && bulkId === bulkReportId}
          className="primary-button"
          onClick={() => onClickDownload(bulkId)}>
          Download
        </Button>
      )
    }
  ];

  // eslint-disable-next-line
  const orderColumns: ColumnsType<any> = [
    {
      title: 'Order id',
      dataIndex: 'id',
      width: '70%'
    },
    {
      title: 'Action',
      width: '30%',
      dataIndex: 'id',
      render: (id) => (
        <Button onClick={() => navigate(`/order-details/${id}`)} className="primary-button">
          View Details
        </Button>
      )
    }
  ];
  return (
    <div>
      <h2 className="text-xl font-normal m-0 mb-3">Bulk Orders List</h2>

      <Table
        columns={columns}
        loading={isLoading}
        rowKey={'_id'}
        scroll={{ x: 1616 }}
        pagination={false}
        dataSource={bulkOrdersList?.ordersList}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div>
                <Tabs
                  defaultActiveKey="1"
                  animated
                  centered
                  items={[
                    {
                      label: (
                        <h1 className="text-lg">
                          Success Orders ( {record?.successfulOrders?.length} )
                        </h1>
                      ),
                      key: '1',
                      children: (
                        <div className="xl:flex justify-center">
                          <Table
                            className="primary-box-shadow rounded-xl xl:w-1/2"
                            columns={orderColumns}
                            dataSource={record?.successfulOrders?.map((id: string) => ({ id: id }))}
                          />
                        </div>
                      )
                    },
                    {
                      label: (
                        <h1 className="text-lg">
                          Failed Orders ( {record?.failedOrders?.length} )
                        </h1>
                      ),
                      key: '2',
                      children: (
                        <div className="xl:flex justify-center">
                          <Table
                            className="primary-box-shadow rounded-xl xl:w-1/2"
                            columns={orderColumns}
                            dataSource={record?.failedOrders?.map((id: string) => ({ id: id }))}
                          />
                        </div>
                      )
                    }
                  ]}
                />
              </div>
            );
          }
        }}
      />

      <div className="mt-4 flex justify-end">
        <Pagination
          responsive={true}
          showQuickJumper
          current={currentPage}
          pageSize={pageLimit}
          pageSizeOptions={[20, 30, 50]}
          total={bulkOrdersList?.totalPage ? bulkOrdersList?.totalPage * pageLimit : 0}
          onChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default BulkOrdersList;
