import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { documentVerification } from '../../api';

interface Props {
  userId: string;
  docType: string;
  docLabel: string;
  docMsg: string;
  docPlaceholder: string;
}

const DocVerification: React.FC<Props> = ({
  userId,
  docType,
  docLabel,
  docMsg,
  docPlaceholder
}) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedFileBack, setSelectedFileBack] = useState<any>();
  const [uploadingFront, setUploadingFront] = useState(false);
  const [uploadingBack, setUploadingBack] = useState(false);

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>, pageSide: string) => {
    const file = event.target.files && event.target.files[0];
    pageSide === 'front' ? setSelectedFile(file) : setSelectedFileBack(file);
  };

  const handleUploadClick = async (pageSide: string) => {
    try {
      await form.validateFields(); // Validate form fields

      const formDataPayload = new FormData();
      const selectedFileValue = pageSide === 'front' ? selectedFile : selectedFileBack;
      formDataPayload.append('file', selectedFileValue);
      formDataPayload.append('userId', userId);
      formDataPayload.append('docType', docType);
      formDataPayload.append('docNumber', documentNumber);
      formDataPayload.append('registeredName', name);
      formDataPayload.append('address', address);
      formDataPayload.append('pageSide', pageSide);

      if (pageSide === 'front') {
        setUploadingFront(true);
      } else {
        setUploadingBack(true);
      }

      const response = await documentVerification(formDataPayload); // Make API call

      if (pageSide === 'front') {
        setUploadingFront(false);
      } else {
        setUploadingBack(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form form={form}>
      <Form.Item
        name="registeredName"
        label="Register Name"
        rules={[{ required: true, message: 'Please enter your register name!' }]}
        className="w-full">
        <Input placeholder="Enter register name" onChange={(e) => setName(e.target.value)} />
      </Form.Item>

      <Form.Item
        label={docLabel}
        rules={[{ required: true, message: `${docMsg}` }]}
        className="w-full"
        name="docNumber">
        <Input placeholder={docPlaceholder} onChange={(e) => setDocumentNumber(e.target.value)} />
      </Form.Item>

      <Form.Item
        label="Enter Address"
        rules={[
          {
            required: true,
            message: 'Please enter the address!'
          }
        ]}
        name="address">
        <TextArea
          rows={4}
          placeholder="Enter your address"
          onChange={(e) => setAddress(e.target.value)}
        />
      </Form.Item>

      <div className="flex gap-2 items-center">
        <Form.Item
          label="Upload Document Front"
          valuePropName="file"
          name="file"
          rules={[{ required: true, message: 'Please upload front side of document!' }]}>
          <input
            type="file"
            id="imageUpload"
            onChange={(e) => onFileChange(e, 'front')}
            className="hidden"
            hidden
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => handleUploadClick('front')}
            loading={uploadingFront}
            disabled={uploadingFront}>
            {uploadingFront ? 'Uploading...' : 'Upload Front Side'}
          </Button>
        </Form.Item>
      </div>

      {docType !== 'pan' && (
        <div className="flex gap-2 items-center">
          <Form.Item
            label="Upload Document Back"
            valuePropName="file"
            name="file"
            rules={[{ required: true, message: 'Please upload back side of document!' }]}>
            <input
              type="file"
              id="imageUpload"
              onChange={(e) => onFileChange(e, 'back')}
              className="hidden"
              hidden
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              onClick={() => handleUploadClick('back')}
              loading={uploadingBack}
              disabled={uploadingBack}>
              {uploadingBack ? 'Uploading...' : 'Upload Back Side'}
            </Button>
          </Form.Item>
        </div>
      )}
    </Form>
  );
};

export default DocVerification;
