import { Dispatch, SetStateAction } from 'react';
import { ErrorMessage, showMessage } from '../../../utils/ResponseMessages';
import { httpClient } from '../../../utils/apiRequest';
import { IEmailVerifiedData } from '../../../interfaces/bulkOrders';

export const getHsnCategories = () => {
  return httpClient.get(`/hsn`);
};

export const createBulkOrder = async (payload: { [key: string]: any }) => {
  try {
    const response = await httpClient?.post('/bulk-order/create', payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const generateBulkOrderId = async ({
  setLoading
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
}): Promise<any> => {
  setLoading(true);
  try {
    const response = await httpClient?.get('/bulk-order/id');
    setLoading(false);
    return response?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    setLoading(false);
  }
};

export const generateInvoice = async ({
  bulkId,
  setLoading,
  setData
}: {
  bulkId: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<
    SetStateAction<{
      invoice?: string;
      shipmentLabel?: string;
      invoiceUrl?: string;
      shipmentLabelUrl?: string;
    }>
  >;
}): Promise<any> => {
  setLoading(true);
  try {
    const response = await httpClient?.get(`/bulk-order/invoice?id=${bulkId}`);
    setData({
      invoice: response?.data?.data?.invoice,
      shipmentLabel: response?.data?.data?.shipmentLabel,
      invoiceUrl: response?.data?.data?.invoiceUrl,
      shipmentLabelUrl: response?.data?.data?.shipmentLabelUrl
    });
    setLoading(false);
  } catch (error: any) {
    setLoading(false);
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};

export const getBulkOrdersList = async ({
  pageNumber = 1,
  pageLimit = 20,
  setLoading,
  setData,
  orderId,
  bulkId,
  from,
  to
}: {
  pageNumber?: number;
  pageLimit?: number;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<{ ordersList: { [key: string]: any }[]; totalPage: number }>>;
  orderId?: string;
  bulkId?: string;
  from?: string;
  to?: string;
}) => {
  setLoading(true);

  const skip = orderId ? 0 : pageNumber - 1; // subtract by 1, As per api requirements, page number start from 0.
  const orderid = orderId ? `&orderId=${orderId}` : '';
  const bulkid = bulkId ? `&bulkOrderId=${bulkId}` : '';
  const dateRange = from ? `&from=${from}&to=${to}` : '';

  try {
    const response = await httpClient?.get(
      `/bulk-order/list?limit=${pageLimit}&skip=${skip}${orderid}${bulkid}${dateRange}`
    );
    setData({
      ordersList: response?.data?.data?.ordersList,
      totalPage: response?.data?.data?.totalPage
    });
    setLoading(false);
  } catch (error: any) {
    setLoading(false);
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};

export const downloadBulkOrderReport = async ({
  setLoading,
  bulkOrderId
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  bulkOrderId: string;
}) => {
  setLoading(true);

  const bulkId = bulkOrderId ? `?bulkOrderId=${bulkOrderId}` : '';
  try {
    const response = await httpClient?.get(`/bulk-order/order-report${bulkId}`);
    setLoading(false);
    return response?.data?.data[0]?.report;
  } catch (error: any) {
    setLoading(false);
    showMessage(error?.response?.data?.message, error?.response?.status);
    return [];
  }
};

export const verifyUserEmail = async ({
  setLoading,
  setVerified,
  setUserData,
  email
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setVerified: Dispatch<SetStateAction<boolean>>;
  setUserData: Dispatch<SetStateAction<IEmailVerifiedData | undefined>>;
  email: string;
}) => {
  setLoading(true);

  try {
    const response = await httpClient?.post('/bulk-order/fetch-user', { email });
    if (response?.data?.status >= 200 && response?.data?.status <= 300) {
      setVerified(true);
      setUserData(response?.data?.data);
    } else {
      setVerified(false);
      setUserData(undefined);
      ErrorMessage(response?.data?.message);
    }
  } catch (error: any) {
    setVerified(false);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};
