import { Button, Form, Input, Select } from 'antd';
import React, { FC, useState } from 'react';
import Formlabel from '../../../CommonComponents/FormLabel';
import {
  CREDIT_BNPL,
  MARKET_PLACE_OPTIONS,
  SALES_MODE_OPTIONS,
  formValidations
} from './constants';
import AccountDetails from './AccountDetails';
import BusinessPresence from './BusinessPresence';
import ShippingMode from './ShippingMode';
import {
  IBusinessDetailsForm,
  IBusinessDetailsPayload
} from '../../../../interfaces/b2bOnboarding';
import { businessDetails } from '../api';

interface IBusinessDetails {
  prevStep: () => void;
  nextStep: () => void;
  businessID: string;
}

const BusinessDetails: FC<IBusinessDetails> = ({ nextStep, businessID }) => {
  const [businessDetailsForm] = Form.useForm<IBusinessDetailsForm>();
  const [businessPresenceCount, setBusinessPresenceCount] = useState<number>(0);
  const [shippingModeCount, setShippingModeCount] = useState<number>(0);
  const [selectedShippingModes, setSelectedShippingModes] = useState<string[]>([]);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleValuesChange = (__: Partial<any>, allValues: any) => {
    setBusinessPresenceCount(allValues?.businessPresence?.length);
    setShippingModeCount(allValues?.shippingMode?.length);
    setSelectedShippingModes(allValues?.shippingMode?.map((item: any) => item?.serviceType));
  };

  const onSubmit = async (values: IBusinessDetailsForm) => {
    let payload: IBusinessDetailsPayload = {
      businessId: businessID,
      productType: values?.productType,
      averageWeight: `${values?.averageWeight}kg`,
      monthlyDispatch: `${values?.minMonthlyDispatch}-${values?.maxMonthlyDispatch}`,
      averageBillingValue: values?.averageBillingValue,
      salesMode: values?.salesMode,
      businessPresence: values?.businessPresence,
      shippingMode: values?.shippingMode,
      marketPlace: values?.marketPlace,
      accountDetails: {
        accountType: values?.accountType
      }
    };

    if (values?.accountType === CREDIT_BNPL) {
      payload = {
        ...payload,
        accountDetails: {
          ...payload.accountDetails,
          creditLimit: selectedOption === 'custom' ? values?.bnplCustomLimit : values?.creditLimit
        }
      };
    }

    if (values?.valueAddedServices?.length) {
      payload = {
        ...payload,
        accountDetails: {
          ...payload?.accountDetails,
          valueAddedServices: values?.valueAddedServices?.map((item) => ({ service: item }))
        }
      };
    }

    await businessDetails({ setLoading: setIsFormLoading, payload, callBackFunc: nextStep });
  };

  return (
    <div>
      <Form
        onValuesChange={handleValuesChange}
        layout="vertical"
        scrollToFirstError
        disabled={isFormLoading}
        id="businessDetailsForm"
        form={businessDetailsForm}
        onFinish={onSubmit}>
        <div className="flex flex-col md:flex-row md:gap-5 gap-0">
          <div className="w-full md:w-1/2">
            <Formlabel labelText="Product Type" required />
            <Form.Item
              className="mt-2"
              name="productType"
              rules={[{ required: true, message: formValidations?.productType?.required }]}>
              <Input placeholder="Enter product type" />
            </Form.Item>
          </div>

          <div className="w-full md:w-1/2">
            <Formlabel labelText="Sales Mode" required />
            <Form.Item
              className="mt-2"
              name="salesMode"
              rules={[{ required: true, message: formValidations?.salesMode?.required }]}>
              <Select placeholder="Select type" allowClear options={SALES_MODE_OPTIONS} />
            </Form.Item>
          </div>
        </div>

        <div className="w-full">
          <Formlabel labelText="Market Places" required />
          <Form.Item
            className="mt-2"
            name="marketPlace"
            rules={[{ required: true, message: formValidations?.marketPlace?.required }]}>
            <Select
              allowClear
              placeholder="Select market places"
              options={MARKET_PLACE_OPTIONS}
              mode="multiple"
            />
          </Form.Item>
        </div>

        <div className="w-full">
          <Formlabel labelText="Average Weight ( kg )" required />
          <Form.Item
            className="mt-2"
            name="averageWeight"
            rules={[{ required: true, message: formValidations?.averageWeight?.required }]}>
            <Input placeholder="Enter weight in kg" />
          </Form.Item>
        </div>

        <div>
          <Formlabel labelText={<h2 className="font-bold">Monthly Dispatch Counts</h2>} required />
          <div className="mt-3 flex gap-5">
            <div className="w-1/2">
              <Formlabel labelText="Min Count" required />
              <Form.Item
                className="mt-2"
                name="minMonthlyDispatch"
                dependencies={['maxMonthlyDispatch']}
                rules={[
                  { required: true, message: formValidations?.minMonthlyDispatch?.required },
                  {
                    pattern: formValidations?.minMonthlyDispatch?.patternRegex,
                    message: formValidations?.minMonthlyDispatch?.patternMessage
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        Number(value) &&
                        Number(value) >= Number(getFieldValue('maxMonthlyDispatch'))
                      )
                        return Promise.reject(
                          new Error(
                            `Min count must be less than ${getFieldValue('maxMonthlyDispatch')}`
                          )
                        );

                      return Promise.resolve();
                    }
                  })
                ]}
                hasFeedback>
                <Input placeholder="Enter min count" />
              </Form.Item>
            </div>

            <div className="w-1/2">
              <Formlabel labelText="Max Count" required />
              <Form.Item
                className="mt-2"
                name="maxMonthlyDispatch"
                dependencies={['minMonthlyDispatch']}
                rules={[
                  { required: true, message: formValidations?.maxMonthlyDispatch?.required },
                  {
                    pattern: formValidations?.maxMonthlyDispatch?.patternRegex,
                    message: formValidations?.maxMonthlyDispatch?.patternMessage
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        Number(value) &&
                        Number(getFieldValue('minMonthlyDispatch')) &&
                        Number(value) <= Number(getFieldValue('minMonthlyDispatch'))
                      )
                        return Promise.reject(
                          new Error(
                            `Max count must be grater than ${getFieldValue('minMonthlyDispatch')}`
                          )
                        );

                      return Promise.resolve();
                    }
                  })
                ]}
                hasFeedback>
                <Input placeholder="Enter max count" />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="w-full">
          <Formlabel labelText="Average Billing Value" required />
          <Form.Item
            className="mt-2"
            name="averageBillingValue"
            rules={[{ required: true, message: formValidations?.averageBillingValue.required }]}>
            <Input placeholder="Enter value" />
          </Form.Item>
        </div>

        <BusinessPresence businessPresenceCount={businessPresenceCount} />

        <ShippingMode
          selectedShippingModes={selectedShippingModes}
          shippingModeCount={shippingModeCount}
        />

        <AccountDetails
          businessDetailsForm={businessDetailsForm}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </Form>

      <div className="mt-5 mb-3 flex  justify-center">
        <Button
          loading={isFormLoading}
          htmlType="submit"
          form="businessDetailsForm"
          className="primary-button">
          Submit & Proceed
        </Button>
      </div>
    </div>
  );
};

export default BusinessDetails;
