import React from 'react';
import { Divider, Form, Input } from 'antd';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { formFieldValidations } from '../constants';

const PersonOfContact = () => {
  return (
    <>
      <Divider />

      <h2 className="text-xl font-bold mb-5 select-none">Person of contact :</h2>

      <div className="flex flex-col md:flex-row md:gap-5 gap-0">
        <div className="w-full md:w-1/2">
          <Formlabel labelText="First Name" required />
          <Form.Item
            className="mt-2"
            name="poc_firstName"
            rules={[
              { required: true, message: formFieldValidations?.pocFirstName?.required },
              {
                max: formFieldValidations?.pocFirstName?.maxCharactersLength,
                message: formFieldValidations?.pocFirstName?.maxCharactersMessage
              }
            ]}>
            <Input placeholder="Enter first name" />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/2">
          <Formlabel labelText="Last Name" required />
          <Form.Item
            className="mt-2"
            name="poc_lastName"
            rules={[
              { required: true, message: formFieldValidations?.pocLastName?.required },
              {
                max: formFieldValidations?.pocLastName?.maxCharactersLength,
                message: formFieldValidations?.pocLastName?.maxCharactersMessage
              }
            ]}>
            <Input placeholder="Enter last name" />
          </Form.Item>
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:gap-5 gap-0">
        <div className="w-full md:w-1/2">
          <Formlabel labelText="Contact Number" required />
          <Form.Item
            className="mt-2"
            rules={[
              { required: true, message: formFieldValidations?.pocMobileNum?.required },
              {
                pattern: formFieldValidations?.pocMobileNum?.patternValueRegex,
                message: formFieldValidations?.pocMobileNum?.patternMessage
              }
            ]}
            name="poc_contactNo">
            <Input
              type="number"
              placeholder="Enter Number"
              onKeyDown={(e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()}
            />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/2">
          <Formlabel labelText="Email" />
          <Form.Item
            className="mt-2"
            rules={[
              {
                pattern: formFieldValidations?.pocEmail?.patternValueRegex,
                message: formFieldValidations?.pocEmail?.patternMessage
              }
            ]}
            name="poc_email">
            <Input placeholder="Enter email" />
          </Form.Item>
        </div>
      </div>

      <div className="w-full">
        <Formlabel labelText="Designation" required />
        <Form.Item
          className="mt-2"
          rules={[
            { required: true, message: formFieldValidations?.pocDesignation?.required },
            {
              max: formFieldValidations?.pocDesignation?.maxCharactersLength,
              message: formFieldValidations?.pocDesignation?.maxCharactersMessage
            }
          ]}
          name="poc_designation">
          <Input placeholder="Enter designation" />
        </Form.Item>
      </div>
    </>
  );
};

export default PersonOfContact;
