import { Button, Tabs, TabsProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import HandleCODService from './HandleCODService';
import { BiAddToQueue } from 'react-icons/bi';
import HandlePortalServiceability from './HandlePortalServiceability';
import AddServiceProvider from './AddServiceProvider';
import { getServiceProvidersList } from './api';
import { IServiceProvidersListProps } from '../../../interfaces/serviceProvider';

const ManageServiceProviders = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isAddProviderLoading, setIsAddProviderLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('Business');
  const [addProviderModal, setAddProviderModal] = useState<boolean>(false);

  const [serviceList, setServiceList] = useState<IServiceProvidersListProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (containerRef.current) {
      const tabs = containerRef.current.getElementsByClassName('ant-tabs-tab');
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i] as HTMLDivElement;
        tab.style.width = '100%';
        tab.style.textAlign = 'center';
      }
    }
  }, []);

  const handleGetServiceabilityList = async () => {
    await getServiceProvidersList({
      setLoading: setIsLoading,
      setData: setServiceList,
      portalName: activeTab
    });
  };

  useEffect(() => {
    setServiceList([]);
    handleGetServiceabilityList();
  }, [activeTab]);

  const onTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
  };
  const items: TabsProps['items'] = [
    {
      key: 'Business',
      label: 'Business',
      children: (
        <HandlePortalServiceability
          currentPortalName="Business"
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleGetServiceabilityList={handleGetServiceabilityList}
          serviceList={serviceList}
        />
      )
    },
    {
      key: 'Partner',
      label: 'Partner',
      children: (
        <HandlePortalServiceability
          currentPortalName="Partner"
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleGetServiceabilityList={handleGetServiceabilityList}
          serviceList={serviceList}
        />
      )
    },
    {
      key: 'User',
      label: 'User',
      children: (
        <HandlePortalServiceability
          currentPortalName="User"
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleGetServiceabilityList={handleGetServiceabilityList}
          serviceList={serviceList}
        />
      )
    },
    {
      key: 'cod',
      label: 'COD Serviceability',
      children: <HandleCODService />
    }
  ];

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-normal">Manage Service Providers</h2>
        <Button
          onClick={() => setAddProviderModal(true)}
          className="primary-button flex items-center gap-2">
          <BiAddToQueue size={20} /> Add Provider
        </Button>
      </div>
      <div ref={containerRef} className="tabs-container">
        <Tabs
          animated
          type="card"
          activeKey={activeTab}
          onChange={onTabChange}
          tabBarGutter={20}
          size="small"
          className="md:w-[100%] w-full mx-auto p-2 border-0">
          {items.map((item) => (
            <Tabs.TabPane
              key={item.key}
              tab={<div style={{ width: '150px', textAlign: 'center' }}>{item.label}</div>}>
              {item.children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>

      <AddServiceProvider
        isLoading={isAddProviderLoading}
        setIsLoading={setIsAddProviderLoading}
        isModalOpen={addProviderModal}
        setIsModalOpen={setAddProviderModal}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

export default ManageServiceProviders;
