import { Empty, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar
} from 'recharts';

const HourlyOrderGraph = ({ ordersData, loading }: any) => {
  const [graphData, setGraphData] = useState([]);

  const orderKeyNames = {
    success: 'Hourly Success Order'
  };

  useEffect(() => {
    const hoursArray = ordersData?.map((orders: any, index: number) => ({
      time: `${index} - ${index + 1}`,
      [orderKeyNames?.success]: orders
    }));

    setGraphData(hoursArray);
  }, [ordersData]);

  return (
    <div className="primary-box-shadow lg:w-[50%] w-full p-4 rounded-xl">
      <h3 className="mt-0 text-left my-5 text-lg font-normal">Hourly successful orders</h3>

      {loading ? (
        <div className="min-h-[500px] h-[90%] my-5 flex items-center justify-center">
          <Spin />
        </div>
      ) : ordersData?.length ? (
        <ResponsiveContainer height={500}>
          <BarChart data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" height={30} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={orderKeyNames?.success} fill="green" />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div className="h-[90%] flex items-center justify-center">
          <Empty description={'No orders found.'} />
        </div>
      )}
    </div>
  );
};

export default HourlyOrderGraph;
