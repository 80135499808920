import React, { useState } from 'react';
import { Form, Input, Button, message, Collapse } from 'antd';
import { CaretRightFilled, CaretDownFilled } from '@ant-design/icons';
import { verifySellerRegistration } from './api';
import DocVerification from './Partials/DocVerification';

const SellerVerification: React.FC = () => {
  const [form] = Form.useForm();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [userId, setuserId] = useState('');
  const [loading, setLoading] = useState(false);

  const customArrow = (isOpen?: boolean) => (isOpen ? <CaretDownFilled /> : <CaretRightFilled />);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const response = await verifySellerRegistration(values?.email);
      if (response?.status === 202) {
        setuserId(response?.data?.userId);
        message.success('Email verified successfully!');
        setIsEmailVerified(true);
        setLoading(false);
      } else {
        message.error(response?.message);
      }
    } catch (error) {
      message.error('Failed to verify email.');
    }
  };

  return (
    <div>
      <h2 className="text-xl font-normal mb-4">Seller Registration</h2>
      <Form form={form} onFinish={onFinish}>
        <div className="flex justify-between gap-5">
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please enter a valid email!'
              }
            ]}
            className="w-full">
            <Input placeholder="Enter your email" />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Verify Email
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Collapse
        collapsible={isEmailVerified === false ? 'disabled' : 'header'}
        expandIconPosition="end"
        expandIcon={(panelProps) => customArrow(panelProps.isActive)}
        items={[
          {
            key: '1',
            label: 'Aadhaar Verification',
            children: (
              <DocVerification
                userId={userId}
                docType="aadhaar"
                docLabel="Aadhaar Number"
                docMsg="Please enter your aadhaar number!"
                docPlaceholder="Enter aadhaar number"
              />
            )
          }
        ]}
      />

      <Collapse
        collapsible={isEmailVerified === false ? 'disabled' : 'header'}
        expandIconPosition="end"
        expandIcon={(panelProps) => customArrow(panelProps.isActive)}
        items={[
          {
            key: '1',
            label: 'GST (Goods and Services Tax) Verification',
            children: (
              <DocVerification
                userId={userId}
                docType="gst"
                docLabel="GST Number"
                docMsg="Please enter your GST number!"
                docPlaceholder="Enter GST number"
              />
            )
          }
        ]}
        className="my-5"
      />

      <Collapse
        collapsible={isEmailVerified === false ? 'disabled' : 'header'}
        expandIconPosition="end"
        expandIcon={(panelProps) => customArrow(panelProps.isActive)}
        items={[
          {
            key: '1',
            label: 'IEC (Importer-Exporter Code) Verification',
            children: (
              <DocVerification
                userId={userId}
                docType="iec"
                docLabel="IEC Number"
                docMsg="Please enter your IEC number!"
                docPlaceholder="Enter IEC number"
              />
            )
          }
        ]}
        className="my-5"
      />

      <Collapse
        collapsible={isEmailVerified === false ? 'disabled' : 'header'}
        expandIconPosition="end"
        expandIcon={(panelProps) => customArrow(panelProps.isActive)}
        items={[
          {
            key: '1',
            label: 'PAN (Permanent Account Number) Verification',
            children: (
              <DocVerification
                userId={userId}
                docType="pan"
                docLabel="PAN Number"
                docMsg="Please enter your pan number!"
                docPlaceholder="Enter pan number"
              />
            )
          }
        ]}
        className="my-5"
      />

      <Collapse
        collapsible={isEmailVerified === false ? 'disabled' : 'header'}
        expandIconPosition="end"
        expandIcon={(panelProps) => customArrow(panelProps.isActive)}
        items={[
          {
            key: '1',
            label: 'Voter ID Verification',
            children: (
              <DocVerification
                userId={userId}
                docType="voter id"
                docLabel="Voter ID Number"
                docMsg="Please enter your voter id number!"
                docPlaceholder="Enter voter id number"
              />
            )
          }
        ]}
        className="my-5"
      />

      <Collapse
        collapsible={isEmailVerified === false ? 'disabled' : 'header'}
        expandIconPosition="end"
        expandIcon={(panelProps) => customArrow(panelProps.isActive)}
        items={[
          {
            key: '1',
            label: 'Passport Verification',
            children: (
              <DocVerification
                userId={userId}
                docType="passport"
                docLabel="Passport Number"
                docMsg="Please enter your passport number!"
                docPlaceholder="Enter passport number"
              />
            )
          }
        ]}
      />
    </div>
  );
};

export default SellerVerification;
