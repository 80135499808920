import React, { Fragment } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthPage from './AuthPage';

interface AuthRouterProps {
  isAuthenticated: boolean;
}

const AuthRouter: React.FC<AuthRouterProps> = ({ isAuthenticated }) => {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Navigate to="signin" replace />} />
      </Routes>
      {isAuthenticated ? <AuthPage isAuthenticated={isAuthenticated} /> : null}
    </Fragment>
  );
};

export default AuthRouter;
