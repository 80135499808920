export const PROVIDERS_ENUM: { [key: string]: string[] } = {
  DTDC: ['premium'],
  amazon: ['standard'],
  criticalog: ['fastTrack', 'superCritical'],
  delhivery: ['standard', 'surface'],
  ecom: ['standard'],
  ekart: ['express', 'standard'],
  gati: ['airExpress', 'surfaceExpress'],
  indiaPost: ['speedPost', 'pickup', 'selfDrop'],
  movin: ['express', 'standardPremium'],
  shadowfax: ['standard'],
  shreeMaruti: ['fastTrack', 'standard', 'surface'],
  xpressBees: ['cargo', 'standard', 'surface'],
  smartr: ['airCargo', 'airStandard'],
  bluedart: ['air']
};

export const SERVICE_TYPE_ENUMS = [
  {
    label: 'Premium',
    value: 'premium'
  },
  {
    label: 'Standard',
    value: 'standard'
  },
  {
    label: 'Fast Track',
    value: 'fastTrack'
  },
  {
    label: 'Super Critical',
    value: 'superCritical'
  },
  {
    label: 'Surface',
    value: 'surface'
  },
  {
    label: 'Express',
    value: 'express'
  },
  {
    label: 'Surface Express',
    value: 'surfaceExpress'
  },
  {
    label: 'Air Express',
    value: 'airExpress'
  },
  {
    label: 'Speed Post',
    value: 'speedPost'
  },
  {
    label: 'Pickup',
    value: 'pickup'
  },

  {
    label: 'Self Drop',
    value: 'selfDrop'
  },
  {
    label: 'Standard Premium',
    value: 'standardPremium'
  },
  {
    label: 'Cargo',
    value: 'cargo'
  },
  {
    label: 'Air Cargo',
    value: 'airCargo'
  },
  {
    label: 'Air Standard',
    value: 'speedPost'
  },
  {
    label: 'Air',
    value: 'air'
  }
];

export const SYNCHRONIZATION_ENUMS = [
  {
    label: 'Blocked',
    value: '0'
  },
  {
    label: 'Unblocked',
    value: '1'
  }
];
