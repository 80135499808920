import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Spin, Table } from 'antd';

import { GetBusinessOrdersList } from '../../api';
import { ColumnsType } from 'antd/es/table';
import { getDate } from '../../../../../utils/getDate';
// future use
// import { ROLE_FOR_REFUND_ACTIONS } from '../../../../../utils/config';
// import { GetProviderLogo } from '../../../../../utils/GetProviderLogos';
import GetStatus from '../../../../../utils/GetStatus';

const BusinessOrdersDetails: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [orderDetails, setOrderDetails] = useState<any>({});
  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [detailsTableData, setDetailsTableData] = useState<any>([]);
  const [packageTableData, setPackageTableData] = useState<any>([]);
  const [otherData, setOtherData] = useState<any>({});

  const handleGetOrderDetails = () => {
    GetBusinessOrdersList({
      setDataState: setOrderDetails,
      setLoading: setOrderLoading,
      orderId: params?.orderId
    });
  };

  useEffect(() => {
    handleGetOrderDetails();
  }, [params]);

  useEffect(() => {
    const updatedData = orderDetails?.ordersList?.length
      ? [
          {
            rowHeading: 'Pickup Details',
            name: orderDetails?.ordersList[0]?.pickup_customer_name,
            addressLine1: orderDetails?.ordersList[0]?.pickup_address_line1,
            addressLine2: orderDetails?.ordersList[0]?.pickup_address_line2,
            city: orderDetails?.ordersList[0]?.pickup_address_city,
            state: orderDetails?.ordersList[0]?.pickup_address_state,
            country: orderDetails?.ordersList[0]?.pickup_address_country,
            pincode: orderDetails?.ordersList[0]?.pickup_address_pincode,
            mobileNum: orderDetails?.ordersList[0]?.pickup_phone_number,
            emailId: orderDetails?.ordersList[0]?.pickup_email
          },
          {
            rowHeading: 'Delivery Details',
            name: orderDetails?.ordersList[0]?.delivery_customer_name,
            addressLine1: orderDetails?.ordersList[0]?.delivery_address_line1,
            addressLine2: orderDetails?.ordersList[0]?.delivery_address_line2,
            city: orderDetails?.ordersList[0]?.delivery_address_city,
            state: orderDetails?.ordersList[0]?.delivery_address_state,
            country: orderDetails?.ordersList[0]?.delivery_address_country,
            pincode: orderDetails?.ordersList[0]?.delivery_address_pincode,
            mobileNum: orderDetails?.ordersList[0]?.delivery_phone_number,
            emailId: orderDetails?.ordersList[0]?.delivery_email
          },
          {
            rowHeading: 'Billing Details',
            name: orderDetails?.ordersList[0]?.billing_customer_name,
            addressLine1: orderDetails?.ordersList[0]?.billing_address_line1,
            addressLine2: orderDetails?.ordersList[0]?.billing_address_line2,
            city: orderDetails?.ordersList[0]?.billing_address_city,
            state: orderDetails?.ordersList[0]?.billing_address_state,
            country: orderDetails?.ordersList[0]?.billing_address_country,
            pincode: orderDetails?.ordersList[0]?.billing_address_pincode,
            mobileNum: orderDetails?.ordersList[0]?.delivery_phone_number,
            emailId: orderDetails?.ordersList[0]?.delivery_email
          }
        ]
      : [];

    setDetailsTableData(updatedData);

    const updatedPackageData = orderDetails?.ordersList?.length
      ? [
          {
            packageNum: 1,
            weight: orderDetails?.ordersList[0]?.weight,
            length: orderDetails?.ordersList[0]?.shipment_length,
            height: orderDetails?.ordersList[0]?.shipment_height,
            width: orderDetails?.ordersList[0]?.shipment_width
          }
        ]
      : [];

    setPackageTableData(updatedPackageData);

    const updatedOtherData = orderDetails?.ordersList?.length ? orderDetails?.ordersList[0] : {};

    setOtherData(updatedOtherData);
  }, [orderDetails]);

  const orderDetailsColumns: ColumnsType<any> = [
    {
      title: '',
      dataIndex: 'rowHeading',
      width: 150,
      render: (data) => (data ? <p className="m-0 font-semibold">{data}</p> : '-')
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Address line 1',
      dataIndex: 'addressLine1',
      width: 200,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Address line 2',
      dataIndex: 'addressLine2',
      width: 200,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'City',
      dataIndex: 'city',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'State',
      dataIndex: 'state',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Country',
      dataIndex: 'country',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Pincode',
      dataIndex: 'pincode',
      width: 100,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Mobile Num',
      dataIndex: 'mobileNum',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Email Id',
      dataIndex: 'emailId',
      width: 150,
      render: (data) => (data ? data : 'NA')
    }
  ];

  const packageDetailsColumns: ColumnsType<any> = [
    {
      title: 'Package Num',
      dataIndex: 'packageNum',
      width: 150,
      render: (data) => (data ? <p className="m-0 font-semibold">{data}</p> : '-')
    },
    {
      title: 'Weight (g)',
      dataIndex: 'weight',
      width: 150
    },
    {
      title: 'Length (cm)',
      dataIndex: 'length',
      width: 150
    },
    {
      title: 'Height (cm)',
      dataIndex: 'height',
      width: 150
    },
    {
      title: 'Width (cm)',
      dataIndex: 'width',
      width: 150
    }
  ];

  return (
    <>
      <Button className="m-0 p-0" type="link" onClick={() => navigate('/business-orders-list')}>
        {`<`}
        <span className="hover:underline pl-1">Back to Business Orders List</span>
      </Button>
      <h3 className="text-center text-lg p-0 m-0 mb-3">Order Details</h3>
      <Table
        columns={orderDetailsColumns}
        dataSource={detailsTableData}
        pagination={false}
        loading={orderLoading}
        scroll={{
          x: 1600
        }}
        rowKey={'rowHeading'}
      />

      <div className="my-10">
        <h3 className="text-center text-lg p-0 m-0 mb-3">Package Details</h3>
        <Table
          columns={packageDetailsColumns}
          dataSource={packageTableData}
          pagination={false}
          loading={orderLoading}
          scroll={{
            x: 775
          }}
          rowKey={'rowHeading'}
        />
      </div>
      <Spin className="mt-10" spinning={orderLoading}>
        <div className="flex gap-6 flex-col xl:flex-row justify-between">
          <div className="w-full xl:w-[49%] primary-box-shadow rounded-xl p-5 flex flex-col gap-3.5">
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Order ID</p>
              <p className="pr-2">:</p>
              <p>{otherData?.orderId}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">AWB #</p>
              <p className="pr-2">:</p>
              <p>{otherData?.tracking_number}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Order Date</p>
              <p className="pr-2">:</p>
              <p>{getDate(otherData?.createdAt)}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Status</p>
              <p className="pr-2">:</p>
              <GetStatus
                currStatus={otherData?.order_status}
                PROCESSING="processing"
                SUCCESS="success"
                FAILED="failure"
                CANCELLED="cancelled"
              />
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Pickup Vendor</p>
              <p className="pr-2">:</p>
              <p>{otherData?.serviceProvider}</p>
            </div>
            {/* future use */}
            {/* <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Refund</p>
              <p className="pr-2">:</p>
              <div  >
                <div className="flex items-center">
                  <GetOrderStatus
                    refundStatus={singleOrderData?.refundStatus?.toUpperCase()}
                    setRefundRequestModal={setRefundRequestModal}
                  />

                  <div className="m-0 ml-2">
                    {singleOrderData?.initiatedCount >= 1 ? (
                      <Button className="primary-button" onClick={() => handleGetRefundList(false)}>
                        Details
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div> */}
            {/* {ROLE_FOR_REFUND_ACTIONS?.includes(tokenData?.role) ? (
              <div className="flex items-center">
                <p className="w-44 font-semibold text-xl">Refund Request</p>
                <p className="pr-2">:</p>
                <div  >
                  <Button className="primary-button" onClick={() => handleGetRefundList(true)}>
                    Action
                  </Button>
                </div>
              </div>
            ) : null} */}

            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Bill amount</p>
              <p className="pr-2">:</p>
              <p>{otherData?.totalAmount}</p>
            </div>
          </div>
          <div className="w-full xl:w-[49%] primary-box-shadow rounded-xl p-5 flex flex-col gap-4">
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Delivery Vendor</p>
              <p className="pr-2">:</p>
              <p>{otherData?.delivery_vendor}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Payment Type</p>
              <p className="pr-2">:</p>
              <p>{otherData?.payment_type}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Payment Provider</p>
              <p className="pr-2">:</p>
              <p>{otherData?.end_customer_payment_method}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Service</p>
              <p className="pr-2">:</p>
              <p>{otherData?.serviceType}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Item Desc</p>
              <p className="pr-2">:</p>
              <p>{otherData?.item_description}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Item Category</p>
              <p className="pr-2">:</p>
              <p>{otherData?.item_category}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Item Value</p>
              <p className="pr-2">:</p>
              <p>{otherData?.estimated_value}</p>
            </div>
            {otherData?.invoice_order ? (
              <div className="flex items-center">
                <p className="w-44 font-semibold text-xl">Invoice</p>
                <p className="pr-2">:</p>
                <span>
                  <Button
                    href={otherData?.invoice_order}
                    target="_blank"
                    className="primary-button">
                    View Invoice
                  </Button>
                </span>
              </div>
            ) : null}

            {/* future use */}
            {/* <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Shipment Label</p>
              <p className="pr-2">:</p>
              <span  >
                <Button
                  href={otherData?.shipmentLabel}
                  target="_blank"
                  className="primary-button">
                  View Shipment Label
                </Button>
              </span>
            </div> */}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default BusinessOrdersDetails;
