import { Button, Divider, Form, Input, Radio, Spin, Table } from 'antd';
import React, { useState } from 'react';
import useOrderListDetails from '../../api';
import InputWithMultiValues from '../../../../CommonComponents/InputWithMultiValues';
import { ColumnsType } from 'antd/es/table';

const PushFailedOrder: React.FC = () => {
  const [pushFailedOrdersForm] = Form.useForm();
  const { pushFailedOrderApi, isLoading } = useOrderListDetails();
  const [orderIds, setOrderIDs] = useState<string[]>([]);
  const [orderType, setOrderType] = useState<string>('');
  const [reOrders, setReOrders] = useState<{
    successOrders: string[];
    failedOrders: string[];
  }>({ successOrders: [], failedOrders: [] });

  // eslint-disable-next-line
  const onPushFailedOrders = async (formValues: any) => {
    pushFailedOrderApi({
      orderIDs: orderIds?.length > 0 ? orderIds : formValues?.failedOrderId?.trim(),
      gatewayOrderID: formValues?.gatewayOrderId?.trim(),
      payementId: formValues?.paymentId?.trim(),
      isBulkOrderId: orderType === 'CUSTOM_BULK_ORDER_ID',
      setResponseData: setReOrders,
      callbackFun: clearAll
    });
  };

  const clearAll = () => {
    setOrderIDs([]);
    setOrderType('');
    pushFailedOrdersForm?.resetFields();
  };

  const failedOrdersOption: {
    label: string;
    value: string;
  }[] = [
    {
      label: 'Multiple Ids',
      value: 'MULTIPLE'
    },
    {
      label: 'Custom Bulk Order Id',
      value: 'CUSTOM_BULK_ORDER_ID'
    }
  ];

  const navigateToOrderDetails = (orderId: string) => {
    const newRoute = `/order-details/${orderId}`;
    const link = window.location.origin + newRoute;
    window.open(link, '_blank');
  };

  // eslint-disable-next-line
  const orderColumns: ColumnsType<any> = [
    {
      title: 'Order id',
      dataIndex: 'id',
      width: '70%'
    },
    {
      title: 'Action',
      width: '30%',
      dataIndex: 'id',
      render: (id) => (
        <Button onClick={() => navigateToOrderDetails(id)} className="primary-button">
          View Details
        </Button>
      )
    }
  ];

  return (
    <div>
      <h2 className="text-xl font-normal m-0 mb-4">Push Failed Order</h2>
      <Spin spinning={isLoading}>
        <div className="flex justify-center">
          <div className="max-w-[550px] w-full  p-5 primary-box-shadow rounded-xl">
            <Form
              scrollToFirstError
              id={'pushFailedOrdersForm'}
              layout="vertical"
              autoComplete="off"
              className="gap-4"
              onFinish={onPushFailedOrders}
              form={pushFailedOrdersForm}>
              <Form.Item
                rules={[{ required: true, message: 'Please Select any one.' }]}
                name="orderType"
                label="Order Type :">
                <Radio.Group
                  className="py-2 flex flex-wrap"
                  onChange={({ target }) => {
                    setOrderType(target?.value);
                    setOrderIDs([]);
                  }}
                  value={orderType}>
                  {failedOrdersOption?.map((option) => (
                    <Radio key={option?.value} value={option?.value}>
                      {option?.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              {orderType === 'CUSTOM_BULK_ORDER_ID' ? (
                <Form.Item
                  rules={[{ required: true, message: 'Please enter order id.' }]}
                  name="failedOrderId"
                  className="mb-4"
                  label="Order ID:">
                  <Input type="text" placeholder="Enter Order Id" disabled={!orderType} />
                </Form.Item>
              ) : (
                <Form.Item
                  rules={[
                    { required: !(orderIds?.length > 0), message: 'Please enter order ids.' }
                  ]}
                  name="failedOrderIds"
                  className="mb-4"
                  label="Order ID:">
                  <InputWithMultiValues tags={orderIds} setTags={setOrderIDs} />
                </Form.Item>
              )}

              <Form.Item
                label={`Gateway Order ID : (Optional)`}
                name="gatewayOrderId"
                className="mb-4">
                <Input type="text" placeholder="Enter gateway order id" disabled={!orderType} />
              </Form.Item>

              <Form.Item label={`Payment ID : (Optional)`} name="paymentId" className="mb-4">
                <Input type="text" placeholder="Enter payment id" disabled={!orderType} />
              </Form.Item>
            </Form>

            <Button
              className="m-0 primary-button"
              htmlType={'submit'}
              form={'pushFailedOrdersForm'}>
              Submit
            </Button>
          </div>
        </div>
      </Spin>

      {reOrders?.successOrders?.length > 0 || reOrders?.failedOrders?.length > 0 ? (
        <div className="md:flex-row flex flex-col gap-4 mt-8">
          <div className="md:w-1/2">
            <h2 className="mb-4 text-lg font-bold text-center">Success Orders</h2>
            <Table
              className="primary-box-shadow rounded-xl"
              columns={orderColumns}
              dataSource={reOrders?.successOrders?.map((id: string) => ({ id: id }))}
            />
          </div>
          <Divider className="h-auto" type="vertical" />
          <div className="md:w-1/2">
            <h2 className="mb-4 text-lg font-bold text-center">Failed Orders</h2>
            <Table
              className="primary-box-shadow rounded-xl"
              columns={orderColumns}
              dataSource={reOrders?.failedOrders?.map((id: string) => ({ id: id }))}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PushFailedOrder;
