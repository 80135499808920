import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          onClick={() => navigate('/dashboard')}
          className="!text-[#fff]"
          style={{ background: 'rgba(3, 143, 222, 0.7)' }}>
          Back To Dashboard
        </Button>
      }
    />
  );
};

export default PageNotFound;
