import { Button, Form, Spin } from 'antd';
import React, { useState } from 'react';
import FileUploadButton from './FileUploadButton';
import SelectProviderFields from './SelectProviderFields';
import { getTokenDetails } from '../../../../../utils/getTokenDetails';
import { IPincodeFormFields } from '../../../../../interfaces/handlePincode';
import { handlePincodes } from '../../api';

const BlockUnblockPincode = () => {
  const [fileName, setFileName] = useState<string>('No File Chosen');
  const [pincodeData, setPincodeData] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pincodeForm] = Form.useForm();

  const onSubmitForm = (formValues: IPincodeFormFields) => {
    const { tokenData } = getTokenDetails();
    const payload = {
      ...formValues,
      pincodes: pincodeData,
      blockedBy: 'Coreyo',
      operator: tokenData?.fullName,
      isPickup: Boolean(formValues?.blockFor?.includes('Pickup')),
      isDelivery: Boolean(formValues?.blockFor?.includes('Delivery'))
    };

    delete payload?.blockFor;

    handlePincodes({
      setLoading: setIsLoading,
      payload,
      callbackFunc: () => {
        pincodeForm.resetFields();
        setPincodeData([]);
        setFileName('');
      }
    });
  };

  return (
    <>
      <h2 className="text-xl font-normal m-0 mt-2">Block / Unblock Pincode</h2>

      <div className="flex flex-col items-center">
        <div className="my-5 w-[90%] md:w-[500px] primary-box-shadow rounded-xl">
          <Spin spinning={isLoading}>
            <div className="p-5 bg-white opacity-70 rounded-xl">
              <Form onFinish={onSubmitForm} id="pincodeForm" form={pincodeForm} layout="vertical">
                <FileUploadButton
                  pincodeForm={pincodeForm}
                  fileName={fileName}
                  setFileName={setFileName}
                  pincodeData={pincodeData}
                  setPincodeData={setPincodeData}
                />

                {!!pincodeData?.length && <SelectProviderFields pincodeForm={pincodeForm} />}

                <Button
                  disabled={!pincodeData?.length}
                  className="mt-5 primary-button"
                  htmlType="submit"
                  form="pincodeForm">
                  Submit
                </Button>
              </Form>
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default BlockUnblockPincode;
