import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Input, Modal, Radio, Spin, Switch } from 'antd';
import { getTokenDetails } from '../../../../../utils/getTokenDetails';
import useOrderListDetails from '../../api';

const { TextArea } = Input;

interface RefundRequestProps {
  refundRequestModal: boolean;
  setRefundRequestModal: Dispatch<SetStateAction<boolean>>;
  orderTotalAmount: string | number;
  callBackFunction: () => void;
}

const RefundRequest: React.FC<RefundRequestProps> = ({
  refundRequestModal,
  setRefundRequestModal,
  orderTotalAmount,
  callBackFunction
}) => {
  const param = useParams();
  const [refundForm] = Form.useForm();

  const [refundSpeed, setRefundSpeed] = useState<string>('');
  const [isCustomAmount, setIsCustomAmount] = useState<boolean>(false);

  const { requestForRefund, isLoading } = useOrderListDetails();
  const { tokenData } = getTokenDetails();

  useEffect(() => {
    refundForm?.setFieldsValue({
      refundAmount: orderTotalAmount,
      refundSpeed: 'normal'
    });
  }, [param, orderTotalAmount, refundRequestModal]);

  const onClearAll = () => {
    setRefundRequestModal(false);
    setIsCustomAmount(false);
    refundForm?.resetFields();
  };

  const refundSpeedOptions = [
    { value: 'normal', label: 'Normal' },
    { value: 'optimum', label: 'Optimum' }
  ];

  const onSubmitRequest = async (values: any) => {
    const payload = {
      orderId: param?.orderId,
      refundSpeed: values?.refundSpeed,
      actionBy: tokenData?.fullName,
      amount: Number(values?.refundAmount),
      reasonOfAction: values?.refundReason
    };

    const { data } = await requestForRefund({
      payload: payload,
      callbackFun: onClearAll
    });

    if (data?.status >= 200 && data?.status <= 300) {
      if (callBackFunction) {
        callBackFunction();
      }
    }
  };

  return (
    <>
      <Modal
        forceRender
        open={refundRequestModal}
        title="Request for refund"
        footer={false}
        onCancel={onClearAll}>
        <Spin spinning={isLoading}>
          <div className="max-w-md">
            <div className="flex my-3">
              <p className="mr-2 w-28 flex justify-between">
                Order Id <span>:</span>
              </p>
              <p>{param?.orderId}</p>
            </div>

            <Form
              scrollToFirstError
              id={'refundRequestForm'}
              layout="vertical"
              form={refundForm}
              onFinish={onSubmitRequest}>
              <div className="flex flex-col gap-3">
                <div className="mb-2 flex items-center">
                  <p className="mb-0 mr-2 w-28 flex justify-between">
                    Refund speed <span>:</span>
                  </p>
                  <Form.Item
                    className="mb-0"
                    name="refundSpeed"
                    rules={[{ required: true, message: 'Please select any refund speed.' }]}>
                    <Radio.Group
                      onChange={({ target }) => setRefundSpeed(target?.value)}
                      value={refundSpeed}>
                      {refundSpeedOptions?.map((options, key) => (
                        <Radio key={key} value={options?.value}>
                          {options?.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>

                <div className="mb-3 flex">
                  <p className="mb-0 mr-2 w-28 flex justify-between">
                    Custom Amount <span>:</span>
                  </p>
                  <div>
                    <Switch
                      checked={isCustomAmount}
                      className="bg-gray-300"
                      onChange={(checked) => setIsCustomAmount(checked)}
                    />
                    <Form.Item
                      name="refundAmount"
                      className="mb-0 mt-2"
                      rules={[
                        { required: isCustomAmount, message: 'Please enter amount.' },
                        () => ({
                          validator(_, value) {
                            if (Number(value) <= Number(orderTotalAmount)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(`Amount must be less than ${orderTotalAmount}`)
                            );
                          }
                        })
                      ]}>
                      <Input
                        type="number"
                        placeholder="Enter Amount"
                        disabled={!isCustomAmount}
                        onKeyDown={(e) =>
                          ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="mb-2 flex">
                  <p className="mt-1 mb-0 mr-2 w-28 flex justify-between">
                    Refund reason <span>:</span>
                  </p>
                  <div>
                    <Form.Item
                      className="mb-0 mt-2"
                      name="refundReason"
                      rules={[{ required: true, message: 'Please enter the reason.' }]}>
                      <TextArea showCount placeholder="Enter refund reason." />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>

            <div className="mt-8 flex justify-end gap-4">
              <Button onClick={onClearAll}>Cancel</Button>
              <Button className="primary-button" htmlType={'submit'} form={'refundRequestForm'}>
                Send request
              </Button>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default RefundRequest;
