export const addressFormValidations = {
  address: {
    required: 'Please enter address.',
    patternValueRegex: /^[a-zA-Z0-9\d\-_@/.,\s]+$/,
    patternMessage: 'Only , . / - _ special characters allowed',
    maxCharactersLength: 50,
    maxCharactersMessage: 'Maximum 50 characters allowed.'
  },
  locality: {
    required: 'Please enter locality'
  },
  postalCode: {
    required: 'Please enter postal code',
    patternValueRegex: /^[1-9][0-9]{5}$/,
    patternMessage: 'Postal code is invalid'
  },
  isdCode: {
    required: 'Please enter ISD code'
  },
  mobileNumber: {
    required: 'Please enter mobile number',
    patternValueRegex: /^[0-9]{10}$/,
    patternMessage: 'Please enter a valid mobile number'
  },
  cityFieldName: {
    required: 'Please enter city'
  },
  stateFieldName: {
    required: 'Please enter state'
  }
};
