import { Button, DatePicker, Form, Input, Pagination, PaginationProps, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import useWalletTransactions from './api';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const WalletTransactions: React.FC = () => {
  const [userIdSearchForm] = Form.useForm();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [userId, setUserId] = useState('');
  const [daterange, setDaterange] = useState({ from: '', to: '' });

  const { transactionData, getTransactionOfWallet, isLoading } = useWalletTransactions();

  const handleGetTransaction = () => {
    getTransactionOfWallet({
      pageNumber: currentPage,
      pageLimit: pageLimit,
      userId: userId,
      from: new Date(daterange?.from),
      to: new Date(daterange?.to)
    });
  };

  useEffect(() => {
    handleGetTransaction();
  }, [currentPage, pageLimit, daterange, userId]);

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    if (pageNo < 1) {
      setCurrentPage(1);
    } else if (pageNo > Math.ceil(transactionData.totalPage / limit)) {
      setCurrentPage(Math.ceil(transactionData.totalPage / limit));
    } else {
      setCurrentPage(pageNo);
    }
    setPageLimit(limit);
  };

  // User Id Search Functions
  const handleOrderIdSearch = ({ userId }: any) => {
    setUserId(userId);
  };
  const handleOrderIdReset = () => {
    setUserId('');
    userIdSearchForm.resetFields();
  };
  const getUserId = () => ({
    filterDropdown: () => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Form
          form={userIdSearchForm}
          layout="vertical"
          id="userIdForm"
          onFinish={handleOrderIdSearch}>
          <Form.Item name="userId" className="mb-3">
            <Input placeholder="User ID" value={userId} />
          </Form.Item>
        </Form>
        <div className="flex gap-3 justify-end">
          <Button onClick={handleOrderIdReset} size="small">
            Reset
          </Button>
          <Button htmlType="submit" form="userIdForm" size="small" className="primary-button">
            Search
          </Button>
        </div>
      </div>
    )
  });

  // Date Search Functions
  const handleDateRangeChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string
  ) => {
    setDaterange({ from: dateString[0], to: dateString[1] });
  };
  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };
  const getdateRangeColumnFilterProps = () => ({
    filterDropdown: () => (
      <div className="p-4" onKeyDown={(e) => e.stopPropagation()}>
        <p className="m-0 mb-3 text-gray-500">Select Date Range:</p>
        <RangePicker
          id="ordersDateRange"
          onChange={handleDateRangeChange}
          disabledDate={disabledDate}
          showTime={{
            hideDisabledOptions: true,
            defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('11:59:59', 'HH:mm:ss')]
          }}
          format="YYYY-MM-DD HH:mm:ss"
        />
      </div>
    )
  });

  const columns: ColumnsType<any> = [
    {
      title: 'Transaction Id',
      dataIndex: '_id',
      key: '_id'
    },
    {
      title: 'User Id',
      dataIndex: 'user_id',
      key: 'user_id',
      ...getUserId()
    },
    {
      title: 'Wallet Balance',
      dataIndex: 'balance',
      key: 'balance'
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
      ...getdateRangeColumnFilterProps()
    },
    {
      title: 'Wallet status',
      dataIndex: 'account_active',
      key: 'account_active',
      render: (accountActive) => (accountActive ? 'Active' : 'Inactive')
    }
    // Note - Might need later this Action Button for Wallet Detail View
    // {
    //   title: 'Action',
    //   render: (text, record: { user_id: any }) => (
    //     <Button className="gx-bg-yellow">View Details</Button>
    //   )
    // }
  ];

  return (
    <div>
      <h2 className="text-xl font-normal mb-4">Wallet Transactions</h2>
      <Table
        columns={columns}
        dataSource={transactionData?.transactionList}
        loading={isLoading}
        rowKey={'userId'}
        scroll={{ x: 1200 }}
        pagination={false}
      />
      <br />
      <div className="flex justify-end">
        <Pagination
          responsive={true}
          showQuickJumper
          current={currentPage}
          pageSize={pageLimit}
          pageSizeOptions={[20, 30, 50]}
          total={transactionData?.totalPage ? transactionData?.totalPage : 0}
          onChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default WalletTransactions;
