import { Modal, Steps } from 'antd';
import React, { FC } from 'react';
import { IValueAddedServiceEvents } from '../../../../../../interfaces/b2bVerification';
import moment from 'moment';

interface IEventsModal {
  isOpen: boolean;
  onClose: () => void;
  eventModalData: IValueAddedServiceEvents[] | undefined;
  modalTitle: string;
}
const EventsModal: FC<IEventsModal> = ({ isOpen, onClose, eventModalData, modalTitle }) => {
  return (
    <Modal title={modalTitle || 'Details'} open={isOpen} onCancel={onClose} footer={false}>
      <Steps
        progressDot
        current={eventModalData?.length}
        direction="vertical"
        items={eventModalData?.map((event, index) => ({
          title: event?.action ? (
            <p className="font-medium">{event?.action?.split('_')?.join(' ')}</p>
          ) : (
            'NA'
          ),
          description: (
            <>
              <div className="flex flex-wrap gap-2">
                <p className="font-bold w-[150px]  flex justify-between">
                  Service <span>:</span>
                </p>
                {event?.service ? (
                  <p className="text-clip">{event?.service?.split('_')?.join(' ')}</p>
                ) : (
                  'NA'
                )}
              </div>

              <div className="flex flex-wrap gap-2">
                <p className="font-bold w-[150px]  flex justify-between">
                  Admin User Id <span>:</span>
                </p>
                {event?.adminUserId ? <p className="text-clip">{event?.adminUserId}</p> : 'NA'}
              </div>

              <div className="flex flex-wrap gap-2">
                <p className="font-bold w-[150px]  flex justify-between">
                  Admin User Email <span>:</span>
                </p>
                {event?.adminUserEmail ? (
                  <p className="text-clip">{event?.adminUserEmail}</p>
                ) : (
                  'NA'
                )}
              </div>

              <div className="flex flex-wrap gap-2">
                <p className="font-bold w-[150px]  flex justify-between">
                  Date <span>:</span>
                </p>
                {event?.timestamp ? (
                  <p className="text-clip">
                    {moment(event?.timestamp).format('DD/MM/YYYY, HH:MM:SS')}
                  </p>
                ) : (
                  'NA'
                )}
              </div>

              {!(eventModalData?.length === index + 1) && <hr className="my-2" />}
            </>
          )
        }))}
      />
    </Modal>
  );
};

export default EventsModal;
