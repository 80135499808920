import React, { useState, useEffect } from 'react';
import { collection, doc, getDocs, onSnapshot } from '@firebase/firestore';
import { db } from '../../../firebase/firebase';
import MonthDailyOrderLineGraph from './Partials/MonthDailyOrderLineGraph';
import HourlyOrderGraph from './Partials/HourlyOrderGraph';
import YearlyOrderGraph from './Partials/YearlyOrderGraph';

interface ProvidedOrderData {
  id: string;
  cancelledThisMonth: number;
  cancelledToday: number;
  date: number;
  failedThisMonth: number;
  failedToday: number;
  hourlyOrderData: [];
  month: 6;
  monthlyOrdersData: [];
  successfulOrdersTillDate: number;
  successfulThisMonth: number;
  successfulTillDate: number;
  successfulToday: number;
  year: number;
}

const Dashboard: React.FC = () => {
  const [loading, setIsLoading] = useState<boolean>(false);
  const [providedOrderData, setProvidedOrderData] = useState<ProvidedOrderData | null>(null);
  const [yearlyData, setYearlyData] = useState([]);

  const collectionName = 'countOrders/archive/monthlyArchive';

  const yearlyDataFromFirebase = async () => {
    const docRefs = await getDocs(collection(db, collectionName));

    const res: any = [];

    docRefs.forEach((month) => {
      res.push({
        id: month.id,
        ...month.data()
      });
    });

    setYearlyData(res);

    return res;
  };

  const firebaseImplementation = async () => {
    const docRefId = doc(db, 'countOrders', 'live');
    if (docRefId) {
      setIsLoading(true);
    }
    const unsub = onSnapshot(docRefId, (snapshot) => {
      setProvidedOrderData(snapshot?.data() as ProvidedOrderData);
      setIsLoading(false);
    });

    return () => {
      unsub();
    };
  };

  useEffect(() => {
    firebaseImplementation();
    yearlyDataFromFirebase();
  }, []);

  return (
    <div>
      <h2 className="text-xl font-normal mb-4">Dashboard</h2>

      <div className="mb-10 flex lg:flex-row flex-col gap-10">
        <HourlyOrderGraph loading={loading} ordersData={providedOrderData?.hourlyOrderData} />
        <MonthDailyOrderLineGraph
          loading={loading}
          ordersData={providedOrderData?.monthlyOrdersData}
        />
      </div>
      <div className="flex lg:flex-row flex-col">
        <YearlyOrderGraph loading={loading} yearlyData={yearlyData} />
      </div>
    </div>
  );
};

export default Dashboard;
