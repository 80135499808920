import { Skeleton } from 'antd';
import React from 'react';

const LoadingPlaceHolder = () => {
  return (
    <div className="flex flex-col gap-5 py-5">
      <div className="flex gap-5">
        <Skeleton.Image active />
        <Skeleton paragraph={{ rows: 2 }} />
      </div>

      <div className="flex gap-5">
        <div className="flex flex-col gap-5">
          <Skeleton.Input active />
          <Skeleton.Input active />
          <Skeleton.Input active />
        </div>
        <Skeleton paragraph={{ rows: 3 }} />
      </div>
    </div>
  );
};

export default LoadingPlaceHolder;
