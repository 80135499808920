import { Button, Checkbox, Form, Select, Spin } from 'antd';
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import { PROVIDERS_ENUM } from '../../utils/providers';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { ISearchPincodeFormFields } from '../../../../../interfaces/handlePincode';
import { SearchPincodes } from '../../api';

interface ISearchPincodeForm {
  setPincodesData: Dispatch<SetStateAction<number[] | undefined>>;
}
const SearchPincodeForm: FC<ISearchPincodeForm> = ({ setPincodesData }) => {
  const [searchPincodeForm] = Form.useForm();
  const [serviceTypes, setServiceTypes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clearPincodeData = () => {
    setPincodesData(undefined);
  };
  const onSelectProvider = (option: ChangeEvent<HTMLSelectElement>) => {
    setServiceTypes(PROVIDERS_ENUM[`${option}`]);
    searchPincodeForm.resetFields(['serviceType']);
    clearPincodeData();
  };

  const onSubmitForm = (formValues: ISearchPincodeFormFields) => {
    const payload = {
      ...formValues,
      isPickup: Boolean(formValues?.blockFor?.includes('Pickup')),
      isDelivery: Boolean(formValues?.blockFor?.includes('Delivery'))
    };
    delete payload?.blockFor;

    SearchPincodes({ setLoading: setIsLoading, payload: payload, setData: setPincodesData });
  };

  const checkboxOptions = ['Pickup', 'Delivery'];

  return (
    <div className="flex flex-col items-center">
      <div className="w-[90%] md:w-[500px] primary-box-shadow rounded-xl">
        <Spin spinning={isLoading}>
          <div className="p-5 bg-white opacity-70 rounded-xl">
            <Form
              onFinish={onSubmitForm}
              form={searchPincodeForm}
              scrollToFirstError
              layout="vertical">
              <Form.Item
                name="provider"
                label={<Formlabel labelText="Provider" />}
                rules={[
                  {
                    required: true,
                    message: 'Select any provider'
                  }
                ]}>
                <Select
                  showSearch
                  placeholder="Select provider"
                  options={Object.keys(PROVIDERS_ENUM)?.map((provider) => ({
                    label: provider?.toUpperCase(),
                    value: provider
                  }))}
                  onChange={onSelectProvider}
                />
              </Form.Item>

              <Form.Item
                name="serviceType"
                label={<Formlabel labelText="Service Type" />}
                rules={[
                  {
                    required: true,
                    message: 'Select service type'
                  }
                ]}>
                <Select
                  disabled={!serviceTypes?.length}
                  showSearch
                  placeholder="Select service type"
                  options={serviceTypes?.map((service) => ({
                    label: service?.toUpperCase(),
                    value: service
                  }))}
                  onChange={clearPincodeData}
                />
              </Form.Item>

              <Form.Item
                name="blockFor"
                label={<Formlabel labelText="Block For" />}
                rules={[{ required: true, message: 'Please select any option.' }]}>
                <Checkbox.Group options={checkboxOptions} />
              </Form.Item>

              <div className="flex justify-center">
                <Button loading={isLoading} htmlType="submit" className="primary-button">
                  Search
                </Button>
              </div>
            </Form>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default SearchPincodeForm;
