import { Button, DatePicker, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { downloadCurrentTracking } from "../api";
import { DELIVERY_VENDORS_LIST } from "../../../../utils/constant";
import dayjs, { Dayjs } from "dayjs";
import { getFinTechUsers } from "../../FintechOrders/api";


const DownloadTracking: React.FC = () => {
    const [dateRange, setDateRange] = useState<{ startDate: Date | null, endDate: Date | null }>({ startDate: null, endDate: null });
    const [error, setError] = useState<string>('');
    const [providerName, setProviderName] = useState<string>('');
    const [users, setUsers] = useState<{label:string,value:string}[]>([{label:"",value:""}]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<string>(''); 

    const getUsers = async() => {
      const usersData= await getFinTechUsers({setLoading:setLoading});
      const dropDownInterFace: { label: string; value: string }[] = [];

      for(let i=0;i<usersData.length;i++){
        dropDownInterFace.push({label:usersData[i].identifier,value:usersData[i].userId})
      }
      setUsers(dropDownInterFace);
      setLoading(false);
    }

    useEffect(() => {
      getUsers()
     }, []);

    const handleUserChange = (value: string) => {   
      setSelectedUser(value);
    };
    
    const handleGetOrder = async () => {
      if (dateRange.startDate && dateRange.endDate && providerName ) {
        setError('');
        try {
          const response = await downloadCurrentTracking({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            providerName,
            userId: selectedUser
          });
          if(response?.data?.url){
            window.open(response?.data.url,'_blank'); 
          }
        } catch (error) {
          setError('Failed to download tracking data');
        }
      } else {
        setError('Please enter all required data.');
      }
    };
  
    const handleDateRangeChange = (
      dates: [Dayjs | null, Dayjs | null] | null
    ) => {
        setDateRange({
            startDate: dates ? dates[0]?.toDate() ?? null : null,
            endDate: dates ? dates[1]?.toDate() ?? null : null
          });
    };
  
    const disabledDate = (current: Dayjs) => {
      return current && current > dayjs().endOf('day');
    };
  
    return (
      <div>
        <h2 className="text-xl font-normal m-0 mb-4">Active Order</h2>
        <div className="flex flex-col items-center gap-5">
          {error ? <p className="m-0 text-red-400">{error}</p> : null}
          <div className="p-10 rounded-xl flex flex-col gap-5 primary-box-shadow">
            <div className="flex flex-col gap-3 md:gap-0 md:flex-row md:items-center">
              <p className="m-0 mr-2 w-[30%] flex justify-between">
                Select Date Range <span>:</span>
              </p>
              <div className="w-[70%]">
                <DatePicker.RangePicker
                  id="currentTrackingDate"
                  onChange={handleDateRangeChange}
                  disabledDate={disabledDate}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </div>
            </div>
  
            <div className="flex items-center">
              <p className="m-0 mr-2 w-[30%] flex justify-between">
                Provider Name <span>:</span>
              </p>
              <div className="w-[70%]">
                <Form.Item name="providerName" className="m-0 w-full">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select Provider"
                    onChange={setProviderName}
                    options={DELIVERY_VENDORS_LIST.map((item) => ({ value: item, label: item }))}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex items-center">
              <p className="m-0 mr-2 w-[30%] flex justify-between">
                User <span>:</span>
              </p>
              <div className="w-[70%]">
                <Form.Item name="user" className="m-0 w-full" rules={[{ required: true, message: 'Please enter user.' }]}>
                  <Select
                    placeholder="Select user name"
                    onChange={handleUserChange}
                    options={users.map((user) => ({ value: user.value, label: user.label }))}
                  />
                </Form.Item>
              </div>
            </div>
            <Button className="primary-button" onClick={handleGetOrder}>
              Download Report
            </Button>
          </div>
        </div>
      </div>
    );
};

    

export default DownloadTracking;