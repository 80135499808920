import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../utils/apiRequest';
import { ErrorMessage } from '../../../../utils/ResponseMessages';
import { IB2BOrderDetails, IB2bV3OrdersList } from '../../../../interfaces/b2bV3OrdersList';

export const getB2BOrders = async ({
  setLoading,
  setData,
  pageLimit,
  skip,
  orderId,
  trackingId,
  clientReferenceId,
  businessOrderId,
  from,
  to,
  orderStatus,
  selectedProvider
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IB2bV3OrdersList | undefined>>;
  pageLimit: number;
  skip: number;
  orderId?: string;
  trackingId?: string;
  clientReferenceId?: string;
  businessOrderId?: string;
  from?: string;
  to?: string;
  orderStatus?: string;
  selectedProvider?: string;
}) => {
  setLoading(true);
  const offSet = (skip - 1) * pageLimit;
  const queryParams = `?offset=${offSet}&limit=${pageLimit}`;
  const orderIdQuery = orderId ? `&orderId=${orderId}` : '';
  const trackingIdQuery = trackingId ? `&trackingNumber=${trackingId}` : '';
  const clientReferenceIdQuery = clientReferenceId
    ? `&clientOrderReferenceId=${clientReferenceId}`
    : '';
  const businessOrderIdQuery = businessOrderId ? `&businessId=${businessOrderId}` : '';
  const dateRangeQuery = from ? `&from=${from}&to=${to}` : '';
  const orderStatusQuery = orderStatus ? `&status=${orderStatus}` : '';
  const deliverVendorQuery = selectedProvider ? `&carrierCode=${selectedProvider}` : '';

  try {
    const response = await httpClient.get(
      `/business-orders${queryParams}${orderIdQuery}${trackingIdQuery}${clientReferenceIdQuery}${businessOrderIdQuery}${dateRangeQuery}${orderStatusQuery}${deliverVendorQuery}`
    );
    setData(response?.data?.data);
  } catch (error: any) {
    setData({ count: 0, rows: [] });
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching B2B orders:', error);
  } finally {
    setLoading(false);
  }
};

export const getB2BOrderDetails = async ({
  setLoading,
  setData,
  id
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IB2BOrderDetails | undefined>>;
  id: string;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/business-orders/${id}`);
    setData(response?.data?.data?.order);
  } catch (error: any) {
    setData(undefined);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching B2B order details:', error);
  } finally {
    setLoading(false);
  }
};
