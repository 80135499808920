import { Dispatch, SetStateAction } from 'react';
import { IProvidersListResponse } from '../interfaces/b2bVerification';
import { httpClient } from '../utils/apiRequest';
import { ErrorMessage } from '../utils/ResponseMessages';

export const getProviders = async ({
  setLoading,
  setData,
  callbackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IProvidersListResponse[]>>;
  callbackFunc?: () => void;
}) => {
  setLoading(true);

  try {
    const response = await httpClient.get(`business/carriers`);
    setData(response.data?.data);
    if (callbackFunc) callbackFunc();
  } catch (error: any) {
    setData([]);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error in getting providers list ==> ', error);
  } finally {
    setLoading(false);
  }
};
