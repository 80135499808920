import { Button, Divider, Form, Input } from 'antd';
import React, { useState } from 'react';
import Logo from '../../../../../assets/c-logo.svg';
import Formlabel from '../../../../CommonComponents/FormLabel';
import PasswordChangeFields from '../PasswordChangeFields';

const ForgetPassword: React.FC = () => {
  const [getOtp, setGetOtp] = useState<boolean>(false);
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="primary-box-shadow w-[90%] max-w-[500px] p-8 rounded-xl flex flex-col">
        <div className="flex flex-col">
          <img className="mb-6 h-12 justify-self-center" src={Logo} alt="Logo" />
          <Form layout="vertical" requiredMark={false} onFinish={() => setGetOtp(!getOtp)}>
            <Form.Item
              className="mb-1"
              rules={[{ required: true, message: 'Please enter registered email id.' }]}
              name="registeredEmail"
              label={<Formlabel labelText="Registered Email Id" />}>
              <Input disabled={getOtp} placeholder="Email Id" type="text" />
            </Form.Item>

            {getOtp ? (
              <p className="my-3 text-green-400">Verification code sent successfully.</p>
            ) : (
              <>
                <p className="my-3 text-gray-400">
                  We will send a verification code to registered email id.
                </p>
                <Button
                  htmlType="submit"
                  className="!text-[#fff] mb-5"
                  style={{ background: 'rgba(3, 143, 222, 0.7)' }}>
                  Get Code
                </Button>
              </>
            )}
          </Form>
        </div>

        {getOtp ? (
          <div>
            <Divider />
            <Form layout="vertical" requiredMark={false}>
              <div className="mb-12 flex flex-col">
                <Form.Item
                  className="m-0"
                  rules={[{ required: true, message: 'Please enter verification code.' }]}
                  name="verificationCode"
                  label={<Formlabel labelText="Enter Verification Code" />}>
                  <Input placeholder="Verification Code" type="text" />
                </Form.Item>
                <button className="my-2 self-end">Resend code?</button>
              </div>

              <PasswordChangeFields />
            </Form>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ForgetPassword;
