import React, { FC } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { COMPANY_TYPES, CONTRACT_TYPES, formFieldValidations } from '../constants';
import { DatePicker, Form, Input, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

interface IBasicDetails {
  format: string;
  oneYearFromNow: dayjs.Dayjs;
}
const BasicDetails: FC<IBasicDetails> = ({ format, oneYearFromNow }) => {
  const disabledDate = (currentDate: Dayjs) => {
    return currentDate && currentDate < dayjs(oneYearFromNow).endOf('day');
  };

  return (
    <>
      <div className="flex flex-col md:flex-row md:gap-5 gap-0">
        <div className="w-full md:w-1/2">
          <Formlabel labelText="Brand name" required />
          <Form.Item
            className="mt-2"
            name="brandName"
            rules={[
              { required: true, message: formFieldValidations?.brandName?.required },
              {
                max: formFieldValidations?.brandName?.maxCharactersLength,
                message: formFieldValidations?.brandName?.maxCharactersMessage
              }
            ]}>
            <Input placeholder="Enter brand name" />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/2">
          <Formlabel labelText="Registered name" required />
          <Form.Item
            className="mt-2"
            name="registeredName"
            rules={[
              { required: true, message: formFieldValidations?.registeredName?.required },
              {
                max: formFieldValidations?.registeredName?.maxCharactersLength,
                message: formFieldValidations?.registeredName?.maxCharactersMessage
              }
            ]}>
            <Input placeholder="Enter registered name" />
          </Form.Item>
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:gap-5 gap-0">
        <div className="w-full md:w-1/2">
          <Formlabel labelText="Company Type" required />
          <Form.Item
            className="mt-2"
            name="companyType"
            rules={[{ required: true, message: formFieldValidations?.companyType?.required }]}>
            <Select allowClear placeholder="Select type" options={COMPANY_TYPES} />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/2">
          <Formlabel labelText="Contract Type" required />
          <Form.Item
            className="mt-2"
            name="contractType"
            rules={[{ required: true, message: formFieldValidations?.contractType?.required }]}>
            <Select allowClear placeholder="Select type" options={CONTRACT_TYPES} />
          </Form.Item>
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:gap-5 gap-0">
        <div className="w-full md:w-1/2">
          <Formlabel labelText="Business Category" required />
          <Form.Item
            className="mt-2"
            name="sector"
            rules={[{ required: true, message: formFieldValidations?.sector?.required }]}>
            <Input placeholder="Enter category" />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/2">
          <Formlabel labelText="Contract Expiry" required />
          <Form.Item
            className="mt-2 md:mb-0"
            name="contractExpiry"
            rules={[{ required: true, message: formFieldValidations?.contractExpiry?.required }]}>
            <DatePicker
              disabledDate={disabledDate}
              className="w-full"
              placeholder="Select Date"
              format={format}
              allowClear={false}
            />
          </Form.Item>
        </div>
      </div>

      <div className="w-full">
        <Formlabel labelText="Website" />
        <Form.Item
          name="website"
          rules={[
            {
              pattern: formFieldValidations?.website?.patternValueRegex,
              message: formFieldValidations?.website?.patternMessage
            }
          ]}>
          <Input placeholder="Enter website" />
        </Form.Item>
      </div>

      <div className="w-full">
        <Formlabel labelText="Former Business Email" />
        <p className="m-0 select-none font-medium text-blue-500">
          * Must be existing business Email
        </p>
        <Form.Item
          className="mt-2"
          rules={[
            {
              pattern: formFieldValidations?.formerBusinessEmail?.patternValueRegex,
              message: formFieldValidations?.formerBusinessEmail?.patternMessage
            }
          ]}
          name="formerBusinessEmail">
          <Input placeholder="Enter email" />
        </Form.Item>
      </div>
    </>
  );
};

export default BasicDetails;
