import { useState } from 'react';
import { showMessage } from '../../../../utils/ResponseMessages';
import { httpClient } from '../../../../utils/apiRequest';
import UserMenu from '../../ManageMenu/api';

const MenuData = () => {
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { getUsersMenuList, getUsersMenu } = UserMenu();

  const fetchStart = () => {
    setLoading(true);
    setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error: string) => {
    setLoading(false);
    setError(error);
  };

  const createMenu = async ({
    payload,
    callbackFun
  }: {
    payload: any;
    callbackFun?: () => void;
  }): Promise<any> => {
    fetchStart();
    try {
      const response = await httpClient.post(`menu/create`, payload);
      getUsersMenuList();

      fetchSuccess();
      showMessage(
        response?.data?.data?.data?.menuId
          ? `${response?.data?.data?.data?.menuId} created successfully`
          : response?.data?.message,
        response?.status
      );
      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const deleteMenu = async ({
    id,
    callbackFun
  }: {
    id?: number;
    callbackFun?: () => void;
  }): Promise<any> => {
    fetchStart();
    try {
      const response = await httpClient.delete(`menu/${id}`);
      fetchSuccess();

      showMessage(
        response?.data?.data?.data?.menuId
          ? `${response?.data?.data?.data?.menuId} deleted successfully`
          : response?.data?.message,
        response?.status
      );

      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const updateMenuData = async ({
    id,
    payload,
    callbackFun
  }: {
    id?: number;
    payload: any;
    callbackFun?: () => void;
  }): Promise<any> => {
    fetchStart();
    try {
      const response = await httpClient.put(`menu/${id}`, payload);
      getUsersMenuList();

      fetchSuccess();
      showMessage(
        response?.data?.data?.data?.menuId
          ? `${response?.data?.data?.data?.menuId} Updated successfully`
          : response?.data?.message,
        response?.status
      );
      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };
  return {
    error,
    isLoading,
    createMenu,
    deleteMenu,
    updateMenuData
  };
};
export default MenuData;
