import { Dispatch, SetStateAction } from 'react';
import {
  ICompanyDetailsData,
  ICompanyListResponseData,
  IProvidersListResponse,
  IVerifyDetailsPayload
} from '../../../interfaces/b2bVerification';
import { httpClient } from '../../../utils/apiRequest';
import { ErrorMessage, SuccessMessage } from '../../../utils/ResponseMessages';
import { IMarginPayload } from '../../../interfaces/b2bMargin';

export const getCompanyList = async ({
  setData,
  setLoading,
  status,
  pageLimit,
  skip
}: {
  setData: Dispatch<SetStateAction<ICompanyListResponseData>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  status: string;
  pageLimit: number;
  skip: number;
}) => {
  setLoading(true);

  const offSet = (skip - 1) * pageLimit;
  try {
    const response = await httpClient.get(`business/${status}?offset=${offSet}&limit=${pageLimit}`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData({ companysCount: 0, companysList: [] });
    ErrorMessage(error?.response?.data?.message);
    console.error('Error in getting companies list ==> ', error);
  } finally {
    setLoading(false);
  }
};

export const getCompanyDetails = async ({
  setData,
  setLoading,
  businessId
}: {
  setData: Dispatch<SetStateAction<ICompanyDetailsData | unknown>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  businessId: string;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`business/details/${businessId}`);

    setData(response?.data?.data ? response?.data?.date : response?.data?.date);
  } catch (error: any) {
    setData({});
    ErrorMessage(error?.response?.data?.message);
    console.error('Error in getting companies list ==> ', error);
  } finally {
    setLoading(false);
  }
};

export const verifyDetails = async ({
  setLoading,
  payload,
  callbackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IVerifyDetailsPayload;
  callbackFunc?: () => void;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.post(`/business/verify-account`, payload);
    SuccessMessage(response?.data?.message);
    if (callbackFunc) callbackFunc();
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    console.error('Error in verifying company details ==> ', error);
  } finally {
    setLoading(false);
  }
};

export const setUpMargin = async ({
  setLoading,
  payload,
  callbackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IMarginPayload;
  callbackFunc?: () => void;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.post('business/setup-margin', payload);
    SuccessMessage(response?.data?.message);
    if (callbackFunc) callbackFunc();
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    console.error('Error in getting providers list ==> ', error);
  } finally {
    setLoading(false);
  }
};
