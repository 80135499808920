import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Space } from 'antd';
import UserMenu from '../../ManageMenu/api';
import { UserFormDetails } from '../interfaces/UserData';

interface UserFormProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (payload: UserFormDetails) => void;
  action: string;
  onUpdate: any;
  initialValues?: UserFormDetails[];
}

const UserForm: React.FC<UserFormProps> = ({
  isVisible,
  onClose,
  onSubmit,
  action,
  onUpdate,
  initialValues
}) => {
  if (!isVisible) return null;

  const [form] = Form.useForm();
  const { getUsersList, getParamList, getParam } = UserMenu();
  const [departmentOptions, setDepartmentOptions] = useState<string[]>([]);
  const [roleOptions, setRoleOptions] = useState<string[]>([]);
  useEffect(() => {
    getUsersList();
    getParam();
  }, []);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  useEffect(() => {
    if (getParamList?.paramList) {
      const uniqueDepartments = Array.from(
        new Set(getParamList?.paramList.map((param: any) => param.paramGroup))
      );
      setDepartmentOptions(uniqueDepartments);
    }
  }, [getParamList]);

  const handleDepartmentChange = (selectedDepartment: string) => {
    const selectedDepartmentData: any = getParamList.paramList.filter(
      (param: any) => param.paramGroup === selectedDepartment
    );

    if (selectedDepartmentData) {
      const uniqueRoles: string[] = Array.from(
        new Set(selectedDepartmentData?.map((param: any) => param.paramValue))
      );
      setRoleOptions(uniqueRoles);
      form.setFieldsValue({ role: selectedDepartmentData.paramValue });
    }
  };

  const handleUsersData = async (values: any) => {
    const payload = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      mobile: parseInt(values.mobile, 10),
      role: values.role,
      rights: values.rights,
      department: values.department,
      reportingTo: values.reportingTo,
      preferredLanguage: values.preferredLanguage,
      status: values.status
    };
    if (action === 'Update') {
      onUpdate(payload);
    } else {
      onSubmit(payload);
    }
  };

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal footer={false} open={isVisible} title="Add User" onCancel={handleOnClose}>
      <div className="py-2">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={(values) => {
            handleUsersData(values);
          }}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please input your Name' }]}>
            <Input placeholder="First Name"></Input>
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: 'Please input your Name' },
              { whitespace: true },
              { min: 3 }
            ]}
            hasFeedback>
            <Input placeholder="Last Name"></Input>
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please input your Name' },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
            hasFeedback>
            <Input placeholder="abc@gmail.com"></Input>
          </Form.Item>
          <Form.Item label="Mobile No" name="mobile">
            <Input placeholder="0123456789"></Input>
          </Form.Item>
          <Form.Item label="Department" name="department">
            <Select
              placeholder="Select department"
              onChange={handleDepartmentChange}
              options={[
                { value: '', label: 'Select a Department', disabled: true }, // Default option
                ...departmentOptions.map((dept) => ({ value: dept, label: dept }))
              ]}
            />
          </Form.Item>
          <Form.Item label="Rights" name="rights">
            <Select placeholder="Select rights" mode="multiple">
              <Select.Option value="read">Read</Select.Option>
              <Select.Option value="write">Write</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                type: 'array',
                required: true,
                message: 'Please select at least one role!'
              }
            ]}>
            <Select
              placeholder="Select role"
              options={[
                { value: '', label: 'Select a Role', disabled: true }, // Default option
                ...roleOptions.map((role) => ({ value: role, label: role }))
              ]}
              mode="multiple"
            />
          </Form.Item>

          <Form.Item label="Reporting To" name="reportingTo">
            <Input placeholder="reporting to"></Input>
          </Form.Item>
          <Form.Item label="Preferred Language" name="preferredLanguage">
            <Input placeholder="Preferred Language"></Input>
          </Form.Item>
          <Form.Item label="Status" name="status">
            <Input placeholder="status"></Input>
          </Form.Item>

          <div className="flex justify-end">
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  {action}
                </Button>
                <Button htmlType="reset">Cancel</Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UserForm;
