import React, { FC, useEffect, useState } from 'react';
import { IB2BOrderAddress, IB2BOrderDetails } from '../../../../../interfaces/b2bV3OrdersList';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

interface IAddressDetails {
  detailsData: IB2BOrderDetails | undefined;
}

const AddressDetails: FC<IAddressDetails> = ({ detailsData }) => {
  const [addressData, setAddressData] = useState<IB2BOrderAddress[]>([]);
  useEffect(() => {
    if (detailsData) {
      setAddressData(detailsData.addresses);
    }
  }, [detailsData]);

  const addressDetailsColumns: ColumnsType<IB2BOrderAddress> = [
    {
      title: ' ',
      dataIndex: 'savedAs',
      key: 'savedAs',
      fixed: 'left',
      width: 150,
      render: (text) => (text ? <p className="m-0 font-semibold">{text}</p> : '-')
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'Address line 1',
      dataIndex: 'line1',
      key: 'line1',
      width: 200,
      render: (text) => text || 'NA'
    },
    {
      title: 'Address line 2',
      dataIndex: 'line2',
      key: 'line2',
      width: 200,
      render: (text) => text || 'NA'
    },
    {
      title: 'Address line 3',
      dataIndex: 'line3',
      key: 'line3',
      width: 200,
      render: (text) => text || 'NA'
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'State Code',
      dataIndex: 'stateCode',
      key: 'stateCode',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'Country Code',
      dataIndex: 'countryCode',
      key: 'countryCode',
      width: 150,
      render: (text) => text || 'NA'
    },
    {
      title: 'Postal Code',
      dataIndex: 'postalCode',
      key: 'postalCode',
      width: 150,
      render: (text) => text || 'NA'
    },
    {
      title: 'Geo Location',
      dataIndex: 'geoLocation',
      key: 'geoLocation',
      width: 150,
      render: (text) => text || 'NA'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      render: (text) => text || 'NA'
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobileNo',
      key: 'mobileNo',
      width: 150,
      render: (text) => text || 'NA'
    },
    {
      title: 'Alternate Phone No',
      dataIndex: 'alternatePhoneNo',
      key: 'alternatePhoneNo',
      width: 150,
      render: (text) => text || 'NA'
    },

    {
      title: 'ISD Code',
      dataIndex: 'isdCode',
      key: 'isdCode',
      width: 150,
      render: (text) => text || 'NA'
    }
  ];
  return (
    <div>
      <h1 className="font-semibold text-xl mb-4">Address Details</h1>

      <Table
        columns={addressDetailsColumns}
        dataSource={addressData}
        pagination={false}
        scroll={{
          x: 2500
        }}
        rowKey={'savedAs'}
      />
    </div>
  );
};

export default AddressDetails;
