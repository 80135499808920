import { Button } from 'antd';
import React, { FC } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface INavigateButton {
  id: string;
}
const NavigateButton: FC<INavigateButton> = ({ id }) => {
  const navigate = useNavigate();

  const onClickDetails = () => {
    navigate(`/b2b-company-details/${id}`);
  };

  return <Button icon={<FaExternalLinkAlt />} onClick={onClickDetails} />;
};

export default NavigateButton;
