import React, { FC } from 'react';
import Formlabel from '../../../CommonComponents/FormLabel';
import { Form, Input } from 'antd';
import { addressFormValidations } from './constants';

interface IAddressFields {
  line_1_fieldName: string;
  line_2_fieldName: string;
  locality_fieldName: string;
  city_fieldName: string;
  state_fieldName: string;
  mobile_fieldName: string;
  postalCode_fieldName: string;
  isdCode_fieldName: string;
}

const AddressFields: FC<IAddressFields> = ({
  line_1_fieldName,
  line_2_fieldName,
  locality_fieldName,
  city_fieldName,
  postalCode_fieldName,
  state_fieldName,
  mobile_fieldName,
  isdCode_fieldName
}) => {
  return (
    <div className="my-3 border p-3 rounded-xl">
      <div className="w-full">
        <Formlabel labelText="Address Line 1" required />
        <Form.Item
          className="mt-2"
          rules={[
            { required: true, message: addressFormValidations?.address?.required },
            {
              max: addressFormValidations?.address?.maxCharactersLength,
              message: addressFormValidations?.address?.maxCharactersMessage
            },
            {
              pattern: addressFormValidations?.address?.patternValueRegex,
              message: addressFormValidations?.address?.patternMessage
            }
          ]}
          name={line_1_fieldName}>
          <Input placeholder="Enter address" />
        </Form.Item>
      </div>

      <div className="w-full">
        <Formlabel labelText="Address Line 2" required />
        <Form.Item
          className="mt-2"
          rules={[
            { required: true, message: addressFormValidations?.address?.required },
            {
              max: addressFormValidations?.address?.maxCharactersLength,
              message: addressFormValidations?.address?.maxCharactersMessage
            },
            {
              pattern: addressFormValidations?.address?.patternValueRegex,
              message: addressFormValidations?.address?.patternMessage
            }
          ]}
          name={line_2_fieldName}>
          <Input placeholder="Enter address" />
        </Form.Item>
      </div>

      <div className="flex flex-col md:flex-row md:gap-5 gap-0">
        <div className="w-full md:w-1/2">
          <Formlabel labelText="Locality" required />
          <Form.Item
            className="mt-2"
            rules={[{ required: true, message: addressFormValidations?.locality?.required }]}
            name={locality_fieldName}>
            <Input placeholder="Enter locality" />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/2">
          <Formlabel labelText="Postal Code" required />
          <Form.Item
            className="mt-2"
            rules={[
              { required: true, message: addressFormValidations?.postalCode?.required },
              {
                pattern: addressFormValidations?.postalCode?.patternValueRegex,
                message: addressFormValidations?.postalCode?.patternMessage
              }
            ]}
            name={postalCode_fieldName}>
            <Input placeholder="Enter postal code" />
          </Form.Item>
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:gap-5 gap-0">
        <div className="w-full md:w-1/2">
          <Formlabel labelText="ISD Code" required />
          <Form.Item
            className="mt-2"
            rules={[{ required: true, message: addressFormValidations?.isdCode?.required }]}
            name={isdCode_fieldName}>
            <Input placeholder="Enter code" />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/2">
          <Formlabel labelText="Mobile Number" required />
          <Form.Item
            className="mt-2"
            rules={[
              { required: true, message: addressFormValidations?.mobileNumber?.required },
              {
                pattern: addressFormValidations?.mobileNumber?.patternValueRegex,
                message: addressFormValidations?.mobileNumber?.patternMessage
              }
            ]}
            name={mobile_fieldName}>
            <Input
              type="number"
              onKeyDown={(e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()}
              placeholder="Enter number"
            />
          </Form.Item>
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:gap-5 gap-0">
        <div className="w-full md:w-1/2">
          <Formlabel labelText="City" required />
          <Form.Item
            className="mt-2"
            rules={[{ required: true, message: addressFormValidations?.cityFieldName?.required }]}
            name={city_fieldName}>
            <Input placeholder="Enter city" />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/2">
          <Formlabel labelText="State" required />
          <Form.Item
            className="mt-2"
            rules={[{ required: true, message: addressFormValidations?.stateFieldName?.required }]}
            name={state_fieldName}>
            <Input type="text" placeholder="Enter state" />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default AddressFields;
