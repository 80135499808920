import React, { useEffect, useState } from 'react';
import { IPincodeLogs, IPincodeLogsTableData } from '../../../../interfaces/handlePincode';
import { getPincodeLogs } from '../api';
import { Button, Form, PaginationProps, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { PROVIDER_NAMES } from '../../../../utils/constant';
import { SearchOutlined } from '@ant-design/icons';
import { GetDropDownFilters } from './helpers';
import { SERVICE_TYPE_ENUMS, SYNCHRONIZATION_ENUMS } from '../utils/providers';
import GetStatus from '../../../../utils/GetStatus';
import { ArrayToExcelCsv } from '../../../../utils/Excel-Conversion/ArrayToExcelCsv';

type ProviderOption = {
  value: string;
  label: string;
};

const PincodesLogs = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pincodesLogsData, setPincodesLogsData] = useState<IPincodeLogs>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [serviceProviderForm] = Form.useForm();
  const [serviceTypeForm] = Form.useForm();
  const [syncValueForm] = Form.useForm();
  const [serviceProvider, setServiceProvider] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [syncValue, setSyncValue] = useState('');

  const handleGetLogs = () => {
    getPincodeLogs({
      setLoading: setIsLoading,
      setData: setPincodesLogsData,
      currentPage,
      pageLimit,
      serviceProvider: serviceProvider,
      serviceType: serviceType,
      synchronization: syncValue
    });
  };

  useEffect(() => {
    handleGetLogs();
  }, [currentPage, pageLimit, serviceProvider, serviceType, syncValue]);

  const handleProviderSearch = ({ serviceProvider }: { serviceProvider: string }) => {
    setServiceProvider(serviceProvider);
  };
  const handleProviderReset = () => {
    setServiceProvider('');
    serviceProviderForm.resetFields();
  };

  const handleTypeSearch = ({ serviceType: type }: { serviceType: string }) => {
    setServiceType(type);
  };
  const handleTypeReset = () => {
    setServiceType('');
    serviceProviderForm.resetFields();
  };

  const handleSyncSearch = ({ synchronization }: { synchronization: string }) => {
    setSyncValue(synchronization);
  };
  const handleSyncReset = () => {
    setSyncValue('');
    syncValueForm.resetFields();
  };

  const PROVIDER_NAMES_OPTIONS: ProviderOption[] = Object.values(PROVIDER_NAMES).map((value) => ({
    value,
    label: value
  }));

  const columns: ColumnsType<IPincodeLogsTableData> = [
    {
      title: 'Operator Name',
      dataIndex: 'operatorName',
      width: 200,
      render: (name) => <p className="capitalize">{name}</p>
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 200,
      render: (text) => <span>{moment(text).format('DD-MM-YYYY, HH:MM:SS')}</span>
    },
    {
      title: 'Blocked By',
      dataIndex: 'blockedBy',
      width: 200,
      render: (name) => <p className="capitalize">{name}</p>
    },
    {
      dataIndex: 'provider',
      width: 200,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      ...GetDropDownFilters({
        formInstance: serviceProviderForm,
        formId: 'serviceProviderForm',
        onFinish: handleProviderSearch,
        title: 'Provider',
        stateValue: serviceProvider,
        options: PROVIDER_NAMES_OPTIONS,
        handleReset: handleProviderReset,
        searchFieldName: 'serviceProvider',
        placeholder: 'Select Provider'
      })
    },
    { title: 'Reason', dataIndex: 'reason', width: 180 },
    {
      dataIndex: 'serviceType',
      width: 150,
      render: (type) => <p className="capitalize">{type}</p>,
      ...GetDropDownFilters({
        formInstance: serviceTypeForm,
        formId: 'serviceTypeForm',
        onFinish: handleTypeSearch,
        title: 'Service Type',
        stateValue: serviceType,
        options: SERVICE_TYPE_ENUMS,
        handleReset: handleTypeReset,
        searchFieldName: 'serviceType',
        placeholder: 'Select Type'
      })
    },
    {
      dataIndex: 'synchronization',
      width: 200,
      render: (type) => (
        <GetStatus currStatus={type} SUCCESS="Unblocked" FAILED="Blocked" CUSTOM_LABEL={type} />
      ),
      ...GetDropDownFilters({
        formInstance: syncValueForm,
        formId: 'syncValueForm',
        onFinish: handleSyncSearch,
        title: 'Synchronization',
        stateValue: syncValue,
        options: SYNCHRONIZATION_ENUMS,
        handleReset: handleSyncReset,
        searchFieldName: 'synchronization',
        placeholder: 'Select Synchronization'
      })
    },
    {
      title: 'Pincodes',
      dataIndex: 'pincode',
      width: 250,
      render: (pincodes, data) => (
        <div className="flex flex-col gap-2">
          <p>
            <b>{pincodes?.length}</b> Pincodes
          </p>

          {!!pincodes?.length && (
            <Button
              className="primary-button"
              onClick={() =>
                ArrayToExcelCsv({ data: pincodes, filename: `${data?.operatorName}` })
              }>
              Download CSV
            </Button>
          )}
        </div>
      )
    }
  ];

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  return (
    <div>
      <h2 className="text-xl font-normal m-0 mb-5">
        Pincodes Logs {pincodesLogsData?.totalCount && `( ${pincodesLogsData?.totalCount} )`}
      </h2>

      <Table
        columns={columns}
        loading={isLoading}
        rowKey={'orderId'}
        scroll={{ x: 1600 }}
        pagination={{
          responsive: true,
          showQuickJumper: true,
          current: currentPage,
          pageSize: pageLimit,
          pageSizeOptions: [20, 30, 50],
          total: pincodesLogsData?.totalPage ? pincodesLogsData?.totalPage * pageLimit : 0,
          onChange: onPageChange
        }}
        dataSource={pincodesLogsData?.logs}
      />
    </div>
  );
};

export default PincodesLogs;
