// eslint-disable-next-line
var XLSX = require('xlsx');

interface ExcelToJsonProps {
  dataFile: any;
}

export const ExcelToJson = ({ dataFile }: ExcelToJsonProps): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(dataFile);

    reader.onload = (event) => {
      const uploadedData = XLSX.read(event?.target?.result, {
        type: 'array'
      })?.Sheets;

      const sheetName = Object.keys(uploadedData)[0];
      const sheet = uploadedData[sheetName];
      const result: { [key: string]: any }[] = [];

      const cells = Object.keys(sheet);

      cells.forEach((cell) => {
        // If cell contains a hyperlink, overwrite the raw value with the hyperlink
        if (sheet[cell].l && sheet[cell].l.Target) {
          sheet[cell].v = sheet[cell].l.Target;
        }
        if (typeof sheet[cell].v === 'string') {
          sheet[cell].v = sheet[cell].v
            .toString()
            .replace(/(\r\n|\n|\r)/g, '')
            ?.trim();
        }
      });
      const data: any = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        raw: true,
        blankrows: false
      });
      const keys = data[0];
      for (let i = 1; i < data.length; i++) {
        const obj: { [key: string]: any } = {};
        for (let j = 0; j < keys.length; j++) {
          obj[keys[j]] = data[i][j];
        }
        result.push(obj);
      }
      resolve(result);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};
