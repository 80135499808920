import { CLOUDINARY_URL } from './config';
import { PROVIDER_NAMES } from './constant';

export const GetProviderLogo = (providerName: string) => {
  const imageUrl = `${CLOUDINARY_URL}/provider-logos`;
  switch (providerName) {
    case PROVIDER_NAMES?.FEDEX:
      return `${imageUrl}/Fedex-Express.svg`;
    case PROVIDER_NAMES?.ECOM:
      return `${imageUrl}/PARTNERBRAND_LOGOS__EcomExpress.svg`;
    case PROVIDER_NAMES?.INDIA_POST:
      return `${imageUrl}/PARTNERBRAND_LOGOS__IndiaPost.svg`;
    case PROVIDER_NAMES?.SHREEMARUTI:
      return `${imageUrl}/PARTNERBRAND_LOGOS_MarutiCourierServices_New.svg`;
    // case PROVIDER_NAMES?.SHREEMARUTI2:
    //   return `${imageUrl}/PARTNERBRAND_LOGOS_MarutiCourierServices_New.svg`;
    case PROVIDER_NAMES?.DELHIVERY:
      return `${imageUrl}/PARTNERBRAND_LOGOS__Delhivery.svg`;
    case PROVIDER_NAMES?.CRITICALOG:
      return `${imageUrl}/PARTNERBRAND_LOGOS_CriticaLog.svg`;
    case PROVIDER_NAMES?.SHADOWFAX:
      return `${imageUrl}/PARTNERBRAND_LOGOS__Shadowfax.svg`;
    case PROVIDER_NAMES?.XPRESSBEES:
      return `${imageUrl}/PARTNERBRAND_LOGOS_Xpressbees.svg`;
    case PROVIDER_NAMES?.QWQER:
      return `${imageUrl}/PARTNERBRAND_LOGOS_qwqer.svg`;
    case PROVIDER_NAMES?.SELF_DROP:
      return `${imageUrl}/SelfDrop.svg`;
    case PROVIDER_NAMES?.AMAZON:
      return `${imageUrl}/PARTNERBRAND_LOGOS_Amazon.svg`;
    case PROVIDER_NAMES?.DUNZO:
      return `${imageUrl}/PARTNERBRAND_LOGOS_Dunzo.svg`;
    case PROVIDER_NAMES?.DELHIVERY_INTERNATIONAL:
      return `${imageUrl}/PARTNERBRAND_LOGOS__Delhivery.svg`;
    case PROVIDER_NAMES?.GATI:
      return `${imageUrl}/PARTNERBRAND_LOGOS_Gati.svg`;
    case PROVIDER_NAMES?.DTDC_INTERNATIONAL:
    case PROVIDER_NAMES?.DTDC:
      return `${imageUrl}/PARTNERBRAND_LOGOS_DTDC.svg`;
    case PROVIDER_NAMES?.BLUEDART:
      return `${imageUrl}/PARTNERBRAND_LOGOS_Bluedart.svg`;
    case PROVIDER_NAMES?.SMARTR:
      return `${imageUrl}/PARTNERBRAND_LOGOS__Smartr.svg`;
    case PROVIDER_NAMES?.MOVIN:
      return `${imageUrl}/PARTNERBRAND_LOGOS_movin.svg`;
    case PROVIDER_NAMES.EKART:
      return `${imageUrl}/PARTNERBRAND_LOGOS_Ekart.svg`;
    default:
      return `${imageUrl}/PARTNERBRAND_LOGOS_Default.svg`;
  }
};
