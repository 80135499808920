import { Dispatch, SetStateAction } from 'react';
import { IDiscrepancyListResponse } from '../../../../interfaces/discrepancy';
import { httpClient } from '../../../../utils/apiRequest';
import { showMessage } from '../../../../utils/ResponseMessages';

export const getDiscrepancyList = async ({
  setLoading,
  setData,
  currentPage = 1,
  pageLimit = 20
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IDiscrepancyListResponse | undefined>>;
  currentPage: number;
  pageLimit: number;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(
      `/weight-discrepancy/list?page=${currentPage}&limit=${pageLimit}`
    );
    setData(response?.data?.data?.data);
  } catch (error: any) {
    console.error('Error in getting discrepancy list', error);
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};
