import { Button, Form, Input } from 'antd';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { IEmailVerifiedData } from '../../../../../interfaces/bulkOrders';
import { EMAIL_REGEX } from '../../../../../utils/constant';
import { verifyUserEmail } from '../../api';
import { FaCheck } from 'react-icons/fa';

interface IEmailVerificationForm {
  isValidEmail: boolean;
  setIsValidEmail: Dispatch<SetStateAction<boolean>>;
  setEmailVerifiedData: Dispatch<SetStateAction<IEmailVerifiedData | undefined>>;
}

interface IVerificationForm {
  email: string;
}
const EmailVerificationForm: FC<IEmailVerificationForm> = ({
  isValidEmail,
  setIsValidEmail,
  setEmailVerifiedData
}) => {
  const [emailForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleVerifyEmail = (value: IVerificationForm) => {
    verifyUserEmail({
      setLoading: setIsLoading,
      setVerified: setIsValidEmail,
      email: value?.email,
      setUserData: setEmailVerifiedData
    });
  };

  return (
    <div>
      <div className="flex gap-3 items-end mb-4">
        <Form
          form={emailForm}
          layout="vertical"
          id="shipmentLabelForm"
          onFinish={handleVerifyEmail}>
          <div className="flex gap-3 items-center">
            <Formlabel labelText="Enter Email :" />
            {isValidEmail && (
              <span className="text-green-500 flex items-center gap-2">
                <FaCheck /> Verified
              </span>
            )}
          </div>
          <div className="flex gap-2 mt-2">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please enter email id.'
                },
                {
                  pattern: EMAIL_REGEX?.pattern,
                  message: EMAIL_REGEX?.message
                }
              ]}
              name="email"
              className="mb-0">
              <Input disabled={isValidEmail} placeholder="Email Id" />
            </Form.Item>
            <Button
              disabled={isValidEmail}
              loading={isLoading}
              className="primary-button outline-none border-none"
              htmlType="submit"
              form="shipmentLabelForm">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EmailVerificationForm;
