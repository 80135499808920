import { Dispatch, SetStateAction } from 'react';
import { showMessage } from '../../../../utils/ResponseMessages';
import { httpClient } from '../../../../utils/apiRequest';

export const GetBusinessOrdersList = async ({
  setDataState,
  setLoading,
  pageNumber = 1,
  pageLimit = 20,
  orderId,
  from,
  to,
  awbId
}: {
  setDataState: Dispatch<SetStateAction<any>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  pageNumber?: number;
  pageLimit?: number;
  orderId?: string;
  from?: string;
  to?: string;
  awbId?: string;
}) => {
  setLoading(true);
  let payload = {} as {
    skip: number;
    limit: number;
    pageNumber?: number;
    pageLimit?: number;
    orderId?: string;
    from?: string;
    to?: string;
    awbNo?: string;
  };

  if (pageLimit) {
    payload = {
      ...payload,
      skip: orderId || awbId ? 0 : pageNumber - 1, // subtract by 1, As per api requirements, page number start from 0.
      limit: pageLimit
    };
  }
  if (orderId) {
    payload = { ...payload, orderId };
  }
  if (from) {
    payload = { ...payload, from };
  }
  if (to) {
    payload = { ...payload, to };
  }
  if (awbId) {
    payload = { ...payload, awbNo: awbId };
  }
  try {
    const response = await httpClient.post(`/order/business-orders-list`, payload);
    setLoading(false);
    setDataState(response?.data?.data);
    return response?.data?.data;
  } catch (error: any) {
    setDataState([]);
    setLoading(false);
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};
