import React from 'react';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import UserMenu from '../api/index';
import UserMenuTable from '../UserMenuTable';

const MapUserMenu: FC = () => {
  const [categories, setCategories] = useState<string[]>([]);
  const [subcategories, setSubcategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>('');

  const { getUsersData, error, isLoading, getUsersList, getParam, getParamList } = UserMenu();

  useEffect(() => {
    getUsersList();
    getParam();
  }, []);
  useEffect(() => {
    if (getUsersData.data && getUsersData.data.length > 0) {
      const uniqueCategories = Array.from(
        new Set(getUsersData.data.map((item: { department: string }) => item.department))
      );
      setCategories(uniqueCategories);
    }
  }, [getUsersData]);

  useEffect(() => {
    let UsersRole: string[] = [];
    const role = getUsersData?.data;
    if (role) {
      const filteredUsers = role.filter(
        (user: { department: string }) => user?.department === selectedCategory
      );
      filteredUsers.forEach((data: { role: string[] }) => {
        if (data?.role) {
          UsersRole = [...UsersRole, ...data.role];
        }
      });

      const uniqueSubcategories = Array.from(new Set(UsersRole));
      setSubcategories(uniqueSubcategories);
    }
  }, [selectedCategory]);

  function updateSelectedCategory(e: ChangeEvent<HTMLSelectElement>) {
    setSelectedCategory(e.target.value);
    setSelectedSubcategory('');
  }

  return (
    <>
      <div className="flex  text-lg mb-5 gap-5">
        <label>Select Category</label>
        <select value={selectedCategory} onChange={(e) => updateSelectedCategory(e)}>
          <option value="">Select a Category</option>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>

        <label>Select Subcategory</label>
        <select
          value={selectedSubcategory}
          onChange={(e) => setSelectedSubcategory(e.target.value)}
          disabled={!selectedCategory} // Disable the subcategory dropdown if no category is selected
        >
          <option value="">Select a Subcategory</option>
          {subcategories.map((subcategory) => (
            <option key={subcategory} value={subcategory}>
              {subcategory}
            </option>
          ))}
        </select>
      </div>

      {selectedSubcategory != '' ? (
        <UserMenuTable department={selectedCategory} role={selectedSubcategory} />
      ) : null}
    </>
  );
};
export default MapUserMenu;
