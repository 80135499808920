import { useState } from 'react';
import { showMessage } from '../../../../utils/ResponseMessages';
import { httpClient } from '../../../../utils/apiRequest';
import { MenuItems } from '../../../../interfaces/menuData';

const UserMenu = () => {
  interface MenuState {
    MenuList: MenuItems[];
  }

  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [getUsersData, setGetUsersData] = useState({
    data: []
  });
  const [getUsersMenu, setGetUsersMenu] = useState<any>({
    MenuList: []
  });
  const [getMappedMenu, setGetMappedMenu] = useState<any>({
    MappedList: []
  });

  const [getParamList, setGetParamList] = useState({
    paramList: []
  });

  const fetchStart = () => {
    setLoading(true);
    setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error: string) => {
    setLoading(false);
    setError(error);
  };

  const getUsersList = async () => {
    try {
      // Start fetching
      fetchStart();

      const response = await httpClient.get('/internal-user/list?from=2023-09-01&department=ALL');

      setGetUsersData({
        data: response?.data?.data?.data?.data || [] // Set to an empty array if data is not available
      });
      fetchSuccess();

      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const getUsersMenuList = async () => {
    try {
      fetchStart();

      const response = await httpClient.get('menu/list');
      fetchSuccess();

      setGetUsersMenu({
        MenuList: response?.data?.data?.data?.data || [] // Set to an empty array if data is not available
      });
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const menuMap = async ({
    callbackFun,
    payload
  }: {
    payload: any;
    callbackFun?: () => void;
  }): Promise<any> => {
    fetchStart();
    try {
      const response = await httpClient.post(`/menu-map/create`, payload);
      fetchSuccess();
      showMessage(
        response?.data?.data?.data?.menuId
          ? `${response?.data?.data?.data?.menuId} created successfully`
          : response?.data?.message,
        response?.status
      );

      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const getMenuMappedData = async ({
    department,
    role
  }: {
    callbackFun?: () => void;
    department?: string;
    role?: string;
  }) => {
    fetchStart();
    try {
      const response = await httpClient.get(`menu-map/list?department=${department}&role=${role}`);
      fetchSuccess();
      setGetMappedMenu({
        MappedList: response?.data?.data?.data?.data || [] // Set to an empty array if data is not available
      });
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const deleteMenu = async ({
    callbackFun,
    id
  }: {
    id?: number;
    callbackFun?: () => void;
  }): Promise<any> => {
    fetchStart();
    try {
      const response = await httpClient.delete(`menu-map/${id}`);
      fetchSuccess();

      showMessage(
        response?.data?.data?.data?.menuId
          ? `${response?.data?.data?.data?.menuId} deleted successfully`
          : response?.data?.message,
        response?.status
      );

      if (callbackFun) callbackFun();
      return response;
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  const getParam = async () => {
    try {
      fetchStart();
      const response = await httpClient.get('param/list');
      // Fetch success
      fetchSuccess();

      setGetParamList({
        paramList: response?.data?.data?.data?.data || [] // Set to an empty array if data is not available
      });
    } catch (error: any) {
      showMessage(error?.response?.data?.message, error?.response?.status);
      fetchError(error?.message);
    }
  };

  return {
    menuMap,
    getUsersData,
    error,
    isLoading,
    getUsersList,
    getUsersMenuList,
    getUsersMenu,
    getMenuMappedData,
    getMappedMenu,
    deleteMenu,
    getParam,
    getParamList
  };
};

export default UserMenu;
