import React, { FC, useState } from 'react';
import { Button } from 'antd';
import { createJiraTicket } from './api';

interface ICreateJiraTicket {
  orderId: string;
}
const CreateJiraTicket: FC<ICreateJiraTicket> = ({ orderId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleCreateTicket = () => {
    createJiraTicket({ orderId: orderId, setIsLoading: setIsLoading });
  };

  return (
    <div className="flex items-center">
      <p className="w-44 font-semibold text-xl">Tickets</p>
      <p className="pr-2">:</p>
      <span>
        <Button
          loading={isLoading}
          target="_blank"
          className="primary-button"
          onClick={handleCreateTicket}>
          Create Ticket
        </Button>
      </span>
    </div>
  );
};

export default CreateJiraTicket;
