import axios from 'axios';
import { API_URL } from './config';

export const httpClient = axios.create({
  baseURL: API_URL, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});
