import { Button, Form, FormInstance, Select } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import YellowCircle from '../../../../../CommonComponents/YellowCircle';

interface IGetOrderByStatus {
  orderStatusForm: FormInstance;
  orderStatus: string;
  setOrderStatus: Dispatch<SetStateAction<string>>;
  title?: string;
}

const GetOrderByStatus = ({
  orderStatusForm,
  orderStatus,
  setOrderStatus,
  title = 'Status'
}: IGetOrderByStatus) => {
  const handleStatusSearch = ({ orderStatus }: { orderStatus: string }) => {
    setOrderStatus(orderStatus);
  };

  const handleStatusReset = () => {
    setOrderStatus('');
    orderStatusForm.resetFields();
  };

  const orderStatusOptions = [
    { value: 'DRAFT', label: 'DRAFT' },
    { value: 'READY_TO_BOOK', label: 'READY TO BOOK' },
    { value: 'BOOKING_FAILED', label: 'BOOKING FAILED' },
    { value: 'READY_TO_SHIP', label: 'READY TO SHIP' },
    { value: 'PICKUP_SCHEDULED', label: 'PICKUP SCHEDULED' },
    { value: 'SHIPPED', label: 'SHIPPED' },
    { value: 'DELIVERED', label: 'DELIVERED' },
    { value: 'RTO', label: 'RTO' },
    { value: 'RTO_DELIVERED', label: 'RTO DELIVERED' },
    { value: 'RTO_FAILED', label: 'RTO FAILED' },
    { value: 'CANCELLATION_REQUESTED', label: 'CANCELLATION REQUESTED' },
    { value: 'CANCELLED', label: 'CANCELLED' }
  ];

  return {
    title: (
      <div className="flex items-center">
        {title}
        {orderStatus ? <YellowCircle /> : null}
      </div>
    ),
    filterDropdown: () => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Form
          form={orderStatusForm}
          layout="vertical"
          id="orderStatusForm"
          onFinish={handleStatusSearch}>
          <Form.Item name="orderStatus">
            <Select
              showSearch
              placeholder="Select Order Status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: 180 }}
              options={orderStatusOptions}
            />
          </Form.Item>
        </Form>
        <div className="flex gap-3 justify-end">
          <Button onClick={handleStatusReset} size="small">
            Reset
          </Button>
          <Button htmlType="submit" form="orderStatusForm" size="small" className="primary-button">
            Search
          </Button>
        </div>
      </div>
    )
  };
};

export default GetOrderByStatus;
