import { useState } from 'react';
import { showMessage } from '../../../../utils/ResponseMessages';
import { httpClient } from '../../../../utils/apiRequest';

const useWalletTransactions = () => {
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState({
    transactionList: [],
    totalPage: 0
  });

  const fetchStart = () => {
    setLoading(true);
    setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error: string) => {
    setLoading(false);
    setError(error);
  };

  const getTransactionOfWallet = async ({
    callbackFun,
    pageNumber = 0,
    pageLimit = 20,
    userId,
    from,
    to
  }: {
    callbackFun?: () => void;
    pageNumber?: number;
    pageLimit?: number;
    userId?: string;
    from?: Date;
    to?: Date;
  }) => {
    fetchStart();
    let payload: {
      skip: number;
      limit: number;
      pageNumber?: number;
      pageLimit?: number;
      userId?: string;
      from?: Date;
      to?: Date;
    } = {
      skip: pageNumber - 1, // subtract by 1, As per api requirements, page number start from 0.
      limit: pageLimit
    };

    if (userId) {
      payload = {
        ...payload,
        userId: userId
      };
    }
    if (from) {
      payload = {
        ...payload,
        from: from
      };
    }
    if (to) {
      payload = {
        ...payload,
        to: to
      };
    }

    try {
      const data = await httpClient.post(`/wallet`, payload);
      fetchSuccess();
      setTransactionData({
        transactionList: data?.data?.data?.walletData?.walletData,
        totalPage: data?.data?.data?.walletData?.totalPage
      });
      if (callbackFun) {
        callbackFun();
      }
    } catch (error: any) {
      fetchError(error.message);
      showMessage(error?.response?.data?.message, error?.response?.status);
    }
  };

  return {
    getTransactionOfWallet,
    transactionData,
    isLoading,
    error
  };
};
export default useWalletTransactions;
