import React, { useEffect, useState } from 'react';
import { Button, Form, Pagination, PaginationProps, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { GetProviderLogo } from '../../../utils/GetProviderLogos';
import { GetBusinessOrdersList } from './api';
import GetStatus from '../../../utils/GetStatus';
import GetOrderByIdFilter from '../../CommonComponents/TableFilterComponents/GetOrderById';
import DateRangeFilter from '../../CommonComponents/TableFilterComponents/DateRangeFilter';

const BusinessOrdersList: React.FC = () => {
  const [orderIdSearchForm] = Form.useForm();
  const [awbIdSearchForm] = Form.useForm();

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(20);
  const [businessOrders, setBusinessOrders] = useState<any>([]);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string>('');
  const [daterange, setDaterange] = useState<{ from: string; to: string }>({ from: '', to: '' });
  const [awbId, setAwbId] = useState<string>('');

  const handleGetBusinessOrders = () => {
    GetBusinessOrdersList({
      setDataState: setBusinessOrders,
      setLoading: setListLoading,
      pageNumber: currentPage,
      pageLimit: pageLimit,
      orderId: orderId,
      from: daterange?.from,
      to: daterange?.to,
      awbId: awbId
    });
  };

  useEffect(() => {
    handleGetBusinessOrders();
  }, [currentPage, pageLimit, orderId, daterange, awbId]);

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  const columns: ColumnsType<any> = [
    {
      dataIndex: 'orderId',
      key: 'orderId',
      fixed: 'left',
      width: 150,
      render: (orderId) => <div id="order-id-dropdown">{orderId}</div>,
      ...GetOrderByIdFilter({
        orderId: orderId,
        setOrderId: setOrderId,
        formInstance: orderIdSearchForm,
        formId: 'businessOrderIdForm'
      })
    },
    {
      dataIndex: 'tracking_number',
      key: 'tracking_number',
      render: (data) => <div>{data ? data : 'NA'}</div>,
      width: 150,
      ...GetOrderByIdFilter({
        orderId: awbId,
        setOrderId: setAwbId,
        formInstance: awbIdSearchForm,
        title: 'AWB #',
        placeHolder: 'AWB #',
        formId: 'AwbIdForm'
      })
    },
    {
      title: 'Delivery vendor',
      dataIndex: 'delivery_vendor',
      key: 'delivery_vendor',
      render: (text) => (
        <img
          src={GetProviderLogo(text)}
          alt="provider"
          style={window.innerWidth > 699 ? { width: '6rem' } : { width: '3rem' }}
        />
      )
    },
    {
      title: 'Pickup customer name',
      dataIndex: 'pickup_customer_name',
      key: 'pickup_customer_name'
    },
    {
      title: 'Delivery customer name',
      dataIndex: 'delivery_customer_name',
      key: 'delivery_customer_name'
    },
    {
      title: 'Pickup Address City',
      dataIndex: 'pickup_address_city',
      key: 'pickup_address_city'
    },
    {
      title: 'Delivery Address City',
      dataIndex: 'delivery_address_city',
      key: 'delivery_address_city'
    },
    {
      width: 150,
      dataIndex: 'createdAt',
      key: 'createdAt',
      ...DateRangeFilter({ setDaterange: setDaterange, daterange: daterange })
    },
    {
      title: 'Order Status',
      dataIndex: 'order_status',
      key: 'order_status',
      render: (status) => (
        <GetStatus
          currStatus={status}
          PROCESSING="processing"
          SUCCESS="success"
          FAILED="failure"
          CANCELLED="cancelled"
        />
      )
    },
    {
      title: 'Action',
      render: (text, record) => (
        <Button
          className="primary-button"
          onClick={() => navigate(`/business-order-details/${record.orderId}`)}>
          View Details
        </Button>
      )
    }
  ];
  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-normal m-0">Business Orders List</h2>
        <Button className="m-0" onClick={handleGetBusinessOrders}>
          Refresh
        </Button>
      </div>
      <Table
        columns={columns}
        loading={listLoading}
        rowKey={'orderId'}
        scroll={{ x: 1600 }}
        pagination={false}
        dataSource={businessOrders?.ordersList}
      />
      <br />
      <div className="flex justify-end">
        <Pagination
          responsive={true}
          showQuickJumper
          current={currentPage}
          pageSize={pageLimit}
          pageSizeOptions={[20, 30, 50]}
          total={businessOrders?.totalPage ? businessOrders?.totalPage * pageLimit : 0}
          onChange={onPageChange}
        />
      </div>
    </div>
  );
};
export default BusinessOrdersList;
