import React, { useEffect, useState } from 'react';
import { Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { GetProviderLogo } from '../../../../utils/GetProviderLogos';
import { getCodServicesList, handleCODServiceability } from '../api';
import ConfirmModal from '../../../../utils/ConfirmModal';
import { ICODServiceProvidersList } from '../../../../interfaces/serviceProvider';

const HandleCODService = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [codProviderList, setCodProviderList] = useState<ICODServiceProvidersList[]>([]);

  const initialCurrentProviderState = {
    serviceProvider: '',
    serviceType: '',
    isCODActive: false
  };
  const [currentProvider, setCurrentProvider] = useState<ICODServiceProvidersList>(
    initialCurrentProviderState
  );
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const handleProviderList = () => {
    getCodServicesList({ setLoading: setIsLoading, setData: setCodProviderList });
  };

  useEffect(() => {
    handleProviderList();
  }, []);

  const handleOnChange = (data: ICODServiceProvidersList) => {
    setCurrentProvider({
      serviceProvider: data?.serviceProvider,
      serviceType: data?.serviceType,
      isCODActive: data?.isCODActive
    });
    setConfirmModal(true);
  };

  const providerColumns: ColumnsType<ICODServiceProvidersList> = [
    {
      title: <div className="flex justify-center">Service Provider</div>,
      dataIndex: 'serviceProvider',
      render: (text) => (
        <div title={text} className="flex items-center justify-center">
          <img
            loading="lazy"
            className="max-w-[100px]"
            src={GetProviderLogo(text)}
            alt="provider"
          />
        </div>
      )
    },
    {
      title: <div className="flex justify-center">Service Type</div>,
      dataIndex: 'serviceType',
      render: (data) => (
        <div className="flex justify-center">
          <b>{data}</b>
        </div>
      )
    },
    {
      title: <div className="flex justify-center">COD Serviceability</div>,
      dataIndex: 'isCODActive',
      render: (isCODActive, data) => (
        <div className="flex justify-center">
          <Switch
            checked={isCODActive}
            className="bg-gray-300"
            onChange={() => handleOnChange(data)}
          />
        </div>
      )
    }
  ];

  const onConfirm = () => {
    handleCODServiceability({
      setLoading: setIsLoading,
      payload: { ...currentProvider, isCODActive: !currentProvider?.isCODActive },
      callBackFun: () => {
        handleProviderList();
        setConfirmModal(false);
        setCurrentProvider(initialCurrentProviderState);
      }
    });
  };

  return (
    <>
      <Table
        columns={providerColumns}
        dataSource={codProviderList}
        loading={isLoading}
        rowKey={'_id'}
      />

      <ConfirmModal
        isModalOpen={confirmModal}
        onClickOk={onConfirm}
        isLoading={isLoading}
        onCancel={() => setConfirmModal(false)}
        okText={currentProvider?.isCODActive ? 'Disable' : 'Enable'}
        modalBody={
          <div className="flex flex-col gap-2">
            <p className="m-0">
              Are you sure to <b>{currentProvider?.isCODActive ? 'disable' : 'enable'}</b> COD
              service for following service provider?
            </p>
            <p>
              <b>Name: {currentProvider?.serviceProvider}.</b>
            </p>
            <p>
              <b>Type: {currentProvider?.serviceType}.</b>
            </p>
          </div>
        }
      />
    </>
  );
};

export default HandleCODService;
