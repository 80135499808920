import React, { ReactNode } from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

interface MainContentProps {
  children: ReactNode;
}

const MainContent: React.FC<MainContentProps> = ({ children }) => {
  return <Content style={{ padding: '32px' }}>{children}</Content>;
};

export default MainContent;
