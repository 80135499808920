import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { downloadBulkOrder } from './Partials/DownloadBulkOrder';

const BulkOrdersReport: React.FC = () => {
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);

  const onSubmitBulkId = ({ bulkId }: { bulkId: string }) => {
    downloadBulkOrder({ setIsReportLoading: setIsReportLoading, bulkId: bulkId });
  };
  return (
    <>
      <h2 className="text-xl font-normal m-0 mb-4">Bulk Orders Report</h2>

      <div className="flex justify-center">
        <div className="max-w-[500px] w-[90%] p-10 rounded-xl gap-5 primary-box-shadow">
          <Form id="bulkOrderDownloadForm" layout="vertical" onFinish={onSubmitBulkId}>
            <Form.Item
              name="bulkId"
              className="m-0"
              label="Bulk Order Id :"
              rules={[{ required: true, message: 'Please enter bulk order id.' }]}>
              <Input placeholder="Order ID" />
            </Form.Item>

            <div className="mt-5 flex justify-center">
              <Button
                loading={isReportLoading}
                htmlType="submit"
                form="bulkOrderDownloadForm"
                className="primary-button">
                Download
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default BulkOrdersReport;
