import { Button, Form, Input } from 'antd';
import React from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';

const PasswordChangeFields: React.FC = () => {
  return (
    <div>
      <Form.Item
        className="mb-2"
        rules={[{ required: true, message: 'Please enter new password.' }]}
        name="newPassword"
        label={<Formlabel labelText="Enter New Password" />}
        hasFeedback>
        <Input type={'password'} placeholder="New Password" />
      </Form.Item>

      <Form.Item
        className="mb-2"
        dependencies={['newPassword']}
        hasFeedback
        rules={[
          { required: true, message: 'Please confirm password.' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The new password that you entered do not match!'));
            }
          })
        ]}
        name="confirmPassword"
        label={<Formlabel labelText="Confirm New Password" />}>
        <Input type={'password'} placeholder="Re-Enter Password" />
      </Form.Item>

      <Button
        htmlType="submit"
        className="!text-[#fff] mt-3 mb-5"
        style={{ background: 'rgba(3, 143, 222, 0.7)' }}>
        Change Password
      </Button>
    </div>
  );
};

export default PasswordChangeFields;
