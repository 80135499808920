import { ColumnsType } from 'antd/es/table';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { checkValidation, validateColumnKeys } from './BulkOrderUtils';
import { Radio } from 'antd';
import ItemCategorySelect from './ItemCategorySelect';
import CustomPriceInput from './CustomPriceInput';

interface BulkOrderColumnsProps {
  isCustomPrice: boolean;
  customPrice: string;
  setCustomPrice: Dispatch<SetStateAction<string>>;
  hsnCategoriesList: { [key: string]: any }[];
  categoriesType: string;
  setCategoriesType: Dispatch<SetStateAction<string>>;
  onItemCategoryChange: (id: string, value: string) => void;
  commCategory: string;
  bookedAllItems: boolean;
  isPriceAdded: boolean;
  handleEditCustomPrice: (orderId: string, newValue: string) => void;
}

export const getError = (order: { [key: string]: any }, value: any, msg: string) => {
  return (
    <p
      className={`${
        checkValidation(order)?.invalidOrderKeys?.includes(msg) ? 'text-red-600' : ''
      } `}>
      {value}
    </p>
  );
};

export const BulkOrderColumns = ({
  isCustomPrice,
  customPrice,
  setCustomPrice,
  hsnCategoriesList,
  categoriesType,
  setCategoriesType,
  onItemCategoryChange,
  commCategory,
  bookedAllItems = false,
  isPriceAdded,
  handleEditCustomPrice
}: BulkOrderColumnsProps) => {
  let tableColumns: ColumnsType<{ [key: string]: any }> = [
    {
      title: 'Id',
      dataIndex: 'id',
      fixed: 'left',
      width: 60
    },
    {
      title: (
        <div>
          Delivery Address <br /> Line 1
        </div>
      ),
      dataIndex: 'delivery_address_line1',
      width: 200,
      render: (address, order) =>
        getError(order, address, validateColumnKeys?.delivery_address_line1)
    },
    {
      title: (
        <div>
          Delivery Address <br /> Line 2
        </div>
      ),
      dataIndex: 'delivery_address_line2',
      width: 200,
      render: (address, order) =>
        getError(order, address, validateColumnKeys?.delivery_address_line2)
    },
    {
      title: (
        <div>
          Delivery <br />
          Email
        </div>
      ),
      dataIndex: 'delivery_email',
      width: 150,
      render: (email, order) => getError(order, email, validateColumnKeys?.delivery_email)
    },
    {
      title: 'Delivery Customer Name',
      dataIndex: 'delivery_customer_name',
      width: 150,
      render: (name, order) => getError(order, name, validateColumnKeys?.delivery_customer_name)
    },
    {
      title: (
        <div>
          Delivery Address
          <br /> Country
        </div>
      ),
      dataIndex: 'delivery_address_country',
      width: 150
    },
    {
      title: (
        <div>
          Delivery Address <br /> State
        </div>
      ),
      dataIndex: 'delivery_address_state',
      width: 150
    },
    {
      title: (
        <div>
          Delivery Address <br /> City
        </div>
      ),
      dataIndex: 'delivery_address_city',
      width: 150
    },
    {
      title: 'Delivery Address Pincode',
      dataIndex: 'delivery_address_pincode',
      width: 150,
      render: (pincode, order) =>
        getError(order, pincode, validateColumnKeys?.delivery_address_pincode)
    },
    {
      title: (
        <div>
          Delivery Phone <br /> Number
        </div>
      ),
      dataIndex: 'delivery_phone_number',
      width: 200,
      render: (mobileNo, order) =>
        getError(order, mobileNo, validateColumnKeys?.delivery_phone_number)
    },
    {
      title: (
        <div>
          Pickup Address <br /> Line 1
        </div>
      ),
      dataIndex: 'pickup_address_line1',
      width: 200,
      render: (address, order) => getError(order, address, validateColumnKeys?.pickup_address_line1)
    },
    {
      title: (
        <div>
          Pickup Address <br /> Line 2
        </div>
      ),
      dataIndex: 'pickup_address_line2',
      width: 200,
      render: (address, order) => getError(order, address, validateColumnKeys?.pickup_address_line2)
    },
    {
      title: 'Pickup Email',
      dataIndex: 'pickup_email',
      width: 150,
      render: (email, order) => getError(order, email, validateColumnKeys?.pickup_email)
    },
    {
      title: (
        <div>
          Pickup Customer
          <br /> Name
        </div>
      ),
      dataIndex: 'pickup_customer_name',
      width: 150,
      render: (name, order) => getError(order, name, validateColumnKeys?.pickup_customer_name)
    },
    {
      title: (
        <div>
          Pickup Address
          <br /> Country
        </div>
      ),
      dataIndex: 'pickup_address_country',
      width: 150
    },
    {
      title: (
        <div>
          Pickup Address <br />
          State
        </div>
      ),
      dataIndex: 'pickup_address_state',
      width: 150
    },
    {
      title: (
        <div>
          Pickup Address <br />
          City
        </div>
      ),
      dataIndex: 'pickup_address_city',
      width: 150
    },
    {
      title: (
        <div>
          Pickup Address <br />
          Pincode
        </div>
      ),
      dataIndex: 'pickup_address_pincode',
      width: 150,
      render: (pincode, order) =>
        getError(order, pincode, validateColumnKeys?.pickup_address_pincode)
    },
    {
      title: (
        <div>
          Pickup Phone <br />
          Number
        </div>
      ),
      dataIndex: 'pickup_phone_number',
      width: 150,
      render: (phone, order) => getError(order, phone, validateColumnKeys?.pickup_phone_number)
    },
    {
      title: (
        <div>
          Billing Customer
          <br />
          Name
        </div>
      ),
      dataIndex: 'billing_customer_name',
      width: 150,
      render: (name, order) => getError(order, name, validateColumnKeys?.billing_customer_name)
    },
    {
      title: (
        <div>
          Billing Address
          <br /> Line 1
        </div>
      ),
      dataIndex: 'billing_address_line1',
      width: 150,
      render: (address, order) =>
        getError(order, address, validateColumnKeys?.billing_address_line1)
    },
    {
      title: (
        <div>
          Billing Address <br />
          Line 2
        </div>
      ),
      dataIndex: 'billing_address_line2',
      width: 150,
      render: (address, order) =>
        getError(order, address, validateColumnKeys?.billing_address_line2)
    },
    {
      title: (
        <div>
          Billing Address
          <br /> City
        </div>
      ),
      dataIndex: 'billing_address_city',
      width: 150
    },
    {
      title: (
        <div>
          Billing Address
          <br /> State
        </div>
      ),
      dataIndex: 'billing_address_state',
      width: 150
    },
    {
      title: (
        <div>
          Billing Address
          <br /> Country
        </div>
      ),
      dataIndex: 'billing_address_country',
      width: 150
    },
    {
      title: (
        <div>
          Billing Address
          <br /> Pincode
        </div>
      ),
      dataIndex: 'billing_address_pincode',
      width: 150,
      render: (pincode, order) =>
        getError(order, pincode, validateColumnKeys?.billing_address_pincode)
    },
    {
      title: (
        <div>
          Billing Phone
          <br /> Number
        </div>
      ),
      dataIndex: 'billing_phone_number',
      width: 150,
      render: (phone, order) => getError(order, phone, validateColumnKeys?.billing_phone_number)
    },
    {
      title: <div>Billing Email</div>,
      dataIndex: 'billing_email',
      width: 150,
      render: (email, order) => getError(order, email, validateColumnKeys?.billing_email)
    },
    {
      title: 'Shipment Length',
      dataIndex: 'shipment_length',
      width: 150,
      render: (length, order) => getError(order, length, validateColumnKeys?.shipment_length)
    },
    {
      title: 'Shipment Width',
      dataIndex: 'shipment_width',
      width: 150,
      render: (width, order) => getError(order, width, validateColumnKeys?.shipment_width)
    },
    {
      title: 'Shipment Height',
      dataIndex: 'shipment_height',
      width: 150,
      render: (height, order) => getError(order, height, validateColumnKeys?.shipment_height)
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      width: 150,
      render: (weight, order) => getError(order, weight, validateColumnKeys?.weight)
    },
    {
      title: 'Item Description',
      dataIndex: 'item_description',
      width: 200,
      render: (description, order) => getError(order, description, validateColumnKeys?.weight)
    },
    {
      title: 'Estimated Value',
      dataIndex: 'estimated_value',
      width: 150,
      render: (value, order) => getError(order, value, validateColumnKeys?.estimated_value)
    },
    {
      title: 'Service Type',
      dataIndex: 'service_type',
      width: 100
    },
    {
      title: 'Delivery Vendor',
      dataIndex: 'delivery_vendor',
      width: 100
    },
    { title: 'Special Field', dataIndex: 'special_field', width: 150 },
    {
      title: 'Eway Bill',
      dataIndex: 'ewayBill',
      width: 150,
      render: (ewayBill) => ewayBill ?? 'NA'
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      width: 150,
      render: (expiryDate) => expiryDate ?? 'NA'
    },
    {
      title: 'Seller Gst No',
      dataIndex: 'sellerGstNo',
      width: 150,
      render: (sellerGstNo) => sellerGstNo ?? 'NA'
    },
    {
      title: 'Buyer Gst No',
      dataIndex: 'buyerGstNo',
      width: 150,
      render: (buyerGstNo) => buyerGstNo ?? 'NA'
    },
    {
      title: 'Product Invoice No',
      dataIndex: 'productInvoiceNo',
      width: 150,
      render: (invoiceNum) => invoiceNum ?? 'NA'
    },
    {
      title: 'Product Invoice Date',
      dataIndex: 'productInvoiceDate',
      width: 150,
      render: (invoiceDate) => invoiceDate ?? 'NA'
    },
    {
      title: 'Taxable Value',
      dataIndex: 'taxableValue',
      width: 150,
      render: (taxableValue) => taxableValue ?? 'NA'
    },
    {
      title: 'CGST Amount',
      dataIndex: 'cgstAmount',
      width: 150,
      render: (cgstAmount) => cgstAmount ?? 'NA'
    },
    {
      title: 'SGST Amount',
      dataIndex: 'sgstAmount',
      width: 150,
      render: (sgstAmount) => sgstAmount ?? 'NA'
    },
    {
      title: 'IGST Amount',
      dataIndex: 'igstAmount',
      width: 150,
      render: (igstAmount) => igstAmount ?? 'NA'
    },
    {
      title: (
        <div>
          Item Category <br />
          {bookedAllItems ? null : (
            <>
              <Radio.Group
                className="py-2 flex"
                onChange={({ target }) => setCategoriesType(target?.value)}
                value={categoriesType}>
                <Radio value={'individual'}>Individual</Radio>
                <Radio value={'common'}>Common</Radio>
              </Radio.Group>
              {categoriesType === 'common' ? (
                <ItemCategorySelect
                  id="common"
                  hsnCategoriesList={hsnCategoriesList}
                  onChange={onItemCategoryChange}
                />
              ) : null}
            </>
          )}
        </div>
      ),
      width: 215,
      fixed: bookedAllItems ? false : 'right',
      render: (_, order) =>
        categoriesType === 'common' ? (
          commCategory
        ) : (
          <ItemCategorySelect
            id={order?.id}
            hsnCategoriesList={hsnCategoriesList}
            onChange={onItemCategoryChange}
          />
        )
    }
  ];

  if (isPriceAdded) {
    tableColumns = [
      ...tableColumns,
      {
        title: <div>Custom Price</div>,
        dataIndex: 'custom_price',
        width: isPriceAdded ? 200 : 0,
        fixed: isPriceAdded ? (bookedAllItems ? false : 'right') : false,
        render: (price, order) => (
          <>
            {bookedAllItems ? (
              <div>{price}</div>
            ) : (
              <CustomPriceInput
                name={`customPrice${order?.id}`}
                defaultValue={price}
                onPriceChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleEditCustomPrice(order?.id, e?.target?.value?.trim())
                }
              />
            )}
          </>
        )
      }
    ];
  } else {
    tableColumns = [
      ...tableColumns,
      {
        title: (
          <div>
            Custom Price
            <br />
            {isPriceAdded ? null : bookedAllItems ? null : (
              <>
                <div className="py-2">
                  <CustomPriceInput
                    name={`customPrice`}
                    onPriceChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setCustomPrice(e?.target?.value)
                    }
                  />
                </div>
              </>
            )}
          </div>
        ),
        dataIndex: 'price',
        fixed: 'right',
        width: 200,
        render: () => <div>{isCustomPrice ? customPrice : '-'}</div>
      }
    ];
  }

  if (bookedAllItems) {
    tableColumns = [
      ...tableColumns,
      {
        title: 'Status',
        dataIndex: 'success',
        fixed: 'right',
        width: 100,
        render: (status) => (
          <div className={status ? 'text-green-600' : 'text-red-600'}>
            {status ? 'Success' : 'Failed'}
          </div>
        )
      },
      {
        title: 'Order Id',
        dataIndex: 'orderId',
        fixed: 'right',
        width: 150
      },
      {
        title: 'AWB Id',
        dataIndex: 'awb',
        fixed: 'right',
        width: 150,
        render: (id) => (id ? id : 'NA')
      }
    ];
  }
  return tableColumns;
};
