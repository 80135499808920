// reducers/rootReducer.ts
import { combineReducers } from 'redux';
import counterReducer from './counterReducer';

export interface RootState {
  counter: number;
  // Add other state properties here
}

const rootReducer = combineReducers({
  counter: counterReducer
  // Add other reducers here
});

export default rootReducer;
