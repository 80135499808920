import React, { FC } from 'react';
import { Button, Divider, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FaMinusCircle } from 'react-icons/fa';
import { formValidations } from '../constants';
import Formlabel from '../../../../CommonComponents/FormLabel';

interface IBusinessPresence {
  businessPresenceCount: number;
}
const BusinessPresence: FC<IBusinessPresence> = ({ businessPresenceCount }) => {
  return (
    <div>
      <Divider />
      <h2 className="text-xl font-bold mb-5 select-none">Business Locations :</h2>

      <Form.List name="businessPresence" initialValue={[{ city: '', country: '' }]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} className="flex gap-3 items-start mb-5 justify-between">
                <div className="flex w-[95%] gap-2 sm:gap-5">
                  <div className="w-1/2 flex flex-col gap-3">
                    <Formlabel labelText="City" required />
                    <Form.Item
                      {...restField}
                      rules={[
                        {
                          required: true,
                          message: formValidations?.businessCity?.required
                        }
                      ]}
                      className="mb-0"
                      name={[name, 'city']}>
                      <Input placeholder="Enter city name" />
                    </Form.Item>
                  </div>

                  <div className="w-1/2 flex flex-col gap-3">
                    <Formlabel labelText="Country" required />

                    <Form.Item
                      {...restField}
                      className="mb-0"
                      name={[name, 'country']}
                      rules={[
                        {
                          required: true,
                          message: formValidations?.businessCountry?.required
                        }
                      ]}>
                      <Input placeholder="Enter country" />
                    </Form.Item>
                  </div>
                </div>

                <div className="mt-[34px] w-[30px] flex flex-col gap-3">
                  <Button
                    disabled={businessPresenceCount <= 1}
                    title={businessPresenceCount <= 1 ? '' : 'Remove'}
                    icon={<FaMinusCircle />}
                    className="flex justify-center items-center"
                    onClick={() => remove(name)}
                  />
                </div>
              </div>
            ))}

            <Form.Item className="flex justify-center">
              <Button
                className="flex items-center"
                type="primary"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}>
                Add
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default BusinessPresence;
