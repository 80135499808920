import { Button } from 'antd';
import React, { FC } from 'react';
import DiscrepancyTable from './DiscrepancyTable';

const Discrepancy: FC = () => {
  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-normal m-0">Discrepancy</h2>
      </div>

      <DiscrepancyTable />
    </>
  );
};

export default Discrepancy;
