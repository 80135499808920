export const SALES_MODE_OPTIONS = [
  {
    value: 'Online',
    label: 'Online'
  },
  {
    value: 'Offline',
    label: 'Offline'
  }
];

export const MARKET_PLACE_OPTIONS = [
  {
    value: 'Amazon',
    label: 'Amazon'
  },
  {
    value: 'Flipkart',
    label: 'Flipkart'
  },
  {
    value: 'Shopify',
    label: 'Shopify'
  },
  {
    value: 'ONDC',
    label: 'ONDC'
  },
  {
    value: 'others',
    label: 'Others'
  }
];

export const CREDIT_BNPL = 'BNPL'; // NOTE: Value should be same the value given for BNPL account type in ACCOUNT_TYPE_OPTIONS
export const ACCOUNT_TYPE_OPTIONS = [
  {
    value: 'WALLET',
    label: 'WALLET'
  },
  {
    value: 'BNPL',
    label: 'BNPL'
  }
];

export const VALUE_ADDED_SERVICE_OPTIONS = [
  {
    value: 'COLLECT_ON_DELIVERY',
    label: 'COLLECT ON DELIVERY'
  },
  {
    value: 'INSURANCE',
    label: 'INSURANCE'
  },
  {
    value: 'BUY_NOW_PAY_LATER',
    label: 'BUY NOW PAY LATER'
  },
  {
    value: 'DEVELOPER_API',
    label: 'DEVELOPER API'
  }
];

export const SHIPPING_SERVICE_TYPE_OPTIONS = [
  {
    value: 'Surface',
    label: 'Surface'
  },
  {
    value: 'Standard',
    label: 'Standard'
  }
];

export const CREDIT_LIMIT_OPTIONS = [
  {
    label: 'Upto 50k',
    value: '50000'
  },
  {
    label: 'Upto 1 lacks',
    value: '100000'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
];

export const formValidations = {
  productType: {
    required: 'Pleas enter product type'
  },
  salesMode: {
    required: 'Please select any mode'
  },
  marketPlace: {
    required: 'Please select any option'
  },
  averageWeight: {
    required: 'Please enter weight'
  },
  minMonthlyDispatch: {
    required: 'Please enter min count',
    patternRegex: /^[1-9]\d*$/,
    patternMessage: 'Count should not start with 0'
  },
  maxMonthlyDispatch: {
    required: 'Please enter max count',
    patternRegex: /^[1-9]\d*$/,
    patternMessage: 'Count should not start with 0'
  },
  averageBillingValue: {
    required: 'Please enter billing value'
  },
  businessCity: {
    required: 'Please enter city'
  },
  businessCountry: {
    required: 'Please enter country'
  },
  shippingServiceType: {
    required: 'Please select any type'
  },
  accountType: {
    required: 'Please select account type'
  },
  creditLimit: {
    required: 'Please enter credit limit'
  },
  valueAddedServices: {
    required: 'Please select any service'
  }
};
