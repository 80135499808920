import { Button, PaginationProps, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { getCompanyList } from '../api';
import { ICompanyListResponseData } from '../../../../interfaces/b2bVerification';
import { initialStatus, statusList, tableColumns } from './utils';

const CompanyList = () => {
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);

  const [tableListData, setTableListData] = useState<ICompanyListResponseData>({
    companysCount: 0,
    companysList: []
  });
  const [tableStatus, setTableStatus] = useState<string>(initialStatus);

  useEffect(() => {
    setTableStatus(initialStatus);
  }, []);

  const handleGetCompanyList = async () => {
    getCompanyList({
      setData: setTableListData,
      setLoading: setIsTableLoading,
      status: tableStatus,
      pageLimit: pageLimit,
      skip: currentPage
    });
  };

  useEffect(() => {
    handleGetCompanyList();
  }, [tableStatus, pageLimit, currentPage]);

  const onChangeStatus = (status: string) => {
    setTableStatus(status);
  };

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  return (
    <>
      <div className="flex flex-wrap gap-3 justify-between items-center">
        <h2 className="text-xl font-normal m-0">
          Company List {isTableLoading ? '' : `( ${tableListData?.companysCount || 0} )`}
        </h2>
        <Button className="primary-button" loading={isTableLoading} onClick={handleGetCompanyList}>
          Refresh
        </Button>
      </div>

      <div className="pt-4">
        <div className="flex gap-3">
          {statusList?.map((item) => (
            <Button
              className="font-medium border-2 mb-5"
              key={item?.value}
              type={item?.value === tableStatus ? 'primary' : 'default'}
              onClick={() => onChangeStatus(item?.value)}>
              {item?.label}
            </Button>
          ))}
        </div>

        <Table
          scroll={{ x: 1600 }}
          dataSource={tableListData?.companysList}
          columns={tableColumns}
          pagination={{
            responsive: true,
            showQuickJumper: true,
            showSizeChanger: true,
            current: currentPage,
            pageSize: pageLimit,
            pageSizeOptions: [20, 30, 50],
            total: tableListData?.companysCount
              ? Math.ceil(tableListData?.companysCount / pageLimit) * pageLimit
              : 0,
            onChange: onPageChange
          }}
          loading={isTableLoading}
        />
      </div>
    </>
  );
};

export default CompanyList;
