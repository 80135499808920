import { Button, Form, FormInstance, Select } from 'antd';
import React from 'react';
import YellowCircle from '../../../CommonComponents/YellowCircle';

export const GetDropDownFilters = ({
  formInstance,
  formId,
  onFinish,
  title,
  stateValue,
  options,
  handleReset,
  searchFieldName,
  placeholder
}: {
  formInstance: FormInstance;
  onFinish?: ((values: any) => void) | undefined;
  title: string;
  stateValue: string | number | undefined;
  options: { label: string; value: string }[];
  handleReset: () => void;
  searchFieldName: string;
  formId: string;
  placeholder: string;
}) => ({
  title: (
    <div className="flex items-center">
      {title}
      {stateValue ? <YellowCircle /> : null}
    </div>
  ),
  filterDropdown: () => (
    <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
      <Form form={formInstance} layout="vertical" id={formId} onFinish={onFinish}>
        <Form.Item name={searchFieldName}>
          <Select
            showSearch
            allowClear
            placeholder={placeholder}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '')?.toString().toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: 180 }}
            options={options}
            onClear={handleReset}
          />
        </Form.Item>
      </Form>
      <div className="flex gap-3 justify-end">
        <Button onClick={handleReset} size="small">
          Reset
        </Button>
        <Button htmlType="submit" form={formId} size="small" className="primary-button">
          Search
        </Button>
      </div>
    </div>
  )
});
