import { Form, Button, Select, FormInstance } from 'antd';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { IProvidersListResponse } from '../../../../../../interfaces/b2bVerification';
import YellowCircle from '../../../../../CommonComponents/YellowCircle';

interface IGetOrderByCarrier {
  filterCarrierForm: FormInstance;
  selectedProvider: string;
  setSelectedProvider: Dispatch<SetStateAction<string>>;
  providersData: IProvidersListResponse[];
}

const GetOrderByCarrier = ({
  filterCarrierForm,
  selectedProvider,
  setSelectedProvider,
  providersData
}: IGetOrderByCarrier) => {
  const [providerOptions, setProviderOptions] = React.useState<{ value: string; label: string }[]>(
    []
  );

  useEffect(() => {
    const options = providersData?.map((provider) => ({
      value: provider?.code,
      label: provider?.name
    }));
    setProviderOptions(options);
  }, [providersData]);

  const handleGetOrdersByCarrier = ({ deliverVendor }: { deliverVendor: string }) => {
    setSelectedProvider(deliverVendor);
  };

  const handleDeliveryReset = () => {
    setSelectedProvider('');
    filterCarrierForm.resetFields();
  };

  return {
    title: (
      <div className="flex items-center">
        Carrier
        {selectedProvider ? <YellowCircle /> : null}
      </div>
    ),
    filterDropdown: () => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Form
          form={filterCarrierForm}
          layout="vertical"
          id="deliveryVendorForm"
          onFinish={handleGetOrdersByCarrier}>
          <Form.Item name="deliverVendor">
            <Select
              showSearch
              allowClear
              placeholder="Select Delivery Vendor"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: 180 }}
              options={providerOptions}
            />
          </Form.Item>
        </Form>
        <div className="flex gap-3 justify-end">
          <Button onClick={handleDeliveryReset} size="small">
            Reset
          </Button>
          <Button
            htmlType="submit"
            form="deliveryVendorForm"
            size="small"
            className="primary-button">
            Search
          </Button>
        </div>
      </div>
    )
  };
};

export default GetOrderByCarrier;
