// eslint-disable-next-line
var XLSX = require('xlsx');

interface ExcelToJsonProps {
  dataFile: any;
}

export const GetPincodesFromExcel = ({ dataFile }: ExcelToJsonProps): Promise<number[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(dataFile);

    reader.onload = (event) => {
      const workbook = XLSX.read(event?.target?.result, {
        type: 'array'
      });

      const sheet = workbook.Sheets[workbook.SheetNames[0]];

      const dataArray: number[] = Object.keys(sheet)
        .filter((key) => key !== '!ref' && key !== '!margins')
        .map((key) => Number(sheet[key].v));

      resolve(dataArray);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};
