import { Button, Form, Input, Modal, Select, Switch } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { BiAddToQueue } from 'react-icons/bi';
import { IUpdateServiceabilityPayload } from '../../../../interfaces/serviceProvider';
import ConfirmModal from '../../../../utils/ConfirmModal';
import { handleServiceability } from '../api';

interface IAddServiceProvider {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setActiveTab: Dispatch<SetStateAction<string>>;
}
const AddServiceProvider: React.FC<IAddServiceProvider> = ({
  isModalOpen,
  setIsModalOpen,
  isLoading,
  setIsLoading,
  setActiveTab
}) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const initialProviderData = {
    serviceProvider: '',
    serviceType: '',
    isActive: false,
    portalName: ''
  };
  const [providerData, setProviderData] =
    useState<IUpdateServiceabilityPayload>(initialProviderData);

  const [providerForm] = Form.useForm();

  const handleSubmit = (formValues: IUpdateServiceabilityPayload) => {
    setProviderData(formValues);
    setIsModalOpen(false);
    setConfirmModal(true);
  };

  const onClose = () => {
    setIsModalOpen(false);
    providerForm.resetFields();
  };

  const onCancelConfirm = () => {
    setActiveTab(providerData?.portalName);
    setConfirmModal(false);
    providerForm.resetFields();
    setProviderData(initialProviderData);
  };

  const onConfirmAdd = () => {
    handleServiceability({
      setLoading: setIsLoading,
      payload: providerData,
      callBackFun: () => {
        onCancelConfirm();
      }
    });
  };

  const availablePortal: {
    label: string;
    value: string;
  }[] = [
    {
      label: 'Business',
      value: 'Business'
    },
    {
      label: 'Partner',
      value: 'Partner'
    },
    {
      label: 'User',
      value: 'User'
    }
  ];

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={onClose}
        title={
          <div className="flex items-center gap-2">
            <BiAddToQueue size={20} /> <h2>Add Provider</h2>
          </div>
        }
        footer={false}>
        <div className="my-4">
          <Form form={providerForm} layout="vertical" id="addProviderForm" onFinish={handleSubmit}>
            <div className="flex flex-col gap-2">
              <p>Provider name :</p>
              <Form.Item
                name="serviceProvider"
                rules={[
                  {
                    required: true,
                    message: 'Please enter provider name.'
                  }
                ]}>
                <Input type="text" placeholder="Enter name" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-2">
              <p>Service Type :</p>
              <Form.Item
                name="serviceType"
                rules={[{ required: true, message: 'Please enter service type' }]}>
                <Input type="text" placeholder="Enter service type" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-2">
              <p>Portal Name :</p>

              <Form.Item
                name="portalName"
                rules={[{ required: true, message: 'Please select portal name' }]}>
                <Select placeholder="Select portal name" options={availablePortal} />
              </Form.Item>
            </div>

            <div className="flex gap-6 items-center">
              <p>Serviceability :</p>

              <Form.Item className="flex-row m-0" name="isActive" initialValue={true}>
                <Switch defaultChecked className="bg-gray-300" />
              </Form.Item>
            </div>

            <div className="my-5 flex justify-end gap-5">
              <Button onClick={onClose}>Cancel</Button>
              <Button htmlType="submit" form="addProviderForm" className="px-10 primary-button">
                Add
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <ConfirmModal
        isLoading={isLoading}
        isModalOpen={confirmModal}
        onClickOk={onConfirmAdd}
        onCancel={onCancelConfirm}
        modalBody={
          <div className="flex flex-col gap-3">
            <p>Confirm to add following provider</p>

            <div className="font-bold flex gap-3">
              <p className="w-[100px] flex justify-between">
                Name <span>:</span>
              </p>
              <p>{providerData?.serviceProvider}</p>
            </div>
            <div className="font-bold flex gap-3">
              <p className="w-[100px] flex justify-between">
                Type <span>:</span>
              </p>
              <p>{providerData?.serviceType}</p>
            </div>

            <div className="font-bold flex gap-3">
              <p className="w-[100px] flex justify-between">
                Portal Name <span>:</span>
              </p>
              <p>{providerData?.portalName}</p>
            </div>

            <div className="font-bold flex gap-3">
              <p className="w-[100px] flex justify-between">
                Serviceability <span>:</span>
              </p>
              <p>{providerData?.isActive ? 'Active' : 'In-Active'}</p>
            </div>
          </div>
        }
      />
    </>
  );
};

export default AddServiceProvider;
