import { Button, Form, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import useOrderListDetails from '../../api';
import UpdateOrdersForm from './Partials/UpdateOrdersForm';

const UpdateOrders: React.FC = () => {
  const [updateOrderForm] = Form.useForm();
  const { updateOrdersAPI, isLoading } = useOrderListDetails();

  const [isOnlyUpdate, setIsOnlyUpdate] = useState<boolean>(true);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>('No File Chosen');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [ordersFile, setOrdersFile] = useState<
    {
      // eslint-disable-next-line
      [key: string]: any;
    }[]
  >([]);

  const [orderColumns, setOrderColumns] = useState<
    // eslint-disable-next-line
    (ColumnGroupType<{ [key: string]: any }> | ColumnType<{ [key: string]: any }>)[]
  >([]);

  // eslint-disable-next-line
  const getInvalidKeys = (objects: any[], mandatoryKeys: string[]): string[] => {
    const invalidKeys: string[] = [];

    objects.forEach((obj) => {
      mandatoryKeys.forEach((key) => {
        if (!(key in obj) || obj[key] === '' || obj[key] === undefined || obj[key] === null) {
          invalidKeys.push(key);
        }
      });
    });

    // Remove duplicates manually
    const uniqueInvalidKeys: string[] = [];
    for (const key of invalidKeys) {
      if (!uniqueInvalidKeys.includes(key)) {
        uniqueInvalidKeys.push(key);
      }
    }

    return uniqueInvalidKeys;
  };

  useEffect(() => {
    if (ordersFile?.length) {
      const updatedFields = selectedFields?.map((field) => ({
        title: field.replace(/_/g, ' ')?.toUpperCase(),
        dataIndex: field,
        width: 220
      }));

      updatedFields?.unshift({ title: 'ORDER ID', dataIndex: 'order_id', width: 50 });
      setOrderColumns(updatedFields);
    } else {
      setOrderColumns([]);
    }

    const invalidKeys: string[] = getInvalidKeys(ordersFile, selectedFields)?.map((val) =>
      val?.replace(/_/g, ' ')?.toUpperCase()
    );

    if (invalidKeys.length > 0) {
      setErrorMsg(`Please check the columns: ${invalidKeys.join(', ')}`);
    } else {
      setErrorMsg('');
    }
  }, [ordersFile]);

  const onClickFile = () => {
    setOrdersFile([]);
    setFileName('No File Chosen');
  };

  const onSubmit = async () => {
    const filteredFields = ordersFile.map((obj) => {
      let filteredObj: { [key: string]: string } = {};
      selectedFields.forEach((key) => {
        if (key in obj) {
          filteredObj[key] = obj[key];
        }
      });
      filteredObj = { ...filteredObj, order_id: obj?.order_id };
      return filteredObj;
    });

    const result = await updateOrdersAPI({
      ordersFile: filteredFields,
      isOnlyUpdate: isOnlyUpdate
    });
    if (result) {
      clearAll();
    }
  };

  const clearAll = () => {
    onClickFile();
    setIsOnlyUpdate(true);
    setSelectedFields([]);
    setOrderColumns([]);
    updateOrderForm?.setFieldsValue({
      updateType: true,
      updateFields: [],
      updateOrdersFile: []
    });
  };

  return (
    <div>
      <h3 className="text-lg p-0 m-0 mb-3">Update Orders</h3>

      <UpdateOrdersForm
        updateOrderForm={updateOrderForm}
        onSubmit={onSubmit}
        isOnlyUpdate={isOnlyUpdate}
        setIsOnlyUpdate={setIsOnlyUpdate}
        selectedFields={selectedFields}
        setSelectedFields={setSelectedFields}
        setErrorMsg={setErrorMsg}
        onClickFile={onClickFile}
        fileName={fileName}
        setFileName={setFileName}
        setOrdersFile={setOrdersFile}
      />
      <div className="mt-5 mb-5 text-center">
        {errorMsg ? (
          <p className="text-red-600">{errorMsg}</p>
        ) : ordersFile?.length ? (
          <Button
            className="primary-button"
            disabled={!ordersFile?.length}
            htmlType="submit"
            form={'updateOrderForm'}
            loading={isLoading}>
            Update Orders
          </Button>
        ) : null}
      </div>

      {ordersFile?.length ? (
        <Table
          columns={orderColumns}
          dataSource={ordersFile}
          loading={isLoading}
          bordered
          rowKey={'id'}
          scroll={{
            x: selectedFields?.length > 6 ? 5000 : 'auto'
          }}
          pagination={false}
          sticky
        />
      ) : null}
    </div>
  );
};

export default UpdateOrders;
