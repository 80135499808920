import { Divider, Form, Input } from 'antd';
import React from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { formFieldValidations } from '../constants';

const UserDetails = () => {
  return (
    <>
      <Divider />
      <h2 className="text-xl font-bold mb-5 select-none">User Info :</h2>

      <div className="flex flex-col md:flex-row md:gap-5 gap-0">
        <div className="w-full md:w-1/2">
          <Formlabel labelText="First Name" required />
          <Form.Item
            className="mt-2"
            name="user_firstName"
            rules={[
              { required: true, message: formFieldValidations?.userFirstName?.required },
              {
                max: formFieldValidations?.userFirstName?.maxCharactersLength,
                message: formFieldValidations?.userFirstName?.maxCharactersMessage
              }
            ]}>
            <Input placeholder="Enter first name" />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/2">
          <Formlabel labelText="Last Name" required />
          <Form.Item
            className="mt-2"
            name="user_lastName"
            rules={[
              { required: true, message: formFieldValidations?.userLastName?.required },
              {
                max: formFieldValidations?.userLastName?.maxCharactersLength,
                message: formFieldValidations?.userLastName?.maxCharactersMessage
              }
            ]}>
            <Input placeholder="Enter last name" />
          </Form.Item>
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:gap-5 gap-0">
        <div className="w-full md:w-1/3">
          <Formlabel labelText="ISD Code" required />
          <Form.Item
            className="mt-2"
            rules={[{ required: true, message: formFieldValidations?.userIsdCode?.required }]}
            name="user_isdCode">
            <Input placeholder="Enter code" />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/3">
          <Formlabel labelText="Contact Number" required />
          <Form.Item
            className="mt-2"
            rules={[
              { required: true, message: formFieldValidations?.userContactNo?.required },
              {
                pattern: formFieldValidations?.userContactNo?.patternValueRegex,
                message: formFieldValidations?.userContactNo?.patternMessage
              }
            ]}
            name="user_contactNo">
            <Input
              type="number"
              placeholder="Enter Number"
              onKeyDown={(e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()}
            />
          </Form.Item>
        </div>

        <div className="w-full md:w-1/3">
          <Formlabel labelText="Email" required />
          <Form.Item
            className="mt-2"
            rules={[
              { required: true, message: formFieldValidations?.userEmail?.required },
              {
                pattern: formFieldValidations?.userEmail?.patternValueRegex,
                message: formFieldValidations?.userEmail?.patternMessage
              }
            ]}
            name="user_email">
            <Input placeholder="Enter email" />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
