import { ColumnsType } from 'antd/es/table';
import { ICompanyListData } from '../../../../interfaces/b2bVerification';
import React from 'react';
import { Avatar, Tag, Typography } from 'antd';
import { MdBusiness } from 'react-icons/md';
import { FaExternalLinkAlt } from 'react-icons/fa';
import moment from 'moment';
import GetStatus from '../../../../utils/GetStatus';
import NavigateButton from './NavigateButton';
import { getNormalizedUrl } from '../../../../utils/getNormalizedUrl';

const { Text } = Typography;

// NOTE: TABLE COLUMNS
export const tableColumns: ColumnsType<ICompanyListData> = [
  {
    title: 'ID',
    width: 200,
    dataIndex: 'id',
    fixed: 'left',
    render: (id) =>
      id ? (
        <div className="flex items-center gap-1">
          <p className="m-0">
            <b>{id}</b>
          </p>
          <Text copyable={{ text: id }} />
        </div>
      ) : (
        'NA'
      )
  },
  {
    title: 'Brand',
    key: 'brand',
    width: 200,
    render: (_, data) => (
      <div className="flex items-center gap-3">
        <Avatar
          size={35}
          shape="square"
          src={data?.logoUrl}
          icon={
            <div className="flex items-center justify-center pt-2">
              <MdBusiness />
            </div>
          }
        />
        <h1 className="font-medium tracking-[0.5px] text-clip w-[150px]">{data?.brandName}</h1>
      </div>
    )
  },
  {
    title: 'Description',
    width: 200,
    dataIndex: 'description',
    render: (text) => (text ? <p>{text}</p> : '---')
  },
  {
    title: 'Sales Officer Id',
    dataIndex: 'salesOfficerId',
    width: 150,
    render: (id) =>
      id ? (
        <div className="flex items-center gap-1">
          <p className="m-0 font-medium">{id}</p>
          <Text copyable={{ text: id }} />
        </div>
      ) : (
        'NA'
      )
  },
  {
    title: 'Website',
    dataIndex: 'website',
    key: 'website',
    width: 100,
    render: (site) => (
      <div className="flex justify-center w-[50px]">
        {site ? (
          <div className="flex items-center gap-3">
            <Text copyable={{ text: site }} />

            <a
              title={site}
              href={getNormalizedUrl(site)}
              className="text-blue-400 hover:text-blue-900"
              target="blank">
              <FaExternalLinkAlt />
            </a>
          </div>
        ) : (
          'NA'
        )}
      </div>
    )
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    width: 120,
    render: (date) => <p className="w-[120px]">{moment(date).format('DD-MM-YYYY hh:mm:ss')}</p>
  },
  {
    title: 'Sector',
    dataIndex: 'sector',
    width: 150,
    render: (text) => (
      <Tag color="default" className="font-medium">
        {text || 'NA'}
      </Tag>
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 100,
    render: (text) => (
      <GetStatus
        currStatus={text}
        SHOW_TAGS
        PROCESSING="PENDING"
        SUCCESS="ENABLED"
        FAILED="CLOSED"
        CANCELLED="DISABLED"
        UNKNOWN="NA"
        CUSTOM_LABEL={text?.toUpperCase()}
      />
    )
  },
  {
    title: 'KYC',
    dataIndex: 'kycStatus',
    width: 100,
    render: (text) => (
      <GetStatus
        currStatus={text}
        SHOW_TAGS
        PROCESSING="PENDING"
        SUCCESS="VERIFIED"
        FAILED="REJECTED"
        CANCELLED="INCOMPLETE"
        UNKNOWN="NA"
        CUSTOM_LABEL={text?.toUpperCase()}
      />
    )
  },
  {
    title: 'Details',
    width: 100,
    fixed: 'right',
    render: (_, item) => {
      return <NavigateButton id={item?.id} />;
    }
  }
];

// NOTE: CONSTANTS
export const initialStatus = 'PENDING';
export const statusList = [
  {
    label: 'PENDING',
    value: 'PENDING'
  },
  {
    label: 'ENABLED',
    value: 'ENABLED'
  },
  {
    label: 'DISABLED',
    value: 'DISABLED'
  },
  {
    label: 'CLOSED',
    value: 'CLOSED'
  }
];
