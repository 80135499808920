import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../utils/apiRequest';
import {
  IEnablePincodeFormFields,
  IPincodeFormFields,
  IPincodeLogs,
  ISearchPincodeFormFields
} from '../../../../interfaces/handlePincode';
import { ErrorMessage, SuccessMessage, showMessage } from '../../../../utils/ResponseMessages';

export const handlePincodes = async ({
  setLoading,
  payload,
  callbackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IPincodeFormFields;
  callbackFunc?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post('/serviceability/block-pincodes', payload);
    SuccessMessage('Updated Successfully.');
    if (callbackFunc) callbackFunc();
  } catch (error: any) {
    console.error('Error in Updating Pincodes', error);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const SearchPincodes = async ({
  setLoading,
  payload,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: ISearchPincodeFormFields;
  setData: Dispatch<SetStateAction<number[] | undefined>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.post('/serviceability/get-block-pincodes', payload);
    SuccessMessage(response?.data?.message);
    setData(response?.data?.data);
  } catch (error: any) {
    console.error('Error in Searching Pincodes', error);
    ErrorMessage(error?.response?.data?.message);
    setData(undefined);
  } finally {
    setLoading(false);
  }
};

export const getPincodeLogs = async ({
  setLoading,
  setData,
  currentPage,
  pageLimit,
  serviceProvider,
  serviceType,
  synchronization
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IPincodeLogs | undefined>>;
  currentPage: number;
  pageLimit: number;
  serviceProvider?: string;
  serviceType?: string;
  synchronization?: string;
}) => {
  setLoading(true);

  const service_provider = serviceProvider ? `&serviceProvider=${serviceProvider}` : '';
  const service_type = serviceType ? `&serviceType=${serviceType}` : '';
  const selected_synchronization = synchronization ? `&synchronization=${synchronization}` : '';
  try {
    const response = await httpClient.get(
      `/serviceability/get-block-logs?limit=${pageLimit}&offset=${
        currentPage - 1
      }${service_provider}${service_type}${selected_synchronization}`
    );
    setData(response?.data?.data);
  } catch (error: any) {
    console.error('Error in Searching Pincodes Logs', error);
    ErrorMessage(error?.response?.data?.message);
    setData(undefined);
  } finally {
    setLoading(false);
  }
};

export const enablePincodes = async ({
  setLoading,
  payload,
  callbackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IEnablePincodeFormFields;
  callbackFunc?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post('/serviceability/enable-pincodes', payload);
    SuccessMessage('Pincodes Enabled Successfully.');
    if (callbackFunc) callbackFunc();
  } catch (error: any) {
    console.error('Error in Updating Pincodes', error);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};
