import { Form, Input } from 'antd';
import React, { ChangeEvent } from 'react';

interface Props {
  name: string;
  onPriceChange: (e: ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: number;
}
const CustomPriceInput: React.FC<Props> = ({ name, defaultValue, onPriceChange }) => {
  return (
    <Form.Item
      name={name}
      rules={[
        { required: true, message: 'Please Enter Price.' },
        {
          pattern: new RegExp(/^[1-9][0-9]*(\.[0-9]+)?$/),
          message: 'Invalid Price.'
        }
      ]}
      className="m-0"
      initialValue={defaultValue}>
      <Input
        type="number"
        placeholder={'Add Custom Price.'}
        onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
        onChange={onPriceChange}
      />
    </Form.Item>
  );
};

export default CustomPriceInput;
