import { Button, Input } from 'antd';
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import getRandomString from '../../../../../utils/getRandomString';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { FaCheckCircle } from 'react-icons/fa';

interface IUploadFileInput {
  inputId?: string;
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  uploading: boolean;
  handleUpload: () => void;
  fileUploadSuccess: boolean;
  setFileUploadSuccess: Dispatch<SetStateAction<boolean>>;
  acceptedFiles?: string;
  required?: boolean;
  isDisabled: boolean;
}
const UploadFileInput: FC<IUploadFileInput> = ({
  inputId,
  file,
  setFile,
  uploading,
  handleUpload,
  fileUploadSuccess,
  setFileUploadSuccess,
  acceptedFiles = '.heif,.heic,.png,.jpg,.jpeg',
  required = true,
  isDisabled
}) => {
  const [fileName, setFileName] = useState<string>('No File Chosen');
  const [inputKey, setInputKey] = useState<string>(getRandomString(5));

  useEffect(() => {
    if (file === null) {
      setFileName('No File Chosen');
    }
  }, [file]);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputKey(getRandomString(5));

    if (event.target.files && event.target.files.length > 0) {
      setFileName(event.target.files[0]?.name);
      setFile(event.target.files[0]);
    }
  };

  const onClickFile = () => {
    setFileName('No File Chosen');
    setFile(null);
    setFileUploadSuccess(false);
  };

  return (
    <>
      <div className="flex flex-wrap gap-2 w-full lg:w-3/4">
        <Formlabel labelText={'Upload File :'} required={required} />
        <p className="m-0 select-none text-gray-500">
          {acceptedFiles?.split(',')?.join(', ')} only
        </p>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 w-full lg:w-3/4">
        <div className="w-full sm:w-1/2 flex items-center gap-2">
          <Input
            title={''}
            className={`w-[97px] pl-1`}
            type="file"
            key={inputKey}
            id={inputId ? inputId : getRandomString(5)}
            accept={acceptedFiles}
            onChange={handleFileChange}
            onClick={onClickFile}
            suffix={false}
            disabled={isDisabled}
          />
          <div title={fileName}>
            {fileName?.length > 20 ? fileName?.slice(0, 20) + '...' : fileName}
          </div>

          {fileUploadSuccess && <FaCheckCircle className="text-green-400" />}
        </div>
        <Button
          className="primary-button w-full sm:w-1/2"
          onClick={handleUpload}
          disabled={fileUploadSuccess || !file}
          loading={uploading}>
          {fileUploadSuccess ? 'Uploaded' : uploading ? 'Uploading' : 'Start Upload'}
        </Button>
      </div>
    </>
  );
};

export default UploadFileInput;
