import { CalendarOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
import YellowCircle from '../../../../../CommonComponents/YellowCircle';

interface IDateRangeFilter {
  setDateRange: Dispatch<SetStateAction<{ from: string; to: string }>>;
  dateRange: { from: string; to: string };
  title?: string;
}

const DateRangeFilter = ({ setDateRange, dateRange, title = 'Date' }: IDateRangeFilter) => {
  // eslint-disable-next-line arrow-body-style
  const handleDateRangeChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string
  ) => {
    setDateRange({ from: dateString[0], to: dateString[1] });
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };
  return {
    title: (
      <div className="flex items-center">
        {title}
        {dateRange?.from ? <YellowCircle /> : null}
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    filterDropdown: () => (
      <div className="p-4" onKeyDown={(e) => e.stopPropagation()}>
        <p className="m-0 mb-3 text-gray-500">Select Date Range:</p>
        <DatePicker.RangePicker
          id="ordersDateRange"
          onChange={handleDateRangeChange}
          disabledDate={disabledDate}
          showTime={{
            hideDisabledOptions: true,
            defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('11:59:59', 'HH:mm:ss')]
          }}
          format="YYYY-MM-DD HH:mm:ss"
        />
      </div>
    ),
    render: (text: string) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
  };
};

export default DateRangeFilter;
