import React, { useState } from 'react';
import SearchPincodeForm from './SearchPincodeForm';
import PincodesTable from './PincodesTable';
import { FaExclamationCircle } from 'react-icons/fa';

const PincodeAvailability = () => {
  const [pincodesData, setPincodesData] = useState<number[] | undefined>(undefined);

  return (
    <div className="flex flex-col gap-10">
      <h2 className="text-xl font-normal m-0">Search Pincodes</h2>
      <SearchPincodeForm setPincodesData={setPincodesData} />
      {!!pincodesData &&
        (pincodesData?.length ? (
          <PincodesTable pincodesData={pincodesData} />
        ) : (
          <h2 className="text-red-400 flex items-center gap-2 text-xl">
            <span>
              <FaExclamationCircle />
            </span>
            No blocked Pincodes.
          </h2>
        ))}
    </div>
  );
};

export default PincodeAvailability;
