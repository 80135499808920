import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserRouter from './components/UserRouter';

interface RoutersProps {
  token: string | null;
}

const Routers: React.FC<RoutersProps> = ({ token }) => {
  const isAuthenticated = Boolean(token);

  return (
    <Routes>
      <Route path="/*" element={<UserRouter isAuthenticated={isAuthenticated} />} />
    </Routes>
  );
};

export default Routers;
