import React, { FC, useEffect, useState } from 'react';
import {   Divider, Drawer, Pagination, PaginationProps,  Table, Typography } from 'antd';

import { getFintechOrders } from '../../api';
import {  IOrderDrawer } from '../../../../../interfaces/fintechOrders';
import { TableColumns } from '../../TableColumns';

const { Title } = Typography;



const OrderDrawer: FC<IOrderDrawer> = ({visible,bulkId,userId,cartStatus,setDrawerVisible}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(20);
    const { cartTableColumns, addressDetailsColumns, packageDetailsColumns } = TableColumns();
    const [cartListData, setCartListData] = useState<any>();

    const [cartListLoading, setCartListLoading] = useState<boolean>(false);
    const requestPayload = {
      pageNo: currentPage,
      pageLimit: pageLimit,
      dateTo: undefined,
      dateFrom: undefined,
      bulkId:bulkId,
      cartStatus: cartStatus,
      userId:userId
    };
    
    useEffect(() => {
      getFintechOrders({ requestPayload: requestPayload, setData: setCartListData, setLoading: setCartListLoading });
    }, [bulkId,cartStatus]);

    const onClose = () => {
    setDrawerVisible(false);
  };

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
    const requestPayload={
      pageNo:pageNo,
      pageLimit:limit,
      bulkId:bulkId,
      userId:userId
    }
    getFintechOrders({ requestPayload: requestPayload, setData: setCartListData, setLoading: setCartListLoading });

  };

  return (
    <>
      <Drawer title="Bulk Order Details For :"  onClose={onClose} open={visible} width={'90%'} extra={<Title   copyable={{ text: bulkId }} level={5}><b>{bulkId}</b></Title>}>
        <Title level={3} style={{marginTop:"20px",marginBottom:"25px", textAlign:"center"}}>
          Orders Details 
         <div style={{float:"right"}}> </div>
        </Title>
       <Table
       bordered
        columns={cartTableColumns}
        loading={cartListLoading}
        rowKey={'orderId'}
        scroll={{ x: 1600, y: 'calc(100vh - 220px)' }}
        pagination={false}
        dataSource={cartListData?.carts}
        expandable={{
          expandedRowRender: (record) => {
            const addressData = [
              {
                ...record?.billingDetails,
                rowHeading: 'Billing Address'
              },
              {
                ...record?.deliveryDetails,
                rowHeading: 'Delivery Address'
              }
            ];
            return (
              <div className="p-7 mx-7 my-5 primary-box-shadow flex flex-col rounded-xl">
                <div>
                  <h2 className="text-xl font-bold m-0">Cart Details:</h2>
                  <h2 className="text-xl font-normal text-center m-0 mb-4">Package Details</h2>

                  <Table
                    columns={packageDetailsColumns}
                    dataSource={record?.packageDetails}
                    pagination={false}
                    loading={cartListLoading}
                    scroll={{
                      x: 1600
                    }}
                    rowKey={'rowHeading'}
                  />
                </div>
                <Divider />
                <div>
                  <h2 className="text-xl font-normal text-center m-0 mb-4">Address Details</h2>

                  <Table
                    columns={addressDetailsColumns}
                    dataSource={addressData}
                    pagination={false}
                    loading={cartListLoading}
                    scroll={{
                      x: 1600
                    }}
                    rowKey={'rowHeading'}
                  />
                </div>
              </div>
            );
          }
        }}
      />
      <br/>
      <div className="flex justify-end">
        <Pagination
          responsive={true}
          current={currentPage}
          pageSize={pageLimit}
          total={cartListData?.totalPages ? cartListData?.totalPages * pageLimit : 0}
          onChange={onPageChange}
        />
      </div>
      </Drawer>
    </>
  );
};

export default OrderDrawer;