import { Checkbox, Form, FormInstance, Radio, Select } from 'antd';
import React, { ChangeEvent, FC, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { PROVIDERS_ENUM } from '../../../utils/providers';
import Formlabel from '../../../../../CommonComponents/FormLabel';
interface ISelectProviderFields {
  pincodeForm: FormInstance;
}
const SelectProviderFields: FC<ISelectProviderFields> = ({ pincodeForm }) => {
  const [serviceTypes, setServiceTypes] = useState<string[]>([]);

  const onSelectProvider = (option: ChangeEvent<HTMLSelectElement>) => {
    setServiceTypes(PROVIDERS_ENUM[`${option}`]);
    pincodeForm.resetFields(['serviceType']);
  };

  const checkboxOptions = ['Pickup', 'Delivery'];

  return (
    <>
      <Form.Item
        name="provider"
        label={<Formlabel labelText="Provider" />}
        rules={[
          {
            required: true,
            message: 'Select any provider'
          }
        ]}>
        <Select
          showSearch
          placeholder="Select provider"
          options={Object.keys(PROVIDERS_ENUM)?.map((provider) => ({
            label: provider?.toUpperCase(),
            value: provider
          }))}
          onChange={onSelectProvider}
        />
      </Form.Item>

      <Form.Item
        name="serviceType"
        label={<Formlabel labelText="Service Type" />}
        rules={[
          {
            required: true,
            message: 'Select service type'
          }
        ]}>
        <Select
          disabled={!serviceTypes?.length}
          showSearch
          placeholder="Select service type"
          options={serviceTypes?.map((service) => ({
            label: service?.toUpperCase(),
            value: service
          }))}
        />
      </Form.Item>

      <Form.Item
        label={<Formlabel labelText="Blocked By" />}
        name="blockedBy"
        rules={[{ required: true, message: 'Select any one.' }]}>
        <Radio.Group className="flex">
          <Radio value={'Service Provider'}>Service Provider</Radio>
          <Radio value={'Coreyo'}>Coreyo</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="blockFor"
        label={<Formlabel labelText="Block For" />}
        rules={[{ required: true, message: 'Please select any option.' }]}>
        <Checkbox.Group options={checkboxOptions} />
      </Form.Item>

      <Form.Item
        name="reason"
        label={<Formlabel labelText="Reason" />}
        rules={[{ required: true, message: 'Please enter reason.' }]}>
        <TextArea placeholder="Enter reason" maxLength={100} showCount />
      </Form.Item>
    </>
  );
};

export default SelectProviderFields;
