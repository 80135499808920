import { httpClient } from '../../../../utils/apiRequest';

export const verifySellerRegistration = async (data: string) => {
  try {
    const formData = {
      email: data
    };
    const response = await httpClient.post('/user/seller-registration-verification', formData);
    return response.data;
  } catch (error) {
    console.error('Error occurred while verifying seller registration:', error);
    throw error;
  }
};

export const documentVerification = async (formData: any) => {
  try {
    const response = await httpClient.post('/user/doc', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    console.error('Error occurred while verifying seller documents:', error);
    throw error;
  }
};
