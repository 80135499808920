import React, { FC, useState } from 'react';
import { ICompanyDetailsData } from '../../../../../interfaces/b2bVerification';
import getRandomString from '../../../../../utils/getRandomString';
import { Empty } from 'antd';

interface IBusinessPocDetails {
  detailsData: ICompanyDetailsData;
}

const BusinessPocDetails: FC<IBusinessPocDetails> = ({ detailsData }) => {
  return detailsData?.businessPocDetails?.length ? (
    <div className="flex flex-wrap gap-6">
      {detailsData?.businessPocDetails?.map((item) => (
        <>
          <div
            className="px-4 w-full xl:max-w-[400px] grow py-3 rounded primary-box-shadow border-gray-100 flex flex-col gap-1"
            key={getRandomString(5)}>
            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[100px]  flex justify-between">
                First Name <span>:</span>
              </p>
              {item?.firstName ? <p className="text-clip">{item?.firstName}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[100px]  flex justify-between">
                Last Name <span>:</span>
              </p>
              {item?.lastName ? <p className="text-clip">{item?.lastName}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[100px]  flex justify-between">
                Designation <span>:</span>
              </p>
              {item?.designation ? <p className="text-clip">{item?.designation}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[100px]  flex justify-between">
                Contact No <span>:</span>
              </p>
              {item?.contactNo ? <p className="text-clip">{item?.contactNo}</p> : 'NA'}
            </div>

            <div className="flex flex-wrap gap-2">
              <p className="font-bold w-[100px]  flex justify-between">
                Email <span>:</span>
              </p>
              {item?.email ? <p className="break-all">{item?.email}</p> : 'NA'}
            </div>
          </div>
        </>
      ))}
    </div>
  ) : (
    <Empty />
  );
};

export default BusinessPocDetails;
