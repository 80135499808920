import { Button, Form, FormInstance, Input } from 'antd';
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import Formlabel from '../../../CommonComponents/FormLabel';
import { MdOutlineClose } from 'react-icons/md';
import { GetOrderIdsFromExcel } from '../utils/getOrderIdsFromExcel';
import { IGenerateBulkShipmentLabel } from '../../../../interfaces/generateDocs';

interface IBulkShipmentLabel {
  form: FormInstance;
  fileName: string;
  setFileName: Dispatch<SetStateAction<string>>;
  fileData: string[];
  setFileData: Dispatch<SetStateAction<string[]>>;
  setBulkShipmentLabel: Dispatch<SetStateAction<IGenerateBulkShipmentLabel | undefined>>;
}

const BulkShipmentLabel: FC<IBulkShipmentLabel> = ({
  form,
  fileData,
  setFileData,
  fileName,
  setFileName,
  setBulkShipmentLabel
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);

    if (event.target.files?.length) {
      setFileName(event.target.files[0]?.name);
      const result = await GetOrderIdsFromExcel({ dataFile: event?.target?.files[0] });
      setFileData(result);
    } else {
      setFileData([]);
    }
    setLoading(false);
  };

  const onClickRemove = () => {
    setFileData([]);
    setFileName('No File Chosen');
    form.resetFields(['orderIds']);
    setBulkShipmentLabel(undefined);
  };

  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: 'Please upload a File!'
        }
      ]}
      label={<Formlabel labelText="Upload CSV/Excel (XLXS) File" />}
      name="orderIds">
      <div className="flex flex-col md:flex-row items-center gap-2">
        <Input
          disabled={!!fileData?.length || loading}
          className="w-[95px] pl-1"
          id="orderIdsFile"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={handleFileChange}
          suffix={false}
        />
        <div title={fileName}>
          {fileName?.length > 20 ? fileName?.slice(0, 20) + '...' : fileName}
          {!!fileData?.length && (
            <Button
              onClick={onClickRemove}
              title={`Remove file`}
              className="border-none"
              icon={<MdOutlineClose />}
            />
          )}
        </div>
      </div>
    </Form.Item>
  );
};

export default BulkShipmentLabel;
