import { Tabs, TabsProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import BlockUnblockPincode from './BlockUnblockPincode';
import EnablePincodes from './EnablePincodes';

const HandlePincodes = () => {
  const [activeTab, setActiveTab] = useState<string>('blockUnblockPincodes');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const tabs = containerRef.current.getElementsByClassName('ant-tabs-tab');
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i] as HTMLDivElement;
        tab.style.width = '100%';
        tab.style.textAlign = 'center';
      }
    }
  }, []);

  const tabItems: TabsProps['items'] = [
    {
      key: 'blockUnblockPincodes',
      label: 'Block / Unblock',
      children: <BlockUnblockPincode />
    },
    {
      key: 'enablePincodes',
      label: 'Enable Pincodes',
      children: <EnablePincodes />
    }
  ];

  const onTabChange = (keyName: string) => {
    setActiveTab(keyName);
  };

  return (
    <>
      <h2 className="text-xl font-normal m-0">Handle Pincodes</h2>

      <div ref={containerRef}>
        <Tabs
          animated
          type="card"
          activeKey={activeTab}
          onChange={onTabChange}
          size="small"
          className="md:w-[100%] pt-4 w-full mx-auto p-2 border-0">
          {tabItems.map((item) => (
            <Tabs.TabPane
              key={item.key}
              tab={<div style={{ width: '150px', textAlign: 'center' }}>{item.label}</div>}>
              {item.children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default HandlePincodes;
