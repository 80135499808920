import React, { useEffect, useRef, useState } from 'react';
import { deleteBulkShipment, getFinTechUsers, getFintechOrders, handleBulkInitiate } from './api';
import { Button, Divider,  Input,  Modal,  Pagination,  PaginationProps,  Select,  Table, TableProps } from 'antd';
import { TableColumns } from './TableColumns';
import {   IGetFintechOrdersResponse, RowSelection } from '../../../interfaces/fintechOrders';
import { DeleteOutlined } from '@ant-design/icons';


const Fintech: React.FC = () => {
  const { cartTableColumns, addressDetailsColumns, packageDetailsColumns } = TableColumns();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(300);
  const [cartListData, setCartListData] = useState<any>();
  const [cartListLoading, setCartListLoading] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [users, setUsers] = useState<{label:string,value:string}[]>([{label:"",value:""}]);
  const [searchUserId, setSearchUserId] = useState<string>('');
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedDeleteOrderId, setSelectedDeleteOrderId] = useState("");
  
  const requestPayload = {
    pageNo: currentPage,
    pageLimit: pageLimit,
    dateTo: undefined,
    dateFrom: undefined,
    userId: searchUserId
  };
 
const getUsers = async() => {
  const usersData= await getFinTechUsers({setLoading:setCartListLoading});
  const dropDownInterFace: { label: string; value: string }[] = [];
  for(let i=0;i<usersData.length;i++){
    dropDownInterFace.push({label:usersData[i].identifier,value:usersData[i].userId})
  }
  setUsers(dropDownInterFace);
}

  useEffect(() => {
   getUsers()
  }, []);

  const rowSelection: RowSelection = {
      type: 'checkbox',
      onChange: (selectedRowKeys: React.Key[], selectedRows: IGetFintechOrdersResponse[]) => {
        setSelectedOrderId(selectedRowKeys.map(key => String(key)));
      },
      onSelect: (record, selected, selectedRows) => {
        if (selected) {
          setSelectedRowKeys(prevKeys => Array.from(new Set([...prevKeys, record.orderId])));
        } else {
          setSelectedRowKeys(prevKeys => prevKeys.filter(key => key !== record.orderId));
        }
      },
      onSelectAll: (selected: boolean, selectedRows: IGetFintechOrdersResponse[], changeRows: IGetFintechOrdersResponse[]) => {
        if (selected) {
          const newKeys = changeRows.map(row => row.orderId);
          setSelectedRowKeys(prevKeys => Array.from(new Set([...prevKeys, ...newKeys])));
        } else {
          const newKeys = changeRows.map(row => row.orderId);
          setSelectedRowKeys(prevKeys => prevKeys.filter(key => !newKeys.includes(key.toString())));
        }
    }

    
  };
   const handleGetOrder = async() => {
    setCartListLoading(true)
    await handleBulkInitiate({ orderIds: selectedOrderId});
    await getFintechOrders({ requestPayload: requestPayload, setData: setCartListData, setLoading: setCartListLoading });
  };

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
    const requestPayload = {
      pageNo: pageNo,
      pageLimit: limit,
      dateTo: undefined,
      dateFrom: undefined,
      userId: searchUserId
    };
    getFintechOrders({ requestPayload: requestPayload, setData: setCartListData, setLoading: setCartListLoading });
  };

  const handleUser = async(value:any) => {
    setSearchUserId(value);
    const requestPayload = {
      pageNo: currentPage,
      pageLimit: pageLimit,
      dateTo: undefined,
      dateFrom: undefined,
      userId: value
    };
    await getFintechOrders({ requestPayload: requestPayload, setData: setCartListData, setLoading: setCartListLoading });
  };




  const onCloseDelete = () => {
    setOpenDelete(false);
  };

  const onDelete = async (record:any) => {
    await deleteBulkShipment({ orderId: selectedDeleteOrderId,userId:searchUserId });
    onCloseDelete();
    await getFintechOrders({ requestPayload: requestPayload, setData: setCartListData, setLoading: setCartListLoading });
  };

  cartTableColumns.push( {
    title: 'Delete',
    key: 'delete',
    dataIndex: 'delete',
    width: 100,
    align: 'center',
    fixed: 'right',
    render: (text, record, index) =>{
      const showModal = () => {
        setSelectedDeleteOrderId(record.orderId);
        setOpenDelete(true);
      };
      return<>
      <DeleteOutlined style={{ color: '#1890ff' }} onClick={showModal} />
      <Modal
        open={openDelete}
        onCancel={onCloseDelete}
        footer={false}
      >
        <div>
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete?</p>
          </div>
          <div className="my-5 flex justify-end gap-5">
            <Button onClick={onCloseDelete}>Cancel</Button>
            <Button onClick={onDelete}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  }})
  return (
    <div>
        <div className="mb-4 flex items-center justify-between">
          <div className='flex items-center' style={{gap:'10px'}}>
            <h2 className="text-xl font-normal m-0">Booking Request</h2>
            <Select placeholder="Select user name" options={users} onChange={handleUser} style={{width:'200px'}} />
          </div>
          <div className="flex items-center" style={{gap:'10px'}}>
            <Button className="m-0" onClick={handleGetOrder}>
              Initiate Bulk
            </Button>
            <Pagination
              responsive={true}
              current={currentPage}
              pageSize={pageLimit}
              total={cartListData?.totalPages ? cartListData?.totalPages * pageLimit : 0}
              onChange={onPageChange}
            />
          </div>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={cartTableColumns}
        loading={cartListLoading}
        pagination={false}
        rowKey={'orderId'}
        scroll={{ x: 1600, y: 'calc(100vh - 220px)' }}
        dataSource={cartListData?.carts}
        expandable={{
          expandedRowRender: (record) => {
            const addressData = [
              {
                ...record?.billingDetails,
                rowHeading: 'Billing Address'
              },
              {
                ...record?.deliveryDetails,
                rowHeading: 'Delivery Address'
              }
            ];
            return (
              <div className="p-7 mx-7 my-5 primary-box-shadow flex flex-col rounded-xl">
                <div>
                  <h2 className="text-xl font-bold m-0">Cart Details:</h2>
                  <h2 className="text-xl font-normal text-center m-0 mb-4">Package Details</h2>

                  <Table
                    columns={packageDetailsColumns}
                    dataSource={record?.packageDetails}
                    loading={cartListLoading}
                    scroll={{
                      x: 1600
                    }}
                    rowKey={'rowHeading'}
                  />
                </div>
                <Divider />
                <div>
                  <h2 className="text-xl font-normal text-center m-0 mb-4">Address Details</h2>

                  <Table
                    columns={addressDetailsColumns}
                    dataSource={addressData}
                    pagination={false}
                    loading={cartListLoading}
                    scroll={{
                      x: 1600
                    }}
                    rowKey={'rowHeading'}
                  />
                </div>
              </div>
            );
          }
        }}
      />
      <br/>
  </div>
  );
};

export default Fintech;
