import { DatePicker, DatePickerProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction } from 'react';
import { DateRangePresets } from '../../../../utils/constant';

interface Props {
  id: string;
  currentState: {
    from: string;
    to: string;
  };
  setState: Dispatch<
    SetStateAction<{
      from: string;
      to: string;
    }>
  >;
}

const ComReportDatePicker: React.FC<Props> = ({ id, currentState, setState }) => {
  const handleDateRangeChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string
  ) => {
    setState({ ...currentState, from: dateString[0], to: dateString[1] });
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  return (
    <div className="flex flex-col gap-3 md:gap-0 md:flex-row md:items-center">
      <p className="m-0 mr-2 w-[30%] flex justify-between">
        Select Date Range <span>:</span>
      </p>
      <div className="w-[70%]">
        <DatePicker.RangePicker
          id={id}
          presets={DateRangePresets}
          onChange={handleDateRangeChange}
          disabledDate={disabledDate}
          showTime={{
            hideDisabledOptions: true,
            defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('11:59:59', 'HH:mm:ss')]
          }}
          format="YYYY-MM-DD HH:mm:ss"
        />
      </div>
    </div>
  );
};

export default ComReportDatePicker;
