import { Button, Form, Input } from 'antd';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { uploadFile } from '../../api';
import { formValidations } from '../constants';
import { IDocumentUpload } from '../../../../../interfaces/b2bOnboarding';
import { ErrorMessage } from '../../../../../utils/ResponseMessages';
import UploadFileInput from '../UploadFileInput';
import { FaCheckCircle } from 'react-icons/fa';

interface IAadhaarDoc {
  isFormDisabled: boolean;
  fileUrl: string;
  handleOnSubmit: ({ documentId, documentType }: IDocumentUpload) => Promise<void>;
  documentSuccess: boolean;
  setDocumentSuccess: Dispatch<SetStateAction<boolean>>;
  fileUploadSuccess: boolean;
  setFileUploadSuccess: Dispatch<SetStateAction<boolean>>;
}

const AadhaarDoc: FC<IAadhaarDoc> = ({
  isFormDisabled,
  fileUrl,
  handleOnSubmit,
  documentSuccess,
  setDocumentSuccess,
  fileUploadSuccess,
  setFileUploadSuccess
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleUpload = async () => {
    if (!file) {
      ErrorMessage('Please select a file first.');
      return;
    }

    const binaryData = await file.arrayBuffer();

    if (file) {
      await uploadFile({
        setLoading: setUploading,
        fileUrl,
        contentType: 'application/pdf',
        payload: binaryData,
        setSuccess: setFileUploadSuccess
      });
    }
  };

  const onSubmitForm = async (values: { aadhaarNumber: 'string' }) => {
    handleOnSubmit({
      documentId: values?.aadhaarNumber?.trim(),
      documentType: 'AADHAR',
      setSuccess: setDocumentSuccess
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <div>
        <Formlabel labelText={<h2 className="text-xl font-bold select-none">Aadhaar : </h2>} />
      </div>

      <div className="flex flex-col gap-3">
        <Form
          onFinish={onSubmitForm}
          className="w-full lg:w-3/4"
          disabled={documentSuccess || isFormDisabled}>
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-2">
            <Form.Item
              className="m-0 w-full sm:w-[80%]"
              name="aadhaarNumber"
              rules={[
                // {
                //   required: true,
                //   message: formValidations?.aadhaar?.required
                // },
                {
                  pattern: formValidations?.aadhaar?.patternRegex,
                  message: formValidations?.aadhaar?.patternMessage
                }
              ]}>
              <Input
                placeholder="Enter Aadhaar number"
                suffix={documentSuccess && <FaCheckCircle className="text-green-400" />}
              />
            </Form.Item>

            <Button
              disabled={documentSuccess || isFormDisabled}
              className="m-0 primary-button w-full sm:w-[20%]"
              htmlType="submit">
              {documentSuccess ? 'Submitted' : 'Submit'}
            </Button>
          </div>
        </Form>

        <div className="flex flex-col gap-2">
          <p className="m-0 select-none font-medium text-blue-500">
            * Please scan both sides of Aadhaar in a single PDF
          </p>

          <UploadFileInput
            inputId={'aadhar_file'}
            file={file}
            setFile={setFile}
            uploading={uploading}
            handleUpload={handleUpload}
            fileUploadSuccess={fileUploadSuccess}
            isDisabled={isFormDisabled}
            setFileUploadSuccess={setFileUploadSuccess}
            acceptedFiles=".pdf"
            required={false}
          />
        </div>
      </div>
    </div>
  );
};

export default AadhaarDoc;
