import React, { useState } from 'react';
import { Button, Select } from 'antd';
import { JsonToExcel } from '../../../../../utils/Excel-Conversion/JsonToExcel';

const ExportBulkOrder: React.FC<{
  processedOrders: { [key: string]: any }[];
}> = ({ processedOrders }) => {
  const [exportType, setExportType] = useState(null);

  const downloadCsv = (orders: { [key: string]: any }[], filename: string) => {
    const updatedData = orders?.map((order: any) => {
      const data = {
        ...order,
        status: order?.success ? 'Success' : 'Failed',
        awb: order?.awb ? order?.awb : 'NA'
      };
      delete data?.success;
      delete data?.key;

      return data;
    });

    JsonToExcel({ dataFile: updatedData, filename: filename });
  };

  const onClickExport = () => {
    if (exportType === 'all') {
      downloadCsv(processedOrders, `All_orders`);
    }
    if (exportType === 'success') {
      downloadCsv(
        processedOrders?.filter((order) => order?.success),
        `Success_orders`
      );
    }
    if (exportType === 'failed') {
      downloadCsv(
        processedOrders?.filter((order) => !order?.success),
        `Failed_orders`
      );
    }
  };

  return (
    <div className="flex items-center gap-5">
      <p className="mb-0">Export Orders:</p>
      <Select
        className="mb-0"
        placeholder="Select orders."
        style={{
          width: 170
        }}
        onChange={(value) => setExportType(value)}
        options={[
          {
            value: 'all',
            label: 'All orders.'
          },
          {
            value: 'success',
            label: 'Success orders.',
            disabled: processedOrders?.filter((order) => order?.success)?.length ? false : true
          },
          {
            value: 'failed',
            label: 'Failed orders.',
            disabled: processedOrders?.filter((order) => !order?.success)?.length ? false : true
          }
        ]}
      />

      <Button
        className="mb-0"
        onClick={onClickExport}
        title={exportType ? '' : 'Please select any options.'}
        disabled={exportType ? false : true}>
        Export
      </Button>
    </div>
  );
};

export default ExportBulkOrder;
