import { httpClient } from '../../../../../utils/apiRequest';

export const refreshAccessToken = async () => {
  try {
    const { headers } = await httpClient.get('/auth/refresh');

    // Update the stored access token
    localStorage.setItem('token', headers?.at);

    return headers?.at;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    throw error;
  }
};
