import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';
import Routers from './routes';
import { initApiService } from './utils/initApiService';

const App: React.FC = () => {
  const [token, setToken] = React.useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) setToken(token);
    if (!token) return;
  }, []);

  initApiService();

  return (
    <Router>
      <Routers token={token} />
    </Router>
  );
};

export default App;
