import { Button, PaginationProps, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FC, useEffect, useState } from 'react';
import {
  IDiscrepancyListResponse,
  IDiscrepancyTableColumns,
  ISubTableColumns
} from '../../../../interfaces/discrepancy';
import { getDiscrepancyList } from '../api';
import GetStatus from '../../../../utils/GetStatus';

const DiscrepancyTable: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [discrepancyList, setDiscrepancyList] = useState<IDiscrepancyListResponse | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);

  const handleGetDiscrepancyList = () => {
    getDiscrepancyList({
      setLoading: setIsLoading,
      setData: setDiscrepancyList,
      currentPage: currentPage,
      pageLimit: pageLimit
    });
  };

  useEffect(() => {
    handleGetDiscrepancyList();
  }, [currentPage, pageLimit]);

  const tableColumns: ColumnsType<IDiscrepancyTableColumns> = [
    {
      title: 'Order Id',
      dataIndex: 'orderId'
    },
    {
      title: 'Tracking Number',
      dataIndex: 'trackingNumber'
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      render: (date) => new Date(date).toLocaleString('en-GB', { hour12: true })
    },
    {
      title: 'User Status',
      dataIndex: 'userStatus',
      render: (status) => (
        <div className="capitalize">
          {GetStatus({
            currStatus: status?.toLowerCase(),
            PROCESSING: 'pending',
            SUCCESS: 'approved',
            FAILED: 'rejected',
            CUSTOM_LABEL: status?.toLowerCase()
          })}
        </div>
      )
    },
    {
      title: 'Support Status',
      dataIndex: 'supportStatus',
      render: (status) => (
        <div className="capitalize">
          {GetStatus({
            currStatus: status?.toLowerCase(),
            PROCESSING: 'pending',
            SUCCESS: 'approved',
            FAILED: 'rejected',
            CUSTOM_LABEL: status?.toLowerCase()
          })}
        </div>
      )
    },
    {
      title: 'Action',
      render: () => (
        <Button disabled={true} title="Work in Progress" className="primary-button">
          Approve
        </Button>
      )
    }
  ];

  const subTableColumns: ColumnsType<ISubTableColumns> = [
    {
      title: 'Data Type',
      dataIndex: 'tabName'
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount'
    },
    {
      title: 'Amount',
      dataIndex: 'amount'
    },
    {
      title: 'Weight',
      dataIndex: 'weight'
    },
    {
      title: 'Length',
      dataIndex: 'length'
    },
    {
      title: 'Width',
      dataIndex: 'width'
    },
    {
      title: 'Height',
      dataIndex: 'height'
    }
  ];

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };
  return (
    <Table
      scroll={{ x: 1600 }}
      columns={tableColumns}
      rowKey="_id"
      dataSource={discrepancyList?.data}
      loading={isLoading}
      expandable={{
        expandedRowRender: (record) => {
          const tableData = [
            {
              tabName: 'Old',
              totalAmount: record?.oldTotalAmount,
              amount: record?.oldAmount,
              weight: record?.oldWeight,
              length: record?.oldLength,
              width: record?.oldWidth,
              height: record?.oldHeight
            },
            {
              tabName: 'New',
              totalAmount: record?.newTotalAmount,
              amount: record?.newAmount,
              weight: record?.newWeight,
              length: record?.newLength,
              width: record?.newWidth,
              height: record?.newHeight
            }
          ];
          return (
            <div className="p-2 bg-green-100 rounded-xl">
              <Table
                columns={subTableColumns}
                pagination={false}
                dataSource={tableData}
                rowKey="tabName"
              />
            </div>
          );
        }
      }}
      pagination={{
        responsive: true,
        showQuickJumper: true,
        showSizeChanger: true,
        current: currentPage,
        pageSize: pageLimit,
        pageSizeOptions: [20, 30, 50],
        total: discrepancyList?.totalPages ? discrepancyList?.totalPages * pageLimit : 0,
        onChange: onPageChange
      }}
    />
  );
};

export default DiscrepancyTable;
