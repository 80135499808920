import { Form, Input } from 'antd';
import React from 'react';
import Formlabel from '../../../CommonComponents/FormLabel';

const OrderIdInput = () => {
  return (
    <Form.Item
      label={<Formlabel labelText="Order Id" />}
      name="orderId"
      className="m-0"
      rules={[
        {
          required: true,
          message: 'Please enter order id'
        }
      ]}>
      <Input placeholder="Order ID" />
    </Form.Item>
  );
};

export default OrderIdInput;
