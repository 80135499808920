import { Button, Form, Input, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import MenuData from '../api';

interface MenuFormProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: any;
  action: string;
  onUpdate: any;
  initialValues?: any;
}

const MenuForm: React.FC<MenuFormProps> = ({
  isVisible,
  onClose,
  onSubmit,
  action,
  onUpdate,
  initialValues
}) => {
  if (!isVisible) return null;

  const { error, isLoading, createMenu } = MenuData();
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleFormData = async (values: any) => {
    const payload = {
      menuId: values.menuId,
      menuName: values.menuName,
      menuParentId: values.menuParentId,
      department: values.department
    };

    if (action === 'Update') {
      onUpdate(payload);
    } else {
      onSubmit(payload);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 background-blur-sm flex justify-center items-center ">
      <div className="bg-white px-12 rounded-3xl">
        <div className="flex flex-row  justify-between my-5">
          <h1 className="text-black">Add User</h1>
          <button className="text-xl" onClick={() => onClose()}>
            X
          </button>
        </div>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={(values) => {
            handleFormData(values);
          }}
          className="w-96">
          <Form.Item
            label="Menu Id"
            name="menuId"
            rules={[{ required: true, message: 'required' }]}>
            <Input placeholder="menu_id"></Input>
          </Form.Item>
          <Form.Item
            label="Menu Name"
            name="menuName"
            rules={[{ required: true, message: 'required' }, { whitespace: true }, { min: 3 }]}
            hasFeedback>
            <Input placeholder="menu_name"></Input>
          </Form.Item>
          <Form.Item label="Menu Parent Id" name="menuParentId" hasFeedback>
            <Input placeholder="menu_parent_id"></Input>
          </Form.Item>
          <Form.Item label="Select Department" name="department">
            <Select placeholder="Select Department">
              <Select.Option value="IT">IT</Select.Option>
              <Select.Option value="SALES">SALES</Select.Option>
              <Select.Option value="OPERATION">OPERATION</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {action}
              </Button>
              <Button htmlType="reset">Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default MenuForm;
