import { Collapse, CollapseProps, Divider, Modal, StepProps, Steps } from 'antd';
import moment from 'moment';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

interface ITrackingDetailsModal {
  showTrackingDetails: boolean;
  setShowTrackingDetails: Dispatch<SetStateAction<boolean>>;
  trackingData: any;
}

const TrackingDetailsModal: FC<ITrackingDetailsModal> = ({
  showTrackingDetails,
  setShowTrackingDetails,
  trackingData
}) => {
  const [refundJourney, setRefundJourney] = useState<StepProps[]>([]);

  useEffect(() => {
    const journey =
      typeof trackingData === 'string'
        ? []
        : trackingData?.map((data: any, index: number) => {
            const items: CollapseProps['items'] = [
              {
                key: index,
                label: 'Details',
                children: (
                  <div key={`trackingDetailMain-${index}`} className="flex flex-col">
                    {data?.subStatuses?.map((item: any, index: number) => (
                      <div key={`trackingDetail-${index}`}>
                        {item?.description && (
                          <div className="flex">
                            <p className="mr-2 min-w-[85px] flex justify-between capitalize font-bold">
                              Description <span>:</span>
                            </p>
                            <p className="w-75">{item?.description}</p>
                          </div>
                        )}

                        <div className="flex">
                          <p className="mr-2 min-w-[85px] flex justify-between capitalize font-bold">
                            Location <span>:</span>
                          </p>
                          <p className="w-75">{item?.location}</p>
                        </div>

                        <div className="flex">
                          <p className="mr-2 min-w-[85px] flex justify-between capitalize font-bold">
                            Date <span>:</span>
                          </p>
                          <p className="w-75">
                            {moment(item?.dateTime).format('DD-MM-YYYY, HH:mm:ss')}
                          </p>
                        </div>

                        {data?.subStatuses?.length !== index + 1 && (
                          <Divider dashed className="my-3" />
                        )}
                      </div>
                    ))}
                  </div>
                ),
                style: {
                  background: `rgba(0, 0, 0, 0.02)`
                }
              }
            ];

            return {
              title: <p className="font-bold text-grey-400 uppercase">{data?.mainStatus}</p>,
              description: <Collapse defaultActiveKey={index} items={items} size="small" />
            };
          });

    setRefundJourney(journey);
  }, [trackingData]);

  return (
    <Modal
      forceRender
      open={showTrackingDetails}
      title={'Tracking Details'}
      footer={false}
      onCancel={() => setShowTrackingDetails(false)}>
      <Steps
        className={`ml-3 mt-5 ${refundJourney?.length >= 2 ? 'mb-50px' : 'mb-[150px]'}`}
        progressDot
        direction="vertical"
        current={refundJourney?.length}
        items={refundJourney}
      />
    </Modal>
  );
};

export default TrackingDetailsModal;
