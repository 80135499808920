import React from 'react';
import { Select, Form } from 'antd';
const { Option, OptGroup } = Select;

interface ItemCategorySelectProps {
  id: string;
  hsnCategoriesList: { [key: string]: any }[];
  onChange: (id: string, value: string) => void;
}

const ItemCategorySelect: React.FC<ItemCategorySelectProps> = ({
  id,
  hsnCategoriesList,
  onChange
}) => {
  return (
    <div>
      <Form.Item
        rules={[{ required: true, message: 'Please select category.' }]}
        name={`category${id}`}
        className="px-2 m-0">
        <Select
          allowClear
          showSearch
          optionFilterProp="value"
          placeholder="Select category"
          style={{
            width: '100%'
          }}
          onChange={(value) => onChange(id, value)}>
          {hsnCategoriesList.map((ele, index) => (
            <OptGroup key={index} label={ele?.categoryName}>
              {ele.subCategories.map((ele: { [key: string]: any }) => (
                <Option key={ele?.subCategoryName} value={ele?.subCategoryName}>
                  {ele?.subCategoryName}
                </Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default ItemCategorySelect;
