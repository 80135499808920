import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCartDetails, handleBulkInitiate } from '../../api';
import { Divider, Table } from 'antd';
import { TableColumns } from '../../TableColumns';
import { IGetFintechOrdersResponse } from '../../../../../interfaces/fintechOrders';

const CartDetails = () => {
  const query = useParams();
    // const param = useParams()
    const { addressDetailsColumns, packageDetailsColumns } = TableColumns();
    const [cartLoading, setCartLoading] = useState<boolean>(false);
    const [cartData, setCartData] = useState<IGetFintechOrdersResponse[]>([]);

    useEffect(() => {
      handleGetOrderDetails(query.id);
    }, [query]);

    const handleGetOrderDetails = async(query:any) => {
       await getCartDetails({ query: query ,setCartData: setCartData, setCartLoading: setCartLoading });
      };

const addressData = [
  {
    ...(cartData as any)?.billingDetails,
    rowHeading: 'Billing Address'
  },
  {
    ...(cartData as any)?.billingDetails,
    rowHeading: 'Delivery Address'
  }
]

    return (
      <div className="p-7 mx-7 my-5 primary-box-shadow flex flex-col rounded-xl">
                <div>
                  <h2 className="text-xl font-bold m-0">Cart Details:</h2>
                  <h2 className="text-xl font-normal text-center m-0 mb-4">Package Details</h2>

                  <Table
                    columns={packageDetailsColumns}
                    dataSource={(cartData as any)?.packageDetails}
                    pagination={false}
                    loading={cartLoading}
                    scroll={{
                      x: 1600
                    }}
                    rowKey={'rowHeading'}
                  />
                </div>
                <Divider />
                <div>
                  <h2 className="text-xl font-normal text-center m-0 mb-4">Address Details</h2>

                  <Table
                    columns={addressDetailsColumns}
                    dataSource={addressData} 
                    pagination={false}
                    loading={cartLoading}
                    scroll={{
                      x: 1600
                    }}
                    rowKey={'rowHeading'}
                  />
                </div>
              </div>
    );
};

export default CartDetails;
