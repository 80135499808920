import { Button } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { generateInvoice } from '../../api';

const InvoiceShipmentButton: React.FC<{
  bulkId: string;
  invoiceLoading: boolean;
  setInvoiceLoading: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line
  updateInvoice?: any;
}> = ({ bulkId, invoiceLoading, setInvoiceLoading, updateInvoice }) => {
  const [invoiceData, setInvoiceData] = useState<{
    invoice?: string;
    shipmentLabel?: string;
    invoiceUrl?: string;
    shipmentLabelUrl?: string;
  }>({
    invoice: '',
    shipmentLabel: '',
    invoiceUrl: '',
    shipmentLabelUrl: ''
  });

  useEffect(() => {
    if (bulkId) {
      generateInvoice({
        bulkId: bulkId,
        setLoading: setInvoiceLoading,
        setData: setInvoiceData
      });
    }
  }, [bulkId, updateInvoice]);

  return (
    <div className="flex gap-5 flex-wrap">
      <Button
        href={invoiceData?.invoiceUrl}
        target="_blank"
        loading={invoiceLoading}
        className="primary-button"
        disabled={!invoiceData?.invoiceUrl}>
        View Invoice
      </Button>
      <Button
        href={invoiceData?.shipmentLabelUrl}
        target="_blank"
        loading={invoiceLoading}
        disabled={!invoiceData?.shipmentLabelUrl}
        className="primary-button">
        View Shipment Label
      </Button>
    </div>
  );
};

export default InvoiceShipmentButton;
