import { refreshAccessToken } from '../components/pages/SignIn/api/refreshAccessToken';
import { httpClient } from './apiRequest';
import { clearCache } from './clearCache';

export const initApiService = () => {
  // Axios request interceptor
  httpClient.interceptors.request.use(
    async (config) => {
      const accessToken = localStorage.getItem('token');

      if (accessToken && config?.url !== '/auth/login') {
        // Add the access token to the request headers
        config.headers.authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Axios response interceptor
  httpClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // Checking, if the error is due to token expiry (e.g., HTTP 401 Unauthorized)
      const originalRequest = error.config;

      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const refreshAuth = await refreshAccessToken();

          // Updating the request configuration with the new access token
          originalRequest.headers.authorization = `Bearer ${refreshAuth}`;

          // Retry the original request with the new access token
          return httpClient(originalRequest);
        } catch (refreshError) {
          // Handle the refresh token error, e.g., redirect to the login page
          console.error('Error refreshing access token:', refreshError);
          clearCache();
          throw refreshError;
        }
      }

      // Handle other API call errors
      throw error;
    }
  );
};
