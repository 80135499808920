import React from 'react';

const UserCount: React.FC = () => {
  return (
    <>
      <div className="mid-row flex flex-row space-x-4 mb-8 sm:w-78-flex-col">
        <div className="content basis-1/4 bg-white h-16 rounded-md">
          <h4 className="ml-4 font-light mt-2">Total users </h4>
          <p className="ml-4 font-bold">3000</p>
        </div>
        <div className="content basis-1/4 h-16 rounded-md bg-white">
          <h4 className="ml-4 font-light mt-2">Admin</h4>
          <p className="ml-4 font-bold">3000</p>
        </div>
        <div className="content basis-1/4 bg-white rounded-md h-16">
          <h4 className="ml-4 font-light mt-2">Admin</h4>
          <p className="ml-4 font-bold">3000</p>
        </div>
        <div className="content basis-1/4 bg-white rounded-md h-16">
          <h4 className="ml-4 font-light4 mt-2">Total User</h4>
          <p className="ml-4 font-bold">3000</p>
        </div>
      </div>
    </>
  );
};

export default UserCount;
