import { Button, Form, Input, Select, Spin, notification } from "antd";
import React, { ChangeEvent, useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { getFinTechUsers, uploadBookingReq } from "../api";


const UploadBookingReq: React.FC = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [bookingData, setBookingData] = useState<number[]>([]);
  const [bookingFrom] = Form.useForm();
  const [users, setUsers] = useState<{label:string,value:string}[]>([{label:"",value:""}]);
  const [userId, setUserId] = useState<string>('');
  

  
  const getUsers = async() => {
      const usersData= await getFinTechUsers({setLoading:setIsLoading});
      const dropDownInterFace: { label: string; value: string }[] = [];
      for(let i=0;i<usersData.length;i++){
          dropDownInterFace.push({label:usersData[i].identifier,value:usersData[i].userId})
        }
        setUsers(dropDownInterFace);
        setIsLoading(false);
        
    }
    
    useEffect(() => {
        getUsers()
    }, []);
    
    const handleUser = async(value:any) => {
      setUserId(value);
    }
  const onSubmitForm = async () => {
    if (!file) {
      notification.error({ message: 'No file selected' });
      return;
    }
    console.log(file, 32);
   const  filedsData= bookingFrom.getFieldsValue();
    console.log(filedsData, 33);
    delete filedsData.uploadBooking;
    delete filedsData.user;
    try {
      setIsLoading(true); 
      await uploadBookingReq({
        file: file,
        userId,
        ...filedsData
      });
      notification.success({ message: 'Booking data uploaded successfully' });
      onClickRemove();
    } catch (error) {
      console.log(error, 32);
    } finally {
      setIsLoading(false); 
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    if (event.target.files?.length) {
      setFileName(event.target.files[0]?.name);
      setFile(event.target.files[0]);
      setBookingData([1]);
    } else {
      setFileName('');
      setFile(null);
      setBookingData([]);
    }
    setIsLoading(false);
  };

  const onClickRemove = () => {
    setFile(null);
    setFileName('');
    setBookingData([]);
    bookingFrom.resetFields();
  };

 const  onClickSubmit = async() => {
  await bookingFrom.validateFields().then(onSubmitForm);
  }
  
  return (
    <>
    <div className="mb-4 flex items-center justify-between">
        <div className='flex items-center ' style={{gap:'10px'}}>
          <h2 className="text-xl font-normal m-0">Upload Booking</h2>
        </div>
    </div>
      
      <div className="flex flex-col items-center">
        <div className="my-5 w-[90%] md:w-[500px] primary-box-shadow rounded-xl">

          <Spin spinning={isLoading}>
            <div className="p-5 bg-white opacity-70 rounded-xl">
              <Form onFinish={onClickSubmit} id="bookingFrom" form={bookingFrom} layout="vertical">
             
              <div className="w-[70%]">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please upload a File!'
                    }
                  ]}
                  label="Upload CSV/Excel(XLXS) File"
                  name="uploadBooking">
                  <div className="flex flex-col md:flex-row items-center gap-2">
                    <Input
                      disabled={isLoading}
                      className="w-[95px] pl-1"
                      type="file"
                      id="bookingvFile"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleFileChange}
                    />
                    <div title={fileName}>
                      {fileName.length > 20 ? fileName.slice(0, 20) + '...' : fileName}
                      {file && (
                        <Button
                          onClick={onClickRemove}
                          title="Remove file"
                          className="border-none"
                          icon={<MdOutlineClose />}
                        />
                      )}
                    </div>
                  </div>
                </Form.Item>

                {file && (
                  <div className="flex flex-col gap-2">
                  <Form.Item
                    name="user"
                    label="User Name"
                    rules={[
                      {
                        required: true,
                        message: 'Select any User'
                      }
                    ]}>
                        <Select
                    placeholder="Select user name"
                    onChange={handleUser}
                    options={users.map((user) => ({ value: user.value, label: user.label }))}
                  />
                  </Form.Item>
                      <div style={{fontWeight:600,fontSize: '20px'}}>Pickup Address :</div>
                  <Form.Item
                    name="customerName"
                    label="Customer Name"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Customer Name'
                      }
                    ]}
                    >
                    <Input placeholder="Enter Customer Name" />
                  </Form.Item>
                  <Form.Item
                    name="line1"
                    label="Address Line 1"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Address Line 1'
                      }
                    ]}
                    >
                        <Input placeholder="Enter Address Line 1" />
                  </Form.Item>
                  <Form.Item
                    name="line2"
                    label="Address Line 2"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Address Line 2'
                      }
                    ]}
                    >
                        <Input placeholder="Enter Address Line 2" />
                  </Form.Item>
                 
                  <div className="flex gap-2">
                  <Form.Item
                    name="city"
                    label="City"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Enter City'
                      }
                    ]}
                    >
                        <Input placeholder="Enter City" />
                  </Form.Item>
                  <Form.Item
                    name="state"
                    label="State"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Enter State'
                      }
                    ]}
                    >
                        <Input placeholder="Enter State" />
                  </Form.Item>
                  </div>
                  <div className="flex gap-2">
                  <Form.Item
                    name="pincode"
                    label="Pincode"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Pincode'
                      }
                    ]}
                    >
                    <Input type="number" placeholder="Enter Pincode" />
                  </Form.Item>
                  <Form.Item
                    name="country"
                    label="Country"
                    initialValue={'India'}
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Country'
                      }
                    ]}
                    >
                        <Input placeholder="Enter Country" />
                  </Form.Item>
                  </div>
                  
                  <Form.Item
                    name="email"
                    label="Email"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Email'
                      },
                      {
                        type: 'email',
                        message: 'Enter valid Email'
                      }
                    ]}
                    >
                        <Input placeholder="Enter Email" />
                  </Form.Item>
                  <Form.Item
                    name="mobileNumber"
                    label="Mobile No"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Mobile Number'
                      }
                    ]}
                    >
                    <Input type='number' placeholder="Enter Mobile No" />
                  </Form.Item>
                  </div>
                  
                )}
              </div>
               
                <Button
                  disabled={!bookingData.length}
                  className="mt-5 primary-button"
                  htmlType="submit"
                  form="bookingFrom"
                  >
                  Submit
                </Button>
              </Form>
            </div>
          </Spin>
        </div>
      </div>
    </>
  );

};
export default UploadBookingReq;