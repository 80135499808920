import { ADDRESS_LENGTH, NAME_LENGTH } from '../../../../../../utils/config';

export const validateColumnKeys = {
  delivery_address_line1: 'Delivery Address Line 1',
  delivery_address_line2: 'Delivery address line 2',
  delivery_email: 'Delivery Email',
  delivery_customer_name: 'Delivery Customer Name',

  // delivery_address_country: "Delivery Address Country",
  // delivery_address_state: "Delivery Address State",
  // delivery_address_city: "Delivery Address City",

  delivery_address_pincode: 'Delivery Address Pincode',
  delivery_phone_number: 'Delivery Phone Number',
  pickup_address_line1: 'Pickup Address Line 1',
  pickup_address_line2: 'Pickup Address Line 2',
  pickup_email: 'Pickup Email',
  pickup_customer_name: 'Pickup Customer Name',

  // pickup_address_country: "Pickup Address Country",
  // pickup_address_state: "Pickup Address State",
  // pickup_address_city: "Pickup Address City",

  pickup_address_pincode: 'Pickup Address Pincode',
  pickup_phone_number: 'Pickup Phone Number',

  billing_customer_name: 'Billing Customer Name',
  billing_address_line1: 'Billing Address Line 1',
  billing_address_line2: 'Billing Address Line 2',

  // billing_address_city: "Billing Address City",
  // billing_address_state: "Billing Address State",
  // billing_address_country: "Billing Address Country",

  billing_address_pincode: 'Billing Address Pincode',
  billing_phone_number: 'Billing Phone Number',
  billing_email: 'Billing Email',

  shipment_length: 'Shipment Length',
  shipment_width: 'Shipment Width',
  shipment_height: 'Shipment Height',
  weight: 'Weight',
  item_description: 'Item Description',
  estimated_value: 'Estimated Value',
  //   service_type: 'Service Type',
  //   delivery_vendor: 'Delivery Vendor',
  custom_price: 'Custom Price'
};

export const checkValidation = (order: { [key: string]: any }) => {
  let invalidOrder: string[] = [];
  let invalidOrderKeys: string[] = [];

  // eslint-disable-next-line
  const specialCharPattern = /[@_!#$%^&*()<>?\|}{~:]/g;
  const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
  const indianPincodeRegex = /^\d{6}$/;
  const phoneNumberRegex = /^[6-9]\d{9}$/;
  const numberRegex = /^[1-9][0-9]*$/;
  const measurementRegex = /^-?\d+(\.\d+)?$/;

  const appendError = (condition: any, message: string, keys: string) => {
    if (condition) {
      invalidOrder = [...invalidOrder, message];
      invalidOrderKeys = [...invalidOrderKeys, keys];
    }
  };

  appendError(
    specialCharPattern?.test(order?.delivery_address_line1),
    `${validateColumnKeys?.delivery_address_line1} : Remove ${order?.delivery_address_line1
      ?.toString()
      ?.match(specialCharPattern)}.`,
    validateColumnKeys?.delivery_address_line1
  );

  appendError(
    order?.delivery_address_line1?.length > Number(ADDRESS_LENGTH),
    `${validateColumnKeys?.delivery_address_line1} : Characters length should not be more than 60.`,
    validateColumnKeys?.delivery_address_line1
  );

  appendError(
    order?.delivery_address_line1?.toString()?.trim()?.length === 0 ||
      order?.delivery_address_line1?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.delivery_address_line1} : Please add address.`,
    validateColumnKeys?.delivery_address_line1
  );

  appendError(
    specialCharPattern?.test(order?.delivery_address_line2),
    `${validateColumnKeys?.delivery_address_line2} : Remove ${order?.delivery_address_line2
      ?.toString()
      ?.match(specialCharPattern)}`,
    validateColumnKeys?.delivery_address_line2
  );

  appendError(
    order?.delivery_address_line2?.length > Number(ADDRESS_LENGTH),
    `${validateColumnKeys?.delivery_address_line2} : Characters length should not be more than 60.`,
    validateColumnKeys?.delivery_address_line2
  );

  appendError(
    order?.delivery_address_line2?.toString()?.trim()?.length === 0 ||
      order?.delivery_address_line2?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.delivery_address_line2} : Please add address.`,
    validateColumnKeys?.delivery_address_line2
  );

  appendError(
    !emailRegex?.test(order?.delivery_email),
    `${validateColumnKeys?.delivery_email} : Invalid email id.`,
    validateColumnKeys?.delivery_email
  );

  appendError(
    order?.delivery_customer_name?.length === 0 ||
      order?.delivery_customer_name?.length === undefined ||
      order?.delivery_customer_name?.length > Number(NAME_LENGTH),
    `${validateColumnKeys?.delivery_customer_name} : ${
      order?.delivery_customer_name?.length === 0 ||
      order?.delivery_customer_name?.length === undefined
        ? 'Please add customer name.'
        : `Characters length should not be more than ${NAME_LENGTH}.`
    }`,
    validateColumnKeys?.delivery_customer_name
  );

  appendError(
    // !indianPincodeRegex?.test(order?.delivery_address_pincode),
    !order?.delivery_address_pincode,
    `${validateColumnKeys?.delivery_address_pincode} : Please add pincode.`,
    validateColumnKeys?.delivery_address_pincode
  );

  appendError(
    !phoneNumberRegex?.test(order?.delivery_phone_number),
    `${validateColumnKeys?.delivery_phone_number} : Invalid phone number.`,
    validateColumnKeys?.delivery_phone_number
  );

  appendError(
    specialCharPattern?.test(order?.pickup_address_line1),
    `${validateColumnKeys?.pickup_address_line1} : Remove ${order?.pickup_address_line1
      ?.toString()
      ?.match(specialCharPattern)}.`,
    validateColumnKeys?.pickup_address_line1
  );

  appendError(
    order?.pickup_address_line1?.length > Number(ADDRESS_LENGTH),
    `${validateColumnKeys?.pickup_address_line1} : Characters length should not be more than 60.`,
    validateColumnKeys?.pickup_address_line1
  );

  appendError(
    order?.pickup_address_line1?.toString()?.trim()?.length === 0 ||
      order?.pickup_address_line1?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.pickup_address_line1} : Please add address.`,
    validateColumnKeys?.pickup_address_line1
  );

  appendError(
    specialCharPattern?.test(order?.pickup_address_line2),
    `${validateColumnKeys?.pickup_address_line2} : Remove ${order?.pickup_address_line2
      ?.toString()
      ?.match(specialCharPattern)}.`,
    validateColumnKeys?.pickup_address_line2
  );

  appendError(
    order?.pickup_address_line2?.length > Number(ADDRESS_LENGTH),
    `${validateColumnKeys?.pickup_address_line2} : Characters length should not be more than 60.`,
    validateColumnKeys?.pickup_address_line2
  );

  appendError(
    order?.pickup_address_line2?.toString()?.trim()?.length === 0 ||
      order?.pickup_address_line2?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.pickup_address_line2} : Please add address.`,
    validateColumnKeys?.pickup_address_line2
  );

  appendError(
    !emailRegex?.test(order?.pickup_email),
    `${validateColumnKeys?.pickup_email} : Invalid email id.`,
    validateColumnKeys?.pickup_email
  );

  appendError(
    order?.pickup_customer_name?.length === 0 ||
      order?.pickup_customer_name?.length === undefined ||
      order?.pickup_customer_name?.length > Number(NAME_LENGTH),
    `${validateColumnKeys?.pickup_customer_name} : ${
      order?.pickup_customer_name?.length === 0 || order?.pickup_customer_name?.length === undefined
        ? 'Please add customer name.'
        : `Characters length should not be more than ${NAME_LENGTH}.`
    }`,
    validateColumnKeys?.pickup_customer_name
  );

  appendError(
    // !indianPincodeRegex?.test(order?.pickup_address_pincode),
    !order?.pickup_address_pincode,
    `${validateColumnKeys?.pickup_address_pincode} : Please add pincode.`,
    validateColumnKeys?.pickup_address_pincode
  );

  appendError(
    !phoneNumberRegex?.test(order?.pickup_phone_number),
    `${validateColumnKeys?.pickup_phone_number} : Invalid phone number.`,
    validateColumnKeys?.pickup_phone_number
  );

  // billing data validations
  appendError(
    order?.billing_customer_name?.length === 0 ||
      order?.billing_customer_name?.length === undefined ||
      order?.billing_customer_name?.length > Number(NAME_LENGTH),
    `${validateColumnKeys?.billing_customer_name} : ${
      order?.billing_customer_name?.length === 0 ||
      order?.billing_customer_name?.length === undefined
        ? 'Please add customer name.'
        : `Characters length should not be more than ${NAME_LENGTH}.`
    }`,
    validateColumnKeys?.billing_customer_name
  );

  appendError(
    specialCharPattern?.test(order?.billing_address_line1),
    `${validateColumnKeys?.billing_address_line1} : Remove ${order?.billing_address_line1
      ?.toString()
      ?.match(specialCharPattern)}.`,
    validateColumnKeys?.billing_address_line1
  );

  appendError(
    order?.billing_address_line1?.length > Number(ADDRESS_LENGTH),
    `${validateColumnKeys?.billing_address_line1} : Characters length should not be more than 60.`,
    validateColumnKeys?.billing_address_line1
  );

  appendError(
    order?.billing_address_line1?.toString()?.trim()?.length === 0 ||
      order?.billing_address_line1?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.billing_address_line1} : Please add address.`,
    validateColumnKeys?.billing_address_line1
  );

  appendError(
    specialCharPattern?.test(order?.billing_address_line2),
    `${validateColumnKeys?.billing_address_line2} : Remove ${order?.billing_address_line2
      ?.toString()
      ?.match(specialCharPattern)}.`,
    validateColumnKeys?.billing_address_line2
  );

  appendError(
    order?.billing_address_line2?.length > Number(ADDRESS_LENGTH),
    `${validateColumnKeys?.billing_address_line2} : Characters length should not be more than 60.`,
    validateColumnKeys?.billing_address_line2
  );

  appendError(
    order?.billing_address_line2?.toString()?.trim()?.length === 0 ||
      order?.billing_address_line2?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.billing_address_line2} : Please add address.`,
    validateColumnKeys?.billing_address_line2
  );

  appendError(
    // !indianPincodeRegex?.test(order?.billing_address_pincode),
    !order?.billing_address_pincode,
    `${validateColumnKeys?.billing_address_pincode} : Please add pincode.`,
    validateColumnKeys?.billing_address_pincode
  );

  appendError(
    !phoneNumberRegex?.test(order?.billing_phone_number),
    `${validateColumnKeys?.billing_phone_number} : Invalid phone number.`,
    validateColumnKeys?.billing_phone_number
  );

  appendError(
    !emailRegex?.test(order?.billing_email),
    `${validateColumnKeys?.billing_email} : Invalid email id.`,
    validateColumnKeys?.billing_email
  );

  appendError(
    !measurementRegex?.test(order?.shipment_length),
    `${validateColumnKeys?.shipment_length} : Invalid length.`,
    validateColumnKeys?.shipment_length
  );

  appendError(
    !measurementRegex?.test(order?.shipment_width),
    `${validateColumnKeys?.shipment_width} : Invalid width.`,
    validateColumnKeys?.shipment_width
  );

  appendError(
    !measurementRegex?.test(order?.shipment_height),
    `${validateColumnKeys?.shipment_height} : Invalid height.`,
    validateColumnKeys?.shipment_height
  );

  appendError(
    !measurementRegex?.test(order?.weight),
    `${validateColumnKeys?.weight} : Invalid weight.`,
    validateColumnKeys?.weight
  );

  appendError(
    order?.item_description?.toString()?.trim()?.length === 0 ||
      order?.item_description?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.item_description} : Please add item description.`,
    validateColumnKeys?.item_description
  );

  appendError(
    order?.estimated_value === '' ||
      order?.estimated_value === 0 ||
      !numberRegex?.test(order?.estimated_value),
    `${validateColumnKeys?.estimated_value} : ${
      !numberRegex?.test(order?.estimated_value)
        ? 'Invalid value.'
        : order?.estimated_value === 0 || order?.estimated_value === ''
        ? 'Please add estimated value.'
        : 'Value must be greater than 0 and less than 50,000.'
    } `,
    validateColumnKeys?.estimated_value
  );

  appendError(
    // eslint-disable-next-line
    order?.hasOwnProperty('custom_price') &&
      (isNaN(order?.custom_price) ||
        !order?.custom_price ||
        order?.custom_price?.toString()?.trim() === 0 ||
        order?.custom_price === undefined),
    `${validateColumnKeys?.custom_price} : ${
      order?.custom_price === undefined
        ? 'Please add price.'
        : isNaN(order?.custom_price)
        ? 'Invalid Price.'
        : 'Please add price.'
    } `,
    validateColumnKeys?.custom_price
  );

  return { invalidOrder, invalidOrderKeys };
};
