// eslint-disable-next-line
var XLSX = require('xlsx');

interface JsonToXlsxProps {
  dataFile: { [key: string]: any }[];
  filename: string;
}
export const JsonToExcel = ({ dataFile, filename }: JsonToXlsxProps) => {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');

  const formattedDate = `${day}-${month}-${year}-${hours}-${minutes}-${seconds}`;

  /* generate worksheet and workbook */

  const worksheet = XLSX.utils.json_to_sheet(dataFile);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');

  const headers = [];
  const range = XLSX.utils.decode_range(worksheet['!ref']);
  let C;
  const R = range.s.r; // First row

  for (C = range.s.c; C <= range.e.c; C++) {
    const cellAddress = XLSX.utils.encode_cell({ c: C, r: R }); // Generate cell address
    const headerCell = worksheet[cellAddress]; // Get cell object
    headers.push(headerCell.v); // Push cell value to headers array
  }

  /* fix headers */
  XLSX.utils.sheet_add_aoa(worksheet, [headers], {
    origin: 'A1'
  });

  /* calculate column width */
  const max_width = dataFile.reduce((w, r) => Math.max(w, r?.name?.length), 10);
  worksheet['!cols'] = [{ wch: max_width }];

  /* create an XLSX file and try to save to Presidents.xlsx */
  XLSX.writeFile(workbook, `${filename}-${formattedDate}.xlsx`);
};
