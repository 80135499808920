import React, { FC } from 'react';
import moment from 'moment';
import { ICompanyDetailsData } from '../../../../../interfaces/b2bVerification';

interface IBusinessLegalDetails {
  detailsData: ICompanyDetailsData;
}

const BusinessLegalDetails: FC<IBusinessLegalDetails> = ({ detailsData }) => {
  const leftContents = [
    {
      label: 'Registered Name',
      value: detailsData?.businessLegalDetails?.registeredName
    },
    {
      label: 'Company Type',
      value: detailsData?.businessLegalDetails?.companyType?.split('_')?.join(' ')
    },
    {
      label: 'Contract Type',
      value: detailsData?.businessLegalDetails?.contractType
    },
    {
      label: 'Created Date',
      value: moment(detailsData?.businessLegalDetails?.createdAt).format('DD-MM-YYYY, hh:mm:ss')
    },
    {
      label: 'Contract Expiry',
      value: detailsData?.businessLegalDetails?.contractExpiry
    }
  ];

  const rightContents = [
    {
      label: 'Address Line 1',
      value: detailsData?.businessLegalDetails?.companyAddressLine1
    },
    {
      label: 'Address Line 2',
      value: detailsData?.businessLegalDetails?.companyAddressLine2
    },
    {
      label: 'Locality',
      value: detailsData?.businessLegalDetails?.companyAddressLocality
    },
    {
      label: 'City',
      value: detailsData?.businessLegalDetails?.companyAddressCity
    },
    {
      label: 'State',
      value: detailsData?.businessLegalDetails?.companyAddressState
    },
    {
      label: 'Postal Code',
      value: detailsData?.businessLegalDetails?.companyAddressPostalCode
    }
  ];

  return (
    <div className="flex flex-col lg:flex-row gap-2">
      <div className="flex flex-col gap-2 lg:w-1/2">
        {leftContents?.map((item, index) => (
          <div key={item?.label} className="flex flex-wrap gap-2">
            <li className="font-bold w-[120px] sm:w-[150px]  flex justify-between">
              {item?.label} <span>:</span>
            </li>
            {item?.value ? <p className="text-clip">{item?.value}</p> : 'NA'}
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-2 lg:w-1/2">
        {rightContents?.map((item, index) => (
          <div key={item?.label} className="flex flex-wrap gap-2">
            <li className="font-bold w-[120px] sm:w-[150px] flex justify-between">
              {item?.label} <span>:</span>
            </li>
            {item?.value ? <p className="">{item?.value}</p> : 'NA'}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessLegalDetails;
